import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Stack } from "@chakra-ui/react";

import {
	EnumSelect,
	Section,
	TextInput,
	DatePicker,
	ButtonEditor,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import api from "~/resources/api";
import Store from "./store";
import { useParams, useHistory } from "react-router";

interface IParams {
	materialId: string;
}
const Edit: React.FC = () => {
	const { materialId } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(materialId));
	const pageStrings = strings.materialsPage;
	const history = useHistory();

	const onBack = () => history.goBack();

	const onSubmit = () => {
		store.editMaterial(() => onBack());
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{base: 0, sm: 16 }}
		>
			<Section title={strings.editMaterial.title}>
				<Stack w="100%" spacing={spacing.s8}>
					<DatePicker
						label={pageStrings.fields.date}
						selectedDate={store.date.state}
						onChangeDate={store.date.setState}
						isDisabled={store.loader.isLoading}
					/>

					<Flex
						w="100%"
						justifyContent={{base: "normal", md:"space-between"}}
						alignItems="center"
						direction={{base: "column", md: "row"}}
					>
						<TextInput
							labelText={pageStrings.fields.weight}
							{...store.formShelf.field("weight")}
							type="number"
							boxProps={{ w: {base: "100%", md: "45%"} }}
						/>
						<EnumSelect
							formProps={{ w: {base: "100%", md: "45%"} }}
							label={pageStrings.fields.category}
							items={api.allValuesMaterialCategory()}
							tranlateEnum={api.translateMaterialCategory}
							onChangeEnum={store.category.setState}
							currentValue={store.category.state}
							disabled={store.loader.isLoading}
						/>
					</Flex>

					<ButtonEditor
						goButtonText={strings.editMaterial.title}
						loading={store.loader.isLoading}
						onCancel={onBack}
						onGo={onSubmit}
					/>
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(Edit);
