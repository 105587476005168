import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Text,
	Tr,
	useDisclosure,
	HStack,
} from "@chakra-ui/react";
import { useHistory } from "react-router";

import {
	Table,
	TableCellWithActionButtons,
	Graphic,
	PeriodFilter,
	TextInput,
	AutoCompleteModal,
	LegalUserCard,
	AutoCompleteLegalUserField,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useDebounce } from "~/hooks/useDebounce";

const TableView: React.FC = () => {
	const pageStrings = strings.materials.table;
	const store = useLocalObservable(() => new Store());
	const { dialog, authStore } = useGlobalStore();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const debounce = useDebounce();
	const history = useHistory();
	const modal = strings.common.modal;
	const isLegalUser = !!authStore.currentAdminUser?.legalUser;

	const onDeleteMaterial = (id: string) => {
		store.deleteMaterial(id);
		dialog.closeDialog();
	};

	const onGoToEditMaterial = (materialId: string) => history.push(`/dashboard/materials/edit/${materialId}`);

	const openDialog = (material: api.Material) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(material.materialCode.toString()),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteMaterial(material.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	const onGoToDetails = (id: string) => {
		history.push(`/dashboard/materials/details/${id}`);
	};

	const onSelectLegalUser = (legalUser: api.AdminUser) => {
		store.legalUser?.setValue(legalUser);
		onClose();
	};

	React.useEffect(() => {
		if (authStore.currentAdminUser) {
			store.setLegalUser(isLegalUser);
		}
	}, [authStore.currentAdminUser]);

	return (
		<Flex p={{ base: "2", lg: "16" }} direction="column">
			<Graphic
				label={pageStrings.graphic.title}
				graphicLabel={store.labelStatistics}
				loading={store.statisticsLoader.isLoading}
				data={store.dataStatistics}
				barLabel={pageStrings.graphic.kg}
				hasError={!store.dataStatistics}
				mb={5}
			/>
			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				hasNextPage={store._isHaveNextPage}
				headerFilter={
					<HStack
						spacing={{ base: 0, lg: 4 }}
						flexDir={{ base: "column", lg: "row" }}
					>
						<PeriodFilter
							selectedStartDate={store.startDate.value}
							onChangeStartDate={store.startDate.setValue}
							selectedFinalDate={store.finalDate.value}
							onChangeFinalDate={store.finalDate.setValue}
						/>
						{!isLegalUser && (
							<AutoCompleteLegalUserField
								labelText={pageStrings.legalUserSearch}
								onOpen={onOpen}
								value={store.legalUser.value ? store.legalUser.value.name : ""}
								onClean={() => store.onClean()}
							/>
						)}
					</HStack>
				}
				renderRow={(item) => (
					<Tr key={item.id} >
						<Td>
							<Text>{item.materialCode}</Text>
						</Td>
						<Td>
							<Text>{api.translateMaterialCategory(item.category)}</Text>
						</Td>
						<Td>
							<Text>{strings.formatters.date(item.date)}</Text>
						</Td>
						<Td>
							<Text>{strings.formatters.weight(item.weight)}</Text>
						</Td>
						<Td>
							<Text>{item.collect.code}</Text>
						</Td>
						<TableCellWithActionButtons
							onDelete={!isLegalUser ? () => openDialog(item) : undefined}
							onEdit={!isLegalUser ? () => onGoToEditMaterial(item.id) : undefined}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
			/>
			<AutoCompleteModal
				isOpen={isOpen}
				onClose={onClose}
				header={
					<TextInput
						placeholder={pageStrings.legalUserSearch}
						onKeyUp={(e) => {
							const input = e.target as HTMLInputElement;
							debounce.clearTimer();
							debounce.setTimer(setTimeout(() => store.searchLegalUser.setValue(input.value), 500));
						}}
					/>
				}
				listProps={{
					data: store.autoCompleteLegalUser.items,
					loading: store.autoCompleteLegalUser.loader.isLoading,
					renderItem: (item) => (
						<LegalUserCard
							key={item.id}
							legalUser={item}
							onClick={(legalUser: api.AdminUser) => onSelectLegalUser(legalUser)}
						/>
					),
					paginantionProps: {
						currentPage: store.autoCompleteLegalUser.page,
						nextPage: store.autoCompleteLegalUser.nextPage,
						prevPage: store.autoCompleteLegalUser.previousPage,
						hasNextPage: store.autoCompleteLegalUser.hasNextPage,
					},
				}}
			/>
		</Flex>
	);
};

export default observer(TableView);
