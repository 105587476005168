import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";

const deleteRequests: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/deleteRequests" component={Table} />
	</Switch>
);

export default observer(deleteRequests);
