import { makeAutoObservable } from "mobx";
import { LoaderShelf, FormShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import SimpleStateShelf from "~/shelves/SimpleStateShelf";

import strings from "~/resources/strings";

const pageStrings = strings.editMaterial;
export default class Store {
	public formShelf = new FormShelf({
		code: "",
		weight: "",
	});

	public materialId = "";

	public loader = new LoaderShelf();

	public category = new SimpleStateShelf(api.MaterialCategory.Plastic);

	public date = new SimpleStateShelf(new Date());

	constructor(materialId: string) {
		this.materialId = materialId;
		this.getMaterial(materialId);
		makeAutoObservable(this);
	}


	public setValuesFormShelf = (material: api.Material) => {
		this.formShelf = new FormShelf({
			code: material.materialCode.toString(),
			weight: material.weight.toString(),
		});
		this.category.setState(material.category);
		this.date.setState(material.date);
	};

	public getMaterial = async (id: string) => {
		this.loader.tryStart();
		try {
			const material = await api.getMaterial(id);
			this.setValuesFormShelf(material);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			Errors.handleError(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public editMaterial = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.formShelf.getValues();
			const newMaterial: api.EditMaterial = {
				category: this.category.state,
				date: this.date.state,
				weight: Number(data.weight),
			};

			await api.editMaterial(this.materialId, newMaterial);
			showSuccessToast(pageStrings.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
