import React from "react";
import {
	Box,
	Image,
	Text,
	Flex,
	FlexProps,
	Badge,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";

import api from "~/resources/api";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";

interface IProps {
	establishment: api.Establishment;
	onClick?: (establishment: api.Establishment) => void;
	isSelected?: boolean;
	flexProps?: FlexProps;
}

export const EstablishmentCard: React.FC<IProps> = observer((props) => {
	const { establishment, onClick, isSelected ,flexProps } = props;
	const componentStrings = strings.components.establishmentCard;

	return (
		<Flex
			h="80px"
			bg="gray.100"
			borderRadius="md"
			borderTopRightRadius={0}
			borderWidth={isSelected ? 1 : 0}
			borderColor="gray.400"
			cursor="pointer"
			w="100%"
			onClick={onClick ? () => onClick(establishment) : undefined}
			{...flexProps}
		>
			<Box
				bg="gray.300"
				minW="25%"
				maxW="30%"
				borderRadius="md"
				borderTopRightRadius={0}
			>
				{establishment.image && (
					<Image
						src={establishment.image.url}
						w="100%"
						h="100%"
						borderRadius="md"
						borderTopRightRadius={0}
					/>
				)}
			</Box>
			<Flex
				p={spacing.s2}
				w="100%"
				position="relative"
			>
				<Text fontWeight="bold" size="sm">
					{establishment.name}
				</Text>
				<Badge
					position="absolute"
					right={2}
					bottom={1}
					bg="white"
					borderRadius="md"
					borderTopRightRadius={0}
					px={spacing.s2}
					py={spacing.s1}
					display="flex"
				>
					<Text mr={spacing.s1} size="xs">{componentStrings.segment}</Text>
					<Text size="xs" fontWeight="normal">{api.translateSegment(establishment.segment)}</Text>
				</Badge>
			</Flex>
		</Flex>
	);
});
