import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Button, Stack, Image, Text, Box, VStack } from "@chakra-ui/react";
import { Section, DetailsRow, DetaislContent } from "../../../../components";
import strings from "../../../../resources/strings";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import spacing from "~/themes/spacing";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.legalUsers.edit;
	const establishmentsFields = strings.establishment.edit.fields;
	const { authStore } = useGlobalStore();
	const { id } = useParams<IParams>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));
	const onGoToEditEstablishment = (idLegalUser: string) => history.push(`/dashboard/legalUsers/establishments/edit/${idLegalUser}`);
	const onGoToCreateEstablishment = () => history.push("/dashboard/legalUsers/establishments/create");

	const controllerEditor = () => {
		if (store.modelShelf._model.legalUser) {
			onGoToEditEstablishment(id);
		} else {
			onGoToCreateEstablishment();
		}
	};

	const [docType, setDoctype] = React.useState<"cpf" | "cnpj">("cnpj");

	React.useEffect(
		() => {
			if (store.modelShelf._model && store.modelShelf._model.legalUser) {
				setDoctype(
					store.modelShelf._model.legalUser.documentNumber.length === 14
						? "cpf"
						: "cnpj",
				);
			}
		},
		[store.modelShelf._model],
	);

	return (
		<Flex
			justifyContent="space-around"
			alignItems="stretch"
			w="100%"
			p={{ base: 0, sm: 4 }}
			flexWrap="wrap"
		>
			<Section
				m={{base: 0, md: spacing.s4}}
				title={pageStrings.titleDetails[docType]}
			>
				<DetaislContent
					loading={store.modelShelf.loader.isLoading}
					item={store.modelShelf._model?.legalUser}
					renderItem={(item) => (
						<VStack spacing={spacing.s2} w="100%" display="inline">
							{store.modelShelf.model.image && (
								<Flex
									justifyContent="center"
								>
									<Image
										borderRadius="md"
										maxW="200px"
										maxH="200px"
										src={store.modelShelf.model.image.url}
									/>
								</Flex>
							)}
							<Stack>
								<DetailsRow
									value={item.documentNumber}
									label={pageStrings.fields.document[docType]}
								/>
								<DetailsRow
									value={item.fantasyName}
									label={pageStrings.fields.userName[docType]}
								/>
							</Stack>
						</VStack>
					)}
				/>
			</Section>
			<Section
				m={{base: 0, md: spacing.s4}}
				mt={{base: spacing.s6, md: spacing.s4}}
				title={pageStrings.establishmentDetails}
				footer={
					store.modelShelf._model && (authStore.hasLegalUser() || authStore.isSuperAdmin && store.modelShelf._model.establishment)
						? (
							<Button variant="footer" colorScheme="primary" onClick={controllerEditor}>
								{pageStrings.editorEstablishment(!!store.modelShelf._model.establishment)}
							</Button>
						)
						: store.modelShelf._model ? store.modelShelf._model.establishment && (
							<Button variant="footer" colorScheme="primary" onClick={() => onGoToEditEstablishment(id)}>
								{pageStrings.editorEstablishment(true)}
							</Button>) : null
				}
			>
				<DetaislContent
					loading={store.modelShelf.loader.isLoading}
					item={store.modelShelf._model?.establishment}
					renderItem={(item) => (
						<VStack spacing={spacing.s2} w="100%" display="inline">
							<DetailsRow
								value={item.name}
								label={establishmentsFields.name}
							/>
							<DetailsRow
								value={item.phone}
								label={establishmentsFields.phone}
							/>
							{docType === "cnpj" && (
								<>
									<DetailsRow
										value={item.documentNumber}
										label={establishmentsFields.documentNumber}
									/>
									<DetailsRow
										value={api.translateSegment(item.segment)}
										label={establishmentsFields.segment}
									/>
								</>
							)}
						</VStack>
					)}
				/>
			</Section>
			<Section
				m={{base: 0, md: spacing.s4}}
				mt={{base: spacing.s6, md: spacing.s4}}
				title={pageStrings.establishmentAddress}
			>
				<DetaislContent
					loading={store.modelShelf.loader.isLoading}
					item={store.modelShelf._model?.establishment}
					renderItem={(item) => (
						<VStack spacing={spacing.s2} w="100%" display="inline">
							<DetailsRow
								value={item.address.zipcode}
								label={establishmentsFields.zipcode}
							/>
							<DetailsRow
								value={api.translateStateUF(item.address.state)}
								label={establishmentsFields.state}
							/>
							<DetailsRow
								value={item.address.city}
								label={establishmentsFields.city}
							/>
							<DetailsRow
								value={item.address.neighborhood}
								label={establishmentsFields.neighborhood}
							/>
							<DetailsRow
								value={item.address.street}
								label={establishmentsFields.street}
							/>
							<DetailsRow
								value={item.address.streetNumber}
								label={establishmentsFields.streetNumber}
							/>
							{item.address.complementary && (
								<DetailsRow
									value={item.address.complementary}
									label={establishmentsFields.complementary}
								/>
							)}
						</VStack>
					)}
				/>
			</Section>
			<Section
				m={{base: 0, md: spacing.s4}}
				mt={{base: spacing.s6, md: spacing.s4}}
				title={pageStrings.establishmentImages}
			>
				<DetaislContent
					loading={store.modelShelf.loader.isLoading}
					item={store.modelShelf._model?.establishment}
					renderItem={(item) => (
						<VStack
							spacing={spacing.s2}
							w="100%"
							alignContent="center"
							display="flex"
						>
							{item.image && (
								<Box textAlign="center">
									<Text mb="2" fontSize="xl" fontWeight="bold">{pageStrings.banner}</Text>
									<Image
										borderRadius="md"
										maxW="200px"
										maxH="200px"
										src={item.image.url}
									/>
								</Box>
							)}
							{item.logo && (
								<Box textAlign="center">
									<Text mb="2" fontSize="xl" fontWeight="bold">{pageStrings.logo}</Text>
									<Image
										borderRadius="md"
										maxW="200px"
										maxH="200px"
										src={item.logo.url}
									/>
								</Box>
							)}
						</VStack>
					)}
				/>
			</Section>
		</Flex>
	);
};

export default observer(Details);
