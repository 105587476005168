import { makeAutoObservable } from "mobx";
import { LoaderShelf, FormShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import ImagePickerShelf from "~/shelves/ImagePicker";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.users.edit;
export default class Store {
	public form = new FormShelf({
		name: "",
		email: "",
		phone: "",
	});

	public loader = new LoaderShelf();
	public id: string | null = null;
	public profileImage = new ImagePickerShelf();


	constructor (id: string) {
		this.getUser(id);
		makeAutoObservable(this);
	}

	public setValuesFormShelf = (user: api.User) => {
		this.form = new FormShelf({
			email: user.email,
			name: user.name,
			phone: user.phone,
		});
		if (user.image) {
			this.profileImage.uploadedImage = user.image;
		}
	};

	public getUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const user = await api.getUser(id);
			this.setValuesFormShelf(user);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			Errors.handleError(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public editUser = async (userId: string, onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.form.getValues();
			const newUser: api.EditUser = {
				name: data.name,
				email: data.email,
				phone: data.phone,
				documentNumber: null,
				image: this.profileImage.uncertainfiedImage,
			};

			await api.editUser(userId, newUser);
			showSuccessToast(pageStrings.success);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
