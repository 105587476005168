import { makeAutoObservable } from "mobx";
import {
	AttributeShelf,
	LoaderShelf,
	FormShelf,
} from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

export default class Store {
	public formShelf = new FormShelf({
		email: "",
		name: "",
		password: "",
		fantasyName: "",
		documentNumber: "",
	});
	public loader = new LoaderShelf();
	public id = new AttributeShelf<string>("");


	constructor(id?: string) {
		if (id) {
			this.id.setValue(id);
			this.getCooperativeUser(id);
		}
		makeAutoObservable(this);
	}

	public setInitValues = (data: api.AdminUser) => {
		this.formShelf = new FormShelf({
			name: data.name,
			email:  data.email,
			fantasyName: data.legalUser?.fantasyName || "",
			documentNumber: data.legalUser?.documentNumber || "",
			password:  "",
		});
	};
	public getCooperativeUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const cooperativeUser = await api.getCooperativeAdminUserById(id);
			this.setInitValues(cooperativeUser);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
	public createOrEditCooperativeAdminUser = async (onSuccess: () => void) => {
		this.loader.tryStart();
		const data = this.formShelf.getValues();
		try {
			if (!this.id.value){
				const newAdmin: api.NewAdminUserCooperative = {
					email: data.email,
					name: data.name,
					password: data.password,
					fantasyName: data.fantasyName,
					documentNumber: data.documentNumber,
				};
				await api.createCooperativeAdminUser(newAdmin);
				showSuccessToast(strings.cooperativeAdminUsers.editOrEdit.success(this.id.value));
				onSuccess();
			} else {
				const newEstablishment: api.EditAdminUser = {
					email: data.email,
					name: data.name,
					image: null,
				};
				await api.editCooperativeAdminUser(this.id.value, newEstablishment);
				showSuccessToast(strings.cooperativeAdminUsers.editOrEdit.success(this.id.value));
				onSuccess();
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
