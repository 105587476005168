import React from "react";
import {
	Badge,
	BadgeProps,
} from "@chakra-ui/react";

import strings from "~/resources/strings";
import api from "~/resources/api";

interface IProps {
	subscriptionStatus: api.SubscriptionStatus;
}

export const SubscriptionStatusBadge: React.FC<IProps> = (props) => {
	const componentStrings = strings.components.paymentCard;
	const { subscriptionStatus } = props;

	const handleStatus = (): BadgeProps => {
		switch (subscriptionStatus) {
			case api.SubscriptionStatus.paid:
				return {
					textColor: "primary.500",
					bg: "white",
					children: componentStrings.signaureStatus.paid,
				};
			case api.SubscriptionStatus.trialing:
				return {
					textColor: "blue.500",
					bg: "white",
					children: componentStrings.signaureStatus.trilling,
				};
			case api.SubscriptionStatus.pendingPayment:
				return {
					textColor: "secondary.500",
					bg: "white",
					children: componentStrings.signaureStatus.pendingPayment,
				};
			case api.SubscriptionStatus.canceled:
				return {
					textColor: "red.500",
					bg: "white",
					children: componentStrings.signaureStatus.canceled,
				};
			case api.SubscriptionStatus.unpaid:
			default:
				return {
					textColor: "white",
					bg: "red",
					children: componentStrings.signaureStatus.noPaid,
				};
		}
	};

	return (
		<Badge
			ml={2}
			h={5}
			px={2}
			py={0.5}
			textAlign="center"
			fontWeight="normal"
			{...handleStatus()}
		/>
	);
};
