import React from "react";
import { observer } from "mobx-react-lite";
import { DrawerLinks } from "../DrawerContainer/DrawerLinks";
import { Box, Center } from "@chakra-ui/react";

export const Menu: React.FC = observer(() => (
	<Center display={{ base: "flex", lg: "none" }}>
		<Box w="250px">
			<DrawerLinks />
		</Box>
	</Center>
));
