import React from "react";
import { Text, Flex, StackProps, Stack } from "@chakra-ui/layout";
import { AiOutlineFilter } from "react-icons/ai";
import { observer } from "mobx-react-lite";

import strings from "~/resources/strings";
import { DatePicker } from "~/components";

interface IProps extends StackProps {
	selectedStartDate: Date | null;
	onChangeStartDate: (date: Date) => void;
	selectedFinalDate: Date | null;
	onChangeFinalDate: (date: Date) => void;
}

export const PeriodFilter: React.FC<IProps> = observer((props) => {

	const { selectedStartDate, onChangeStartDate, selectedFinalDate, onChangeFinalDate, ...rest } = props;

	const componentStrings = strings.components.checkinPeriodFilter;

	return (
		<Stack
			w="100%"
			p={3}
			maxW="3xl"
			direction="row"
			wrap="wrap"
			alignItems="center"
			justifyContent={{ base: "space-evenly", md: "space-around" }}
			borderBottomRadius={20}
			borderTopLeftRadius={20}
			mb={{base: 5, lg: 0 }}
			bg="#FFFFFF"
			{...rest}
		>
			<Flex>
				<AiOutlineFilter
					size={25}
					color="#09BE54"
				/>
				<Text
					px={2}
					textColor="primary.500"
					fontWeight="bold"
					textAlign="center"
					fontSize="md"
				>
					{componentStrings.filterDate}
				</Text>
			</Flex>

			<Flex
				wrap="wrap"
				alignItems="center"
				justifyContent="center"
			>
				<Flex
					alignItems="center"
					my={2}
				>
					<Text
						textColor="primary.800"
						fontSize="md"
						px={2}
					>
						{componentStrings.from}
					</Text>
					<DatePicker
						selectedDate={selectedStartDate}
						onChangeDate={onChangeStartDate}
						calendarIcon
						inputProps={{ h: "35px", textAlign: "center" }}
						maxW="180"
					/>
				</Flex>
				<Flex
					alignItems="center"
					my={2}
				>
					<Text
						textColor="primary.800"
						fontSize="md"
						px={2}
					>
						{componentStrings.until}
					</Text>
					<DatePicker
						selectedDate={selectedFinalDate}
						onChangeDate={onChangeFinalDate}
						calendarIcon
						inputProps={{ h: "35px", textAlign: "center" }}
						maxW="180"
					/>
				</Flex>
			</Flex>

		</Stack>
	);
},
);
