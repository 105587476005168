import { makeAutoObservable } from "mobx";
import { AttributeShelf, LoaderShelf, FormShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import ImagePickerShelf from "~/shelves/ImagePicker";
import { showErrorToast } from "~/resources/toast";
import * as mapsTypes from "~/declarations/maps";
import AuthStore from "~/stores/AuthStore";


export default class Store {
	public form = new FormShelf({
		phone: "",
		zipcode: "",
		street: "",
		streetNumber: "",
		complementary: "",
		neighborhood: "",
		city: "",

	});

	public authStore: AuthStore;

	public loaderLegalUser = new LoaderShelf();
	public loader = new LoaderShelf();
	public id: string | null = null;
	public image = new ImagePickerShelf();
	public logo = new ImagePickerShelf();
	public state: AttributeShelf<api.StateUF> = new AttributeShelf(api.StateUF.AC);
	public segment: AttributeShelf<api.Segment> = new AttributeShelf(api.Segment.clothes);
	public lat: AttributeShelf<number | null> = new AttributeShelf(null);
	public lng: AttributeShelf<number | null> = new AttributeShelf(null);
	public legalUser: AttributeShelf<api.AdminUser | null> = new AttributeShelf(null);
	public isDisabled = new AttributeShelf(true);
	public idLegalUser: string | null = null;

	constructor(id?: string, authStore?: AuthStore) {
		if (authStore) {
			this.authStore = authStore;
		}
		if (id ) {
			this.id = id;
			this.getLegalUser(id);

		}
		makeAutoObservable(this);
	}

	private getField(
		address: mapsTypes.IPlaceDetailsResult,
		fieldName: mapsTypes.AddressType,
	): string | null {
		const foundValues = address.address_components
			.filter((component) => component.types.includes(fieldName))
			.map((component) => component.short_name);
		return foundValues.length > 0 ? foundValues[0] : null;
	}

	public setValuesFormShelf = (establishment: api.Establishment) => {
		this.form = new FormShelf({
			phone: establishment.phone,
			complementary: establishment.address.complementary || "",
			neighborhood: establishment.address.neighborhood,
			city: establishment.address.city,
			street: establishment.address.street,
			streetNumber: establishment.address.streetNumber,
			zipcode: establishment.address.zipcode,
		});
		this.state.value = establishment.address.state;
		this.segment.value = establishment.segment;
		this.lat.setValue(establishment.address.latLng.lat);
		this.lng.setValue(establishment.address.latLng.lng);
		if (establishment.image) {
			this.image.uploadedImage = establishment.image;
		}

		if (establishment.logo) {
			this.logo.uploadedImage = establishment.logo;
		}
	};

	public getEstablishment = async () => {
		this.loader.tryStart();
		try {
			if (this.legalUser.value && this.legalUser.value.establishment) {

				const user = await api.getEstablishment(this.legalUser.value.establishment.id);
				this.setValuesFormShelf(user);
			}
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			Errors.handleError(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public getLegalUser = async (id: string) => {
		this.loaderLegalUser.tryStart();
		try {
			const legalUser = await api.getLegalUser(id);
			this.legalUser.setValue(legalUser);
			await this.getEstablishment();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			Errors.handleError(errorMessage);
		} finally {
			this.loaderLegalUser.end();
		}
	};

	public setStateEnum = async (stateGooglePlace: string) => {
		const allStateEnum = api.allValuesStateUF();
		const stateFiltered = allStateEnum.find((state: api.StateUF) => state === stateGooglePlace);
		this.state.setValue(stateFiltered || api.StateUF.AC);
	};

	public onSelectAddress = async (placeSelected: mapsTypes.IPlaceDetailsResult) => {
		this.isDisabled.setValue(false);
		const data = this.form.getValues();
		data.city = this.getField(placeSelected, "administrative_area_level_2") || "";
		data.street = this.getField(placeSelected, "route") || "";
		data.neighborhood = this.getField(placeSelected, "sublocality_level_1") || "";
		data.zipcode = this.getField(placeSelected, "postal_code") || "";
		data.streetNumber = this.getField(placeSelected, "street_number") || "";
		this.setStateEnum(this.getField(placeSelected, "administrative_area_level_1") || "");
		this.lat.setValue(JSON.parse(JSON.stringify(placeSelected.geometry.location)).lat);
		this.lng.setValue(JSON.parse(JSON.stringify(placeSelected.geometry.location)).lng);
	};

	public createEstablishment = async (onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.form.getValues();

			const newEstablishment: api.NewEstablishment = {
				phone: data.phone,
				segment: this.segment.value,
				address: {
					neighborhood: data.neighborhood,
					city: data.city,
					state: this.state.value,
					street: data.street,
					streetNumber: data.streetNumber,
					zipcode: data.zipcode,
					complementary: data.complementary,
					countryCode: "BR",
					latLng: {
						lat: this.lat.value || 0,
						lng: this.lng.value || 0,
					},
				},
				image: this.image.uncertainfiedImage,
				logo: this.logo.uncertainfiedImage,
			};

			await api.createEstablishment(newEstablishment);
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public editEstablishment = async (userId: string, onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.form.getValues();

			const editEstablishment: api.EditEstablishment = {
				phone: data.phone,
				segment: this.segment.value,
				address: {
					neighborhood: data.neighborhood,
					city: data.city,
					state: this.state.value,
					street: data.street,
					streetNumber: data.streetNumber,
					zipcode: data.zipcode,
					complementary: data.complementary,
					countryCode: "BR",
					latLng: {
						lat: Number(this.lat.value),
						lng: Number(this.lng.value),
					},
				},
				image: this.image.uncertainfiedImage,
				logo: this.logo.uncertainfiedImage,
			};

			if (
				!this.authStore.currentAdminUser?.legalUser
				&& this.legalUser.value
				&& this.legalUser.value.legalUser
			) {
				await api.editEstablishmentForAdmin(userId, this.legalUser.value.legalUser, editEstablishment);
			} else {
				await api.editEstablishment(userId, editEstablishment);
			}
			onSuccess();
		} catch (e) {
			if (Errors.treatError(e).type === api.ErrorType.Validation) {
				this.form.fieldError.cleanAndAddErrors(JSON.parse(e.message));
			} else {
				showErrorToast(e.message);
			}
		} finally {
			this.loader.end();
		}
	};
}
