import React from "react";
import {
	Box,
	Td,
	IconButton,
	Tooltip,
	Image,
} from "@chakra-ui/react";
import { SearchIcon, EditIcon, DeleteIcon, CheckIcon } from "@chakra-ui/icons";
import strings from "~/resources/strings";

interface IProps {
	onDelete?: () => void;
	onView?: () => void;
	onEdit?: () => void;
	onApprove?: () => void;
	isCollect?: boolean;
}

export const TableCellWithActionButtons: React.FC<IProps> = (props) => {
	const {
		onDelete,
		onView,
		onEdit,
		onApprove,
		isCollect,
	} = props;

	return (
		<Td>
			<Box display="flex" justifyContent="flex-end" flexDirection="row">
				{onView && (
					<Tooltip label={strings.common.details}>
						<IconButton
							variant="icon"
							colorScheme="secondary"
							size="sm"
							aria-label="Search"
							icon={isCollect ? <Image h={4} w={7} src="/collectIcon.png" /> : <SearchIcon />}
							onClick={onView}
						/>
					</Tooltip>
				)}
				{onEdit && (
					<Tooltip label={strings.common.edit}>
						<IconButton
							variant="icon"
							colorScheme="secondary"
							aria-label="Edit"
							size="sm"
							icon={<EditIcon />}
							onClick={onEdit}
						/>
					</Tooltip>
				)}
				{onDelete && (
					<Tooltip label={strings.common.delete}>
						<IconButton
							variant="icon"
							colorScheme="secondary"
							size="sm"
							aria-label="Delete"
							onClick={onDelete}
							icon={<DeleteIcon />}
						/>
					</Tooltip>
				)}
				{onApprove && (
					<Tooltip label={strings.common.approve}>
						<IconButton
							variant="icon"
							colorScheme="secondary"
							size="sm"
							aria-label="Approve"
							onClick={onApprove}
							icon={<CheckIcon />}
						/>
					</Tooltip>
				)}
			</Box>
		</Td>
	);
};
