import { LocalizedStringsMethods } from "localized-strings";
import { UAParser } from "ua-parser-js";

let baseUrl: string = "localhost:8000";
let strings: (LocalizedStringsMethods & any) | null = null;

export function setUrl(url: string): void {
    baseUrl = url;
}

export function setStrings(newStrings: (LocalizedStringsMethods & {}) | null): void {
    strings = newStrings;
}
export interface FilterByPeriod {
    startDate: Date | null;
    endDate: Date | null;
}

export interface CreditCard {
    cardNumber: string;
    cvv: string;
    cardExpirationDate: string;
    cardHolderName: string;
}

export interface Subscription {
    id: number;
    currentTransaction: Transaction | null;
    paymentMethods: PaymentMethods;
    cardLastDigits: string | null;
    cardBrand: string | null;
    currentPeriodStart: string | null;
    currentPeriodEnd: string | null;
    charges: number;
    status: SubscriptionStatus;
    plan: Plan;
    dateCreated: string;
    address: PagarmeAddress | null;
}

export interface PagarmeAddress {
    zipcode: string;
    street: string;
    streetNumber: string;
    neighborhood: string;
    complementary: string | null;
}

export interface Phone {
    ddd: string;
    number: string;
}

export interface Image {
    thumb: SimpleImage;
    width: number;
    height: number;
    url: string;
}

export interface SimpleImage {
    width: number;
    height: number;
    url: string;
}

export interface LatLng {
    lat: number;
    lng: number;
}

export interface Banner {
    id: string;
    title: string;
    message: string;
    image: Image;
    contentImage: Image | null;
}

export interface Address {
    zipcode: string;
    street: string;
    streetNumber: string;
    complementary: string | null;
    neighborhood: string;
    city: string;
    state: StateUF;
    countryCode: string;
    latLng: LatLng;
}

export interface Transaction {
    id: string;
    boletoUrl: string | null;
    boletoBarcode: string | null;
    boletoExpirationDate: string | null;
    paymentMethod: PaymentMethods;
    status: TransactionStatus;
    statusReason: string;
    metadata: string;
    pixQRCode: string | null;
    qrExpirationDate: string | null;
    createdAt: Date;
    amount: number;
    lastFourDigits: string | null;
    cardBrand: string | null;
}

export interface FilterHomeOptions {
    name: string | null;
    segment: Segment | null;
    distance: LatLng | null;
}

export interface MaterialStatistics {
    Plastic: number;
    PaperOrCardboard: number;
    Metal: number;
    Electronics: number;
    BatteriesOrLamps: number;
}

export interface ProviderTokens {
    provider: Provider;
    token: string;
    accessToken: string | null;
}

export interface UncertainImage {
    bytes: Buffer | null;
    image: Image | null;
}

export interface User {
    id: string;
    email: string;
    name: string;
    phone: string;
    documentNumber: string | null;
    image: Image | null;
    createdAt: Date;
}

export interface EditUser {
    email: string;
    name: string;
    phone: string;
    documentNumber: string | null;
    image: UncertainImage | null;
}

export interface NewUser {
    email: string;
    password: string;
    name: string;
    phone: string;
    image: UncertainImage | null;
    documentNumber: string | null;
}

export interface NewUserForProvider {
    email: string;
    name: string;
    phone: string;
    image: UncertainImage | null;
    documentNumber: string | null;
}

export interface ProviderUserInfo {
    id: string;
    providerUserId: string;
    provider: Provider;
    email: string;
    user: User;
}

export interface EditLegalUser {
    fantasyName: string;
    documentNumber: string;
}

export interface EditLegalUserWithApp {
    showOnApp: boolean;
    fantasyName: string;
    documentNumber: string;
}

export interface LegalUser {
    id: string;
    fantasyName: string;
    documentNumber: string;
    signatureId: number | null;
    subscriptionStatus: SubscriptionStatus;
}

export interface Establishment {
    id: string;
    name: string;
    documentNumber: string;
    phone: string;
    logo: Image | null;
    image: Image | null;
    address: Address;
    segment: Segment;
    showOnApp: boolean;
}

export interface EditEstablishment {
    phone: string;
    logo: UncertainImage | null;
    image: UncertainImage | null;
    address: Address;
    segment: Segment;
}

export interface AdminUser {
    id: string;
    name: string;
    image: Image | null;
    email: string;
    type: AdminUserType;
    createdAt: Date;
    legalUser: LegalUser | null;
    establishment: Establishment | null;
}

export interface NewAdminUser {
    email: string;
    name: string;
    password: string;
}

export interface NewAdminUserCooperative {
    email: string;
    name: string;
    password: string;
    fantasyName: string;
    documentNumber: string;
}

export interface EditAdminUser {
    name: string;
    email: string;
    image: UncertainImage | null;
}

export interface CheckIn {
    id: string;
    invoice: string;
    ticket: number;
    user: User;
    isOnDraw: boolean;
    establishment: Establishment;
    createdAt: Date;
}

export interface Collect {
    id: string;
    code: string;
    adminUser: AdminUser;
    collectDate: Date | null;
    turn: Turn;
    status: CollectStatus;
    description: string | null;
    createdAt: Date;
    address: Address;
    recoveredCO2: number;
    cooperative: AdminUser | null;
}

export interface NewCollectOrder {
    collectDate: Date | null;
    turn: Turn;
    description: string | null;
}

export interface NewCollectOrderWithStatus {
    collectDate: Date | null;
    turn: Turn;
    status: CollectStatus;
    description: string | null;
    recoveredCO2: number;
}

export interface EditCollectOrder {
    collectDate: Date | null;
    turn: Turn;
    status: CollectStatus;
    description: string | null;
}

export interface DeleteRequest {
    id: string;
    user: User;
    createdAt: Date;
}

export interface NewEstablishment {
    phone: string;
    logo: UncertainImage | null;
    image: UncertainImage | null;
    address: Address;
    segment: Segment;
}

export interface Material {
    id: string;
    materialCode: string;
    category: MaterialCategory;
    weight: number;
    date: Date;
}

export interface MaterialWithCollect {
    collect: Collect;
    id: string;
    materialCode: string;
    category: MaterialCategory;
    weight: number;
    date: Date;
}

export interface NewMaterial {
    category: MaterialCategory;
    weight: number;
    date: Date;
    collectId: string;
}

export interface EditMaterial {
    category: MaterialCategory;
    weight: number;
    date: Date;
}

export interface FilterByPeriodAndLegalUser {
    adminUserId: string | null;
    startDate: Date | null;
    endDate: Date | null;
}

export enum Plan {
    GreenSeal = "GreenSeal",
    EcoPoint = "EcoPoint",
}

export function translatePlan(enumPlan: Plan): string {
    switch (enumPlan) {
        case Plan.GreenSeal: {
            return strings ? strings["enum"]["Plan"]["GreenSeal"] || Plan.GreenSeal : Plan.GreenSeal;
        }
        case Plan.EcoPoint: {
            return strings ? strings["enum"]["Plan"]["EcoPoint"] || Plan.EcoPoint : Plan.EcoPoint;
        }
    }
    return "";
}

export function allValuesPlan(): Plan[] {
    return [
        Plan.GreenSeal,
        Plan.EcoPoint,
    ];
}

export function allTranslatedValuesPlan(): string[] {
    return [
        translatePlan(Plan.GreenSeal),
        translatePlan(Plan.EcoPoint),
    ];
}

export function allDisplayableValuesPlan(): string[] {
    return allTranslatedValuesPlan().sort();
}

export function valueFromTranslationPlan(translation: string): Plan {
    const index = allTranslatedValuesPlan().indexOf(translation);
    return allValuesPlan()[index] || Plan.GreenSeal;
}

export enum AdminUserType {
    adminUser = "adminUser",
    cooperative = "cooperative",
    condominium = "condominium",
    user = "user",
}

export function translateAdminUserType(enumAdminUserType: AdminUserType): string {
    switch (enumAdminUserType) {
        case AdminUserType.adminUser: {
            return strings ? strings["enum"]["AdminUserType"]["adminUser"] || AdminUserType.adminUser : AdminUserType.adminUser;
        }
        case AdminUserType.cooperative: {
            return strings ? strings["enum"]["AdminUserType"]["cooperative"] || AdminUserType.cooperative : AdminUserType.cooperative;
        }
        case AdminUserType.condominium: {
            return strings ? strings["enum"]["AdminUserType"]["condominium"] || AdminUserType.condominium : AdminUserType.condominium;
        }
        case AdminUserType.user: {
            return strings ? strings["enum"]["AdminUserType"]["user"] || AdminUserType.user : AdminUserType.user;
        }
    }
    return "";
}

export function allValuesAdminUserType(): AdminUserType[] {
    return [
        AdminUserType.adminUser,
        AdminUserType.cooperative,
        AdminUserType.condominium,
        AdminUserType.user,
    ];
}

export function allTranslatedValuesAdminUserType(): string[] {
    return [
        translateAdminUserType(AdminUserType.adminUser),
        translateAdminUserType(AdminUserType.cooperative),
        translateAdminUserType(AdminUserType.condominium),
        translateAdminUserType(AdminUserType.user),
    ];
}

export function allDisplayableValuesAdminUserType(): string[] {
    return allTranslatedValuesAdminUserType().sort();
}

export function valueFromTranslationAdminUserType(translation: string): AdminUserType {
    const index = allTranslatedValuesAdminUserType().indexOf(translation);
    return allValuesAdminUserType()[index] || AdminUserType.adminUser;
}

export enum PaymentMethods {
    creditCard = "creditCard",
    boleto = "boleto",
}

export function translatePaymentMethods(enumPaymentMethods: PaymentMethods): string {
    switch (enumPaymentMethods) {
        case PaymentMethods.creditCard: {
            return strings ? strings["enum"]["PaymentMethods"]["creditCard"] || PaymentMethods.creditCard : PaymentMethods.creditCard;
        }
        case PaymentMethods.boleto: {
            return strings ? strings["enum"]["PaymentMethods"]["boleto"] || PaymentMethods.boleto : PaymentMethods.boleto;
        }
    }
    return "";
}

export function allValuesPaymentMethods(): PaymentMethods[] {
    return [
        PaymentMethods.creditCard,
        PaymentMethods.boleto,
    ];
}

export function allTranslatedValuesPaymentMethods(): string[] {
    return [
        translatePaymentMethods(PaymentMethods.creditCard),
        translatePaymentMethods(PaymentMethods.boleto),
    ];
}

export function allDisplayableValuesPaymentMethods(): string[] {
    return allTranslatedValuesPaymentMethods().sort();
}

export function valueFromTranslationPaymentMethods(translation: string): PaymentMethods {
    const index = allTranslatedValuesPaymentMethods().indexOf(translation);
    return allValuesPaymentMethods()[index] || PaymentMethods.creditCard;
}

export enum SubscriptionStatus {
    trialing = "trialing",
    paid = "paid",
    pendingPayment = "pendingPayment",
    unpaid = "unpaid",
    canceled = "canceled",
    ended = "ended",
}

export function translateSubscriptionStatus(enumSubscriptionStatus: SubscriptionStatus): string {
    switch (enumSubscriptionStatus) {
        case SubscriptionStatus.trialing: {
            return strings ? strings["enum"]["SubscriptionStatus"]["trialing"] || SubscriptionStatus.trialing : SubscriptionStatus.trialing;
        }
        case SubscriptionStatus.paid: {
            return strings ? strings["enum"]["SubscriptionStatus"]["paid"] || SubscriptionStatus.paid : SubscriptionStatus.paid;
        }
        case SubscriptionStatus.pendingPayment: {
            return strings ? strings["enum"]["SubscriptionStatus"]["pendingPayment"] || SubscriptionStatus.pendingPayment : SubscriptionStatus.pendingPayment;
        }
        case SubscriptionStatus.unpaid: {
            return strings ? strings["enum"]["SubscriptionStatus"]["unpaid"] || SubscriptionStatus.unpaid : SubscriptionStatus.unpaid;
        }
        case SubscriptionStatus.canceled: {
            return strings ? strings["enum"]["SubscriptionStatus"]["canceled"] || SubscriptionStatus.canceled : SubscriptionStatus.canceled;
        }
        case SubscriptionStatus.ended: {
            return strings ? strings["enum"]["SubscriptionStatus"]["ended"] || SubscriptionStatus.ended : SubscriptionStatus.ended;
        }
    }
    return "";
}

export function allValuesSubscriptionStatus(): SubscriptionStatus[] {
    return [
        SubscriptionStatus.trialing,
        SubscriptionStatus.paid,
        SubscriptionStatus.pendingPayment,
        SubscriptionStatus.unpaid,
        SubscriptionStatus.canceled,
        SubscriptionStatus.ended,
    ];
}

export function allTranslatedValuesSubscriptionStatus(): string[] {
    return [
        translateSubscriptionStatus(SubscriptionStatus.trialing),
        translateSubscriptionStatus(SubscriptionStatus.paid),
        translateSubscriptionStatus(SubscriptionStatus.pendingPayment),
        translateSubscriptionStatus(SubscriptionStatus.unpaid),
        translateSubscriptionStatus(SubscriptionStatus.canceled),
        translateSubscriptionStatus(SubscriptionStatus.ended),
    ];
}

export function allDisplayableValuesSubscriptionStatus(): string[] {
    return allTranslatedValuesSubscriptionStatus().sort();
}

export function valueFromTranslationSubscriptionStatus(translation: string): SubscriptionStatus {
    const index = allTranslatedValuesSubscriptionStatus().indexOf(translation);
    return allValuesSubscriptionStatus()[index] || SubscriptionStatus.trialing;
}

export enum StateUF {
    AC = "AC",
    AL = "AL",
    AP = "AP",
    AM = "AM",
    BA = "BA",
    CE = "CE",
    DF = "DF",
    ES = "ES",
    GO = "GO",
    MA = "MA",
    MT = "MT",
    MS = "MS",
    MG = "MG",
    PA = "PA",
    PB = "PB",
    PR = "PR",
    PE = "PE",
    PI = "PI",
    RJ = "RJ",
    RN = "RN",
    RS = "RS",
    RO = "RO",
    RR = "RR",
    SC = "SC",
    SP = "SP",
    SE = "SE",
    TO = "TO",
}

export function translateStateUF(enumStateUF: StateUF): string {
    switch (enumStateUF) {
        case StateUF.AC: {
            return strings ? strings["enum"]["StateUF"]["AC"] || StateUF.AC : StateUF.AC;
        }
        case StateUF.AL: {
            return strings ? strings["enum"]["StateUF"]["AL"] || StateUF.AL : StateUF.AL;
        }
        case StateUF.AP: {
            return strings ? strings["enum"]["StateUF"]["AP"] || StateUF.AP : StateUF.AP;
        }
        case StateUF.AM: {
            return strings ? strings["enum"]["StateUF"]["AM"] || StateUF.AM : StateUF.AM;
        }
        case StateUF.BA: {
            return strings ? strings["enum"]["StateUF"]["BA"] || StateUF.BA : StateUF.BA;
        }
        case StateUF.CE: {
            return strings ? strings["enum"]["StateUF"]["CE"] || StateUF.CE : StateUF.CE;
        }
        case StateUF.DF: {
            return strings ? strings["enum"]["StateUF"]["DF"] || StateUF.DF : StateUF.DF;
        }
        case StateUF.ES: {
            return strings ? strings["enum"]["StateUF"]["ES"] || StateUF.ES : StateUF.ES;
        }
        case StateUF.GO: {
            return strings ? strings["enum"]["StateUF"]["GO"] || StateUF.GO : StateUF.GO;
        }
        case StateUF.MA: {
            return strings ? strings["enum"]["StateUF"]["MA"] || StateUF.MA : StateUF.MA;
        }
        case StateUF.MT: {
            return strings ? strings["enum"]["StateUF"]["MT"] || StateUF.MT : StateUF.MT;
        }
        case StateUF.MS: {
            return strings ? strings["enum"]["StateUF"]["MS"] || StateUF.MS : StateUF.MS;
        }
        case StateUF.MG: {
            return strings ? strings["enum"]["StateUF"]["MG"] || StateUF.MG : StateUF.MG;
        }
        case StateUF.PA: {
            return strings ? strings["enum"]["StateUF"]["PA"] || StateUF.PA : StateUF.PA;
        }
        case StateUF.PB: {
            return strings ? strings["enum"]["StateUF"]["PB"] || StateUF.PB : StateUF.PB;
        }
        case StateUF.PR: {
            return strings ? strings["enum"]["StateUF"]["PR"] || StateUF.PR : StateUF.PR;
        }
        case StateUF.PE: {
            return strings ? strings["enum"]["StateUF"]["PE"] || StateUF.PE : StateUF.PE;
        }
        case StateUF.PI: {
            return strings ? strings["enum"]["StateUF"]["PI"] || StateUF.PI : StateUF.PI;
        }
        case StateUF.RJ: {
            return strings ? strings["enum"]["StateUF"]["RJ"] || StateUF.RJ : StateUF.RJ;
        }
        case StateUF.RN: {
            return strings ? strings["enum"]["StateUF"]["RN"] || StateUF.RN : StateUF.RN;
        }
        case StateUF.RS: {
            return strings ? strings["enum"]["StateUF"]["RS"] || StateUF.RS : StateUF.RS;
        }
        case StateUF.RO: {
            return strings ? strings["enum"]["StateUF"]["RO"] || StateUF.RO : StateUF.RO;
        }
        case StateUF.RR: {
            return strings ? strings["enum"]["StateUF"]["RR"] || StateUF.RR : StateUF.RR;
        }
        case StateUF.SC: {
            return strings ? strings["enum"]["StateUF"]["SC"] || StateUF.SC : StateUF.SC;
        }
        case StateUF.SP: {
            return strings ? strings["enum"]["StateUF"]["SP"] || StateUF.SP : StateUF.SP;
        }
        case StateUF.SE: {
            return strings ? strings["enum"]["StateUF"]["SE"] || StateUF.SE : StateUF.SE;
        }
        case StateUF.TO: {
            return strings ? strings["enum"]["StateUF"]["TO"] || StateUF.TO : StateUF.TO;
        }
    }
    return "";
}

export function allValuesStateUF(): StateUF[] {
    return [
        StateUF.AC,
        StateUF.AL,
        StateUF.AP,
        StateUF.AM,
        StateUF.BA,
        StateUF.CE,
        StateUF.DF,
        StateUF.ES,
        StateUF.GO,
        StateUF.MA,
        StateUF.MT,
        StateUF.MS,
        StateUF.MG,
        StateUF.PA,
        StateUF.PB,
        StateUF.PR,
        StateUF.PE,
        StateUF.PI,
        StateUF.RJ,
        StateUF.RN,
        StateUF.RS,
        StateUF.RO,
        StateUF.RR,
        StateUF.SC,
        StateUF.SP,
        StateUF.SE,
        StateUF.TO,
    ];
}

export function allTranslatedValuesStateUF(): string[] {
    return [
        translateStateUF(StateUF.AC),
        translateStateUF(StateUF.AL),
        translateStateUF(StateUF.AP),
        translateStateUF(StateUF.AM),
        translateStateUF(StateUF.BA),
        translateStateUF(StateUF.CE),
        translateStateUF(StateUF.DF),
        translateStateUF(StateUF.ES),
        translateStateUF(StateUF.GO),
        translateStateUF(StateUF.MA),
        translateStateUF(StateUF.MT),
        translateStateUF(StateUF.MS),
        translateStateUF(StateUF.MG),
        translateStateUF(StateUF.PA),
        translateStateUF(StateUF.PB),
        translateStateUF(StateUF.PR),
        translateStateUF(StateUF.PE),
        translateStateUF(StateUF.PI),
        translateStateUF(StateUF.RJ),
        translateStateUF(StateUF.RN),
        translateStateUF(StateUF.RS),
        translateStateUF(StateUF.RO),
        translateStateUF(StateUF.RR),
        translateStateUF(StateUF.SC),
        translateStateUF(StateUF.SP),
        translateStateUF(StateUF.SE),
        translateStateUF(StateUF.TO),
    ];
}

export function allDisplayableValuesStateUF(): string[] {
    return allTranslatedValuesStateUF().sort();
}

export function valueFromTranslationStateUF(translation: string): StateUF {
    const index = allTranslatedValuesStateUF().indexOf(translation);
    return allValuesStateUF()[index] || StateUF.AC;
}

export enum BankAccountType {
    contaCorrente = "contaCorrente",
    contaPoupanca = "contaPoupanca",
    contaCorrenteConjunta = "contaCorrenteConjunta",
    contaPoupancaConjunta = "contaPoupancaConjunta",
}

export function translateBankAccountType(enumBankAccountType: BankAccountType): string {
    switch (enumBankAccountType) {
        case BankAccountType.contaCorrente: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrente"] || BankAccountType.contaCorrente : BankAccountType.contaCorrente;
        }
        case BankAccountType.contaPoupanca: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupanca"] || BankAccountType.contaPoupanca : BankAccountType.contaPoupanca;
        }
        case BankAccountType.contaCorrenteConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaCorrenteConjunta"] || BankAccountType.contaCorrenteConjunta : BankAccountType.contaCorrenteConjunta;
        }
        case BankAccountType.contaPoupancaConjunta: {
            return strings ? strings["enum"]["BankAccountType"]["contaPoupancaConjunta"] || BankAccountType.contaPoupancaConjunta : BankAccountType.contaPoupancaConjunta;
        }
    }
    return "";
}

export function allValuesBankAccountType(): BankAccountType[] {
    return [
        BankAccountType.contaCorrente,
        BankAccountType.contaPoupanca,
        BankAccountType.contaCorrenteConjunta,
        BankAccountType.contaPoupancaConjunta,
    ];
}

export function allTranslatedValuesBankAccountType(): string[] {
    return [
        translateBankAccountType(BankAccountType.contaCorrente),
        translateBankAccountType(BankAccountType.contaPoupanca),
        translateBankAccountType(BankAccountType.contaCorrenteConjunta),
        translateBankAccountType(BankAccountType.contaPoupancaConjunta),
    ];
}

export function allDisplayableValuesBankAccountType(): string[] {
    return allTranslatedValuesBankAccountType().sort();
}

export function valueFromTranslationBankAccountType(translation: string): BankAccountType {
    const index = allTranslatedValuesBankAccountType().indexOf(translation);
    return allValuesBankAccountType()[index] || BankAccountType.contaCorrente;
}

export enum FilterOrder {
    desc = "desc",
    asc = "asc",
}

export function translateFilterOrder(enumFilterOrder: FilterOrder): string {
    switch (enumFilterOrder) {
        case FilterOrder.desc: {
            return strings ? strings["enum"]["FilterOrder"]["desc"] || FilterOrder.desc : FilterOrder.desc;
        }
        case FilterOrder.asc: {
            return strings ? strings["enum"]["FilterOrder"]["asc"] || FilterOrder.asc : FilterOrder.asc;
        }
    }
    return "";
}

export function allValuesFilterOrder(): FilterOrder[] {
    return [
        FilterOrder.desc,
        FilterOrder.asc,
    ];
}

export function allTranslatedValuesFilterOrder(): string[] {
    return [
        translateFilterOrder(FilterOrder.desc),
        translateFilterOrder(FilterOrder.asc),
    ];
}

export function allDisplayableValuesFilterOrder(): string[] {
    return allTranslatedValuesFilterOrder().sort();
}

export function valueFromTranslationFilterOrder(translation: string): FilterOrder {
    const index = allTranslatedValuesFilterOrder().indexOf(translation);
    return allValuesFilterOrder()[index] || FilterOrder.desc;
}

export enum TransactionStatus {
    processing = "processing",
    authorized = "authorized",
    paid = "paid",
    refunded = "refunded",
    waitingPayment = "waitingPayment",
    pendingRefund = "pendingRefund",
    refused = "refused",
    chargedback = "chargedback",
}

export function translateTransactionStatus(enumTransactionStatus: TransactionStatus): string {
    switch (enumTransactionStatus) {
        case TransactionStatus.processing: {
            return strings ? strings["enum"]["TransactionStatus"]["processing"] || TransactionStatus.processing : TransactionStatus.processing;
        }
        case TransactionStatus.authorized: {
            return strings ? strings["enum"]["TransactionStatus"]["authorized"] || TransactionStatus.authorized : TransactionStatus.authorized;
        }
        case TransactionStatus.paid: {
            return strings ? strings["enum"]["TransactionStatus"]["paid"] || TransactionStatus.paid : TransactionStatus.paid;
        }
        case TransactionStatus.refunded: {
            return strings ? strings["enum"]["TransactionStatus"]["refunded"] || TransactionStatus.refunded : TransactionStatus.refunded;
        }
        case TransactionStatus.waitingPayment: {
            return strings ? strings["enum"]["TransactionStatus"]["waitingPayment"] || TransactionStatus.waitingPayment : TransactionStatus.waitingPayment;
        }
        case TransactionStatus.pendingRefund: {
            return strings ? strings["enum"]["TransactionStatus"]["pendingRefund"] || TransactionStatus.pendingRefund : TransactionStatus.pendingRefund;
        }
        case TransactionStatus.refused: {
            return strings ? strings["enum"]["TransactionStatus"]["refused"] || TransactionStatus.refused : TransactionStatus.refused;
        }
        case TransactionStatus.chargedback: {
            return strings ? strings["enum"]["TransactionStatus"]["chargedback"] || TransactionStatus.chargedback : TransactionStatus.chargedback;
        }
    }
    return "";
}

export function allValuesTransactionStatus(): TransactionStatus[] {
    return [
        TransactionStatus.processing,
        TransactionStatus.authorized,
        TransactionStatus.paid,
        TransactionStatus.refunded,
        TransactionStatus.waitingPayment,
        TransactionStatus.pendingRefund,
        TransactionStatus.refused,
        TransactionStatus.chargedback,
    ];
}

export function allTranslatedValuesTransactionStatus(): string[] {
    return [
        translateTransactionStatus(TransactionStatus.processing),
        translateTransactionStatus(TransactionStatus.authorized),
        translateTransactionStatus(TransactionStatus.paid),
        translateTransactionStatus(TransactionStatus.refunded),
        translateTransactionStatus(TransactionStatus.waitingPayment),
        translateTransactionStatus(TransactionStatus.pendingRefund),
        translateTransactionStatus(TransactionStatus.refused),
        translateTransactionStatus(TransactionStatus.chargedback),
    ];
}

export function allDisplayableValuesTransactionStatus(): string[] {
    return allTranslatedValuesTransactionStatus().sort();
}

export function valueFromTranslationTransactionStatus(translation: string): TransactionStatus {
    const index = allTranslatedValuesTransactionStatus().indexOf(translation);
    return allValuesTransactionStatus()[index] || TransactionStatus.processing;
}

export enum Segment {
    clothes = "clothes",
    vegan = "vegan",
    drugstore = "drugstore",
    bakery = "bakery",
    hotels = "hotels",
    organic = "organic",
    market = "market",
    restaurant = "restaurant",
    bar = "bar",
    snackShop = "snackShop",
    coffeeShop = "coffeeShop",
    ice = "ice",
    shoeStore = "shoeStore",
    toyStore = "toyStore",
    pizzeria = "pizzeria",
    cosmeticStore = "cosmeticStore",
    healthBranch = "healthBranch",
    leisure = "leisure",
}

export function translateSegment(enumSegment: Segment): string {
    switch (enumSegment) {
        case Segment.clothes: {
            return strings ? strings["enum"]["Segment"]["clothes"] || Segment.clothes : Segment.clothes;
        }
        case Segment.vegan: {
            return strings ? strings["enum"]["Segment"]["vegan"] || Segment.vegan : Segment.vegan;
        }
        case Segment.drugstore: {
            return strings ? strings["enum"]["Segment"]["drugstore"] || Segment.drugstore : Segment.drugstore;
        }
        case Segment.bakery: {
            return strings ? strings["enum"]["Segment"]["bakery"] || Segment.bakery : Segment.bakery;
        }
        case Segment.hotels: {
            return strings ? strings["enum"]["Segment"]["hotels"] || Segment.hotels : Segment.hotels;
        }
        case Segment.organic: {
            return strings ? strings["enum"]["Segment"]["organic"] || Segment.organic : Segment.organic;
        }
        case Segment.market: {
            return strings ? strings["enum"]["Segment"]["market"] || Segment.market : Segment.market;
        }
        case Segment.restaurant: {
            return strings ? strings["enum"]["Segment"]["restaurant"] || Segment.restaurant : Segment.restaurant;
        }
        case Segment.bar: {
            return strings ? strings["enum"]["Segment"]["bar"] || Segment.bar : Segment.bar;
        }
        case Segment.snackShop: {
            return strings ? strings["enum"]["Segment"]["snackShop"] || Segment.snackShop : Segment.snackShop;
        }
        case Segment.coffeeShop: {
            return strings ? strings["enum"]["Segment"]["coffeeShop"] || Segment.coffeeShop : Segment.coffeeShop;
        }
        case Segment.ice: {
            return strings ? strings["enum"]["Segment"]["ice"] || Segment.ice : Segment.ice;
        }
        case Segment.shoeStore: {
            return strings ? strings["enum"]["Segment"]["shoeStore"] || Segment.shoeStore : Segment.shoeStore;
        }
        case Segment.toyStore: {
            return strings ? strings["enum"]["Segment"]["toyStore"] || Segment.toyStore : Segment.toyStore;
        }
        case Segment.pizzeria: {
            return strings ? strings["enum"]["Segment"]["pizzeria"] || Segment.pizzeria : Segment.pizzeria;
        }
        case Segment.cosmeticStore: {
            return strings ? strings["enum"]["Segment"]["cosmeticStore"] || Segment.cosmeticStore : Segment.cosmeticStore;
        }
        case Segment.healthBranch: {
            return strings ? strings["enum"]["Segment"]["healthBranch"] || Segment.healthBranch : Segment.healthBranch;
        }
        case Segment.leisure: {
            return strings ? strings["enum"]["Segment"]["leisure"] || Segment.leisure : Segment.leisure;
        }
    }
    return "";
}

export function allValuesSegment(): Segment[] {
    return [
        Segment.clothes,
        Segment.vegan,
        Segment.drugstore,
        Segment.bakery,
        Segment.hotels,
        Segment.organic,
        Segment.market,
        Segment.restaurant,
        Segment.bar,
        Segment.snackShop,
        Segment.coffeeShop,
        Segment.ice,
        Segment.shoeStore,
        Segment.toyStore,
        Segment.pizzeria,
        Segment.cosmeticStore,
        Segment.healthBranch,
        Segment.leisure,
    ];
}

export function allTranslatedValuesSegment(): string[] {
    return [
        translateSegment(Segment.clothes),
        translateSegment(Segment.vegan),
        translateSegment(Segment.drugstore),
        translateSegment(Segment.bakery),
        translateSegment(Segment.hotels),
        translateSegment(Segment.organic),
        translateSegment(Segment.market),
        translateSegment(Segment.restaurant),
        translateSegment(Segment.bar),
        translateSegment(Segment.snackShop),
        translateSegment(Segment.coffeeShop),
        translateSegment(Segment.ice),
        translateSegment(Segment.shoeStore),
        translateSegment(Segment.toyStore),
        translateSegment(Segment.pizzeria),
        translateSegment(Segment.cosmeticStore),
        translateSegment(Segment.healthBranch),
        translateSegment(Segment.leisure),
    ];
}

export function allDisplayableValuesSegment(): string[] {
    return allTranslatedValuesSegment().sort();
}

export function valueFromTranslationSegment(translation: string): Segment {
    const index = allTranslatedValuesSegment().indexOf(translation);
    return allValuesSegment()[index] || Segment.clothes;
}

export enum CollectStatus {
    scheduled = "scheduled",
    cancelled = "cancelled",
    done = "done",
}

export function translateCollectStatus(enumCollectStatus: CollectStatus): string {
    switch (enumCollectStatus) {
        case CollectStatus.scheduled: {
            return strings ? strings["enum"]["CollectStatus"]["scheduled"] || CollectStatus.scheduled : CollectStatus.scheduled;
        }
        case CollectStatus.cancelled: {
            return strings ? strings["enum"]["CollectStatus"]["cancelled"] || CollectStatus.cancelled : CollectStatus.cancelled;
        }
        case CollectStatus.done: {
            return strings ? strings["enum"]["CollectStatus"]["done"] || CollectStatus.done : CollectStatus.done;
        }
    }
    return "";
}

export function allValuesCollectStatus(): CollectStatus[] {
    return [
        CollectStatus.scheduled,
        CollectStatus.cancelled,
        CollectStatus.done,
    ];
}

export function allTranslatedValuesCollectStatus(): string[] {
    return [
        translateCollectStatus(CollectStatus.scheduled),
        translateCollectStatus(CollectStatus.cancelled),
        translateCollectStatus(CollectStatus.done),
    ];
}

export function allDisplayableValuesCollectStatus(): string[] {
    return allTranslatedValuesCollectStatus().sort();
}

export function valueFromTranslationCollectStatus(translation: string): CollectStatus {
    const index = allTranslatedValuesCollectStatus().indexOf(translation);
    return allValuesCollectStatus()[index] || CollectStatus.scheduled;
}

export enum Turn {
    morning = "morning",
    evening = "evening",
}

export function translateTurn(enumTurn: Turn): string {
    switch (enumTurn) {
        case Turn.morning: {
            return strings ? strings["enum"]["Turn"]["morning"] || Turn.morning : Turn.morning;
        }
        case Turn.evening: {
            return strings ? strings["enum"]["Turn"]["evening"] || Turn.evening : Turn.evening;
        }
    }
    return "";
}

export function allValuesTurn(): Turn[] {
    return [
        Turn.morning,
        Turn.evening,
    ];
}

export function allTranslatedValuesTurn(): string[] {
    return [
        translateTurn(Turn.morning),
        translateTurn(Turn.evening),
    ];
}

export function allDisplayableValuesTurn(): string[] {
    return allTranslatedValuesTurn().sort();
}

export function valueFromTranslationTurn(translation: string): Turn {
    const index = allTranslatedValuesTurn().indexOf(translation);
    return allValuesTurn()[index] || Turn.morning;
}

export enum MaterialCategory {
    Plastic = "Plastic",
    PaperOrCardboard = "PaperOrCardboard",
    Metal = "Metal",
    Electronics = "Electronics",
    BatteriesOrLamps = "BatteriesOrLamps",
}

export function translateMaterialCategory(enumMaterialCategory: MaterialCategory): string {
    switch (enumMaterialCategory) {
        case MaterialCategory.Plastic: {
            return strings ? strings["enum"]["MaterialCategory"]["Plastic"] || MaterialCategory.Plastic : MaterialCategory.Plastic;
        }
        case MaterialCategory.PaperOrCardboard: {
            return strings ? strings["enum"]["MaterialCategory"]["PaperOrCardboard"] || MaterialCategory.PaperOrCardboard : MaterialCategory.PaperOrCardboard;
        }
        case MaterialCategory.Metal: {
            return strings ? strings["enum"]["MaterialCategory"]["Metal"] || MaterialCategory.Metal : MaterialCategory.Metal;
        }
        case MaterialCategory.Electronics: {
            return strings ? strings["enum"]["MaterialCategory"]["Electronics"] || MaterialCategory.Electronics : MaterialCategory.Electronics;
        }
        case MaterialCategory.BatteriesOrLamps: {
            return strings ? strings["enum"]["MaterialCategory"]["BatteriesOrLamps"] || MaterialCategory.BatteriesOrLamps : MaterialCategory.BatteriesOrLamps;
        }
    }
    return "";
}

export function allValuesMaterialCategory(): MaterialCategory[] {
    return [
        MaterialCategory.Plastic,
        MaterialCategory.PaperOrCardboard,
        MaterialCategory.Metal,
        MaterialCategory.Electronics,
        MaterialCategory.BatteriesOrLamps,
    ];
}

export function allTranslatedValuesMaterialCategory(): string[] {
    return [
        translateMaterialCategory(MaterialCategory.Plastic),
        translateMaterialCategory(MaterialCategory.PaperOrCardboard),
        translateMaterialCategory(MaterialCategory.Metal),
        translateMaterialCategory(MaterialCategory.Electronics),
        translateMaterialCategory(MaterialCategory.BatteriesOrLamps),
    ];
}

export function allDisplayableValuesMaterialCategory(): string[] {
    return allTranslatedValuesMaterialCategory().sort();
}

export function valueFromTranslationMaterialCategory(translation: string): MaterialCategory {
    const index = allTranslatedValuesMaterialCategory().indexOf(translation);
    return allValuesMaterialCategory()[index] || MaterialCategory.Plastic;
}

export enum SignatureStatus {
    NoPaid = "NoPaid",
    Paid = "Paid",
}

export function translateSignatureStatus(enumSignatureStatus: SignatureStatus): string {
    switch (enumSignatureStatus) {
        case SignatureStatus.NoPaid: {
            return strings ? strings["enum"]["SignatureStatus"]["NoPaid"] || SignatureStatus.NoPaid : SignatureStatus.NoPaid;
        }
        case SignatureStatus.Paid: {
            return strings ? strings["enum"]["SignatureStatus"]["Paid"] || SignatureStatus.Paid : SignatureStatus.Paid;
        }
    }
    return "";
}

export function allValuesSignatureStatus(): SignatureStatus[] {
    return [
        SignatureStatus.NoPaid,
        SignatureStatus.Paid,
    ];
}

export function allTranslatedValuesSignatureStatus(): string[] {
    return [
        translateSignatureStatus(SignatureStatus.NoPaid),
        translateSignatureStatus(SignatureStatus.Paid),
    ];
}

export function allDisplayableValuesSignatureStatus(): string[] {
    return allTranslatedValuesSignatureStatus().sort();
}

export function valueFromTranslationSignatureStatus(translation: string): SignatureStatus {
    const index = allTranslatedValuesSignatureStatus().indexOf(translation);
    return allValuesSignatureStatus()[index] || SignatureStatus.NoPaid;
}

export enum Provider {
    google = "google",
    facebook = "facebook",
    apple = "apple",
}

export function translateProvider(enumProvider: Provider): string {
    switch (enumProvider) {
        case Provider.google: {
            return strings ? strings["enum"]["Provider"]["google"] || Provider.google : Provider.google;
        }
        case Provider.facebook: {
            return strings ? strings["enum"]["Provider"]["facebook"] || Provider.facebook : Provider.facebook;
        }
        case Provider.apple: {
            return strings ? strings["enum"]["Provider"]["apple"] || Provider.apple : Provider.apple;
        }
    }
    return "";
}

export function allValuesProvider(): Provider[] {
    return [
        Provider.google,
        Provider.facebook,
        Provider.apple,
    ];
}

export function allTranslatedValuesProvider(): string[] {
    return [
        translateProvider(Provider.google),
        translateProvider(Provider.facebook),
        translateProvider(Provider.apple),
    ];
}

export function allDisplayableValuesProvider(): string[] {
    return allTranslatedValuesProvider().sort();
}

export function valueFromTranslationProvider(translation: string): Provider {
    const index = allTranslatedValuesProvider().indexOf(translation);
    return allValuesProvider()[index] || Provider.google;
}

export enum ImageFormat {
    png = "png",
    jpeg = "jpeg",
}

export function translateImageFormat(enumImageFormat: ImageFormat): string {
    switch (enumImageFormat) {
        case ImageFormat.png: {
            return strings ? strings["enum"]["ImageFormat"]["png"] || ImageFormat.png : ImageFormat.png;
        }
        case ImageFormat.jpeg: {
            return strings ? strings["enum"]["ImageFormat"]["jpeg"] || ImageFormat.jpeg : ImageFormat.jpeg;
        }
    }
    return "";
}

export function allValuesImageFormat(): ImageFormat[] {
    return [
        ImageFormat.png,
        ImageFormat.jpeg,
    ];
}

export function allTranslatedValuesImageFormat(): string[] {
    return [
        translateImageFormat(ImageFormat.png),
        translateImageFormat(ImageFormat.jpeg),
    ];
}

export function allDisplayableValuesImageFormat(): string[] {
    return allTranslatedValuesImageFormat().sort();
}

export function valueFromTranslationImageFormat(translation: string): ImageFormat {
    const index = allTranslatedValuesImageFormat().indexOf(translation);
    return allValuesImageFormat()[index] || ImageFormat.png;
}

export enum ErrorType {
    NotFound = "NotFound",
    MissingArgument = "MissingArgument",
    InvalidArgument = "InvalidArgument",
    InvalidPermission = "InvalidPermission",
    BadFormattedResponse = "BadFormattedResponse",
    InternalError = "InternalError",
    Validation = "Validation",
    EmailOrPasswordWrong = "EmailOrPasswordWrong",
    AlreadyRegistered = "AlreadyRegistered",
    AccessNotAllowed = "AccessNotAllowed",
    EmailAlreadyRegistered = "EmailAlreadyRegistered",
    NickAlreadyRegistered = "NickAlreadyRegistered",
    CPFAlreadyRegistered = "CPFAlreadyRegistered",
    CNPJAlreadyRegistered = "CNPJAlreadyRegistered",
    NotLoggedIn = "NotLoggedIn",
    AdminUserIsNotALegalUser = "AdminUserIsNotALegalUser",
    LegalUserDoesNotHaveSubscription = "LegalUserDoesNotHaveSubscription",
    InvalidAccessToken = "InvalidAccessToken",
    ProiverUserIsNotRegistered = "ProiverUserIsNotRegistered",
    UserDoesNotHaveADocumentNumber = "UserDoesNotHaveADocumentNumber",
    UserIsLinkedByExternalProvider = "UserIsLinkedByExternalProvider",
    PagarMeBankAccount = "PagarMeBankAccount",
    PagarMeCreditCard = "PagarMeCreditCard",
    PagarMeTransaction = "PagarMeTransaction",
    PagarMeNotPaid = "PagarMeNotPaid",
    PagarMeError = "PagarMeError",
    OneCollectionPerMonth = "OneCollectionPerMonth",
    QrCodeFailure = "QrCodeFailure",
    Fatal = "Fatal",
    Connection = "Connection",
}

export function translateErrorType(enumErrorType: ErrorType): string {
    switch (enumErrorType) {
        case ErrorType.NotFound: {
            return strings ? strings["enum"]["ErrorType"]["NotFound"] || ErrorType.NotFound : ErrorType.NotFound;
        }
        case ErrorType.MissingArgument: {
            return strings ? strings["enum"]["ErrorType"]["MissingArgument"] || ErrorType.MissingArgument : ErrorType.MissingArgument;
        }
        case ErrorType.InvalidArgument: {
            return strings ? strings["enum"]["ErrorType"]["InvalidArgument"] || ErrorType.InvalidArgument : ErrorType.InvalidArgument;
        }
        case ErrorType.InvalidPermission: {
            return strings ? strings["enum"]["ErrorType"]["InvalidPermission"] || ErrorType.InvalidPermission : ErrorType.InvalidPermission;
        }
        case ErrorType.BadFormattedResponse: {
            return strings ? strings["enum"]["ErrorType"]["BadFormattedResponse"] || ErrorType.BadFormattedResponse : ErrorType.BadFormattedResponse;
        }
        case ErrorType.InternalError: {
            return strings ? strings["enum"]["ErrorType"]["InternalError"] || ErrorType.InternalError : ErrorType.InternalError;
        }
        case ErrorType.Validation: {
            return strings ? strings["enum"]["ErrorType"]["Validation"] || ErrorType.Validation : ErrorType.Validation;
        }
        case ErrorType.EmailOrPasswordWrong: {
            return strings ? strings["enum"]["ErrorType"]["EmailOrPasswordWrong"] || ErrorType.EmailOrPasswordWrong : ErrorType.EmailOrPasswordWrong;
        }
        case ErrorType.AlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["AlreadyRegistered"] || ErrorType.AlreadyRegistered : ErrorType.AlreadyRegistered;
        }
        case ErrorType.AccessNotAllowed: {
            return strings ? strings["enum"]["ErrorType"]["AccessNotAllowed"] || ErrorType.AccessNotAllowed : ErrorType.AccessNotAllowed;
        }
        case ErrorType.EmailAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["EmailAlreadyRegistered"] || ErrorType.EmailAlreadyRegistered : ErrorType.EmailAlreadyRegistered;
        }
        case ErrorType.NickAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["NickAlreadyRegistered"] || ErrorType.NickAlreadyRegistered : ErrorType.NickAlreadyRegistered;
        }
        case ErrorType.CPFAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CPFAlreadyRegistered"] || ErrorType.CPFAlreadyRegistered : ErrorType.CPFAlreadyRegistered;
        }
        case ErrorType.CNPJAlreadyRegistered: {
            return strings ? strings["enum"]["ErrorType"]["CNPJAlreadyRegistered"] || ErrorType.CNPJAlreadyRegistered : ErrorType.CNPJAlreadyRegistered;
        }
        case ErrorType.NotLoggedIn: {
            return strings ? strings["enum"]["ErrorType"]["NotLoggedIn"] || ErrorType.NotLoggedIn : ErrorType.NotLoggedIn;
        }
        case ErrorType.AdminUserIsNotALegalUser: {
            return strings ? strings["enum"]["ErrorType"]["AdminUserIsNotALegalUser"] || ErrorType.AdminUserIsNotALegalUser : ErrorType.AdminUserIsNotALegalUser;
        }
        case ErrorType.LegalUserDoesNotHaveSubscription: {
            return strings ? strings["enum"]["ErrorType"]["LegalUserDoesNotHaveSubscription"] || ErrorType.LegalUserDoesNotHaveSubscription : ErrorType.LegalUserDoesNotHaveSubscription;
        }
        case ErrorType.InvalidAccessToken: {
            return strings ? strings["enum"]["ErrorType"]["InvalidAccessToken"] || ErrorType.InvalidAccessToken : ErrorType.InvalidAccessToken;
        }
        case ErrorType.ProiverUserIsNotRegistered: {
            return strings ? strings["enum"]["ErrorType"]["ProiverUserIsNotRegistered"] || ErrorType.ProiverUserIsNotRegistered : ErrorType.ProiverUserIsNotRegistered;
        }
        case ErrorType.UserDoesNotHaveADocumentNumber: {
            return strings ? strings["enum"]["ErrorType"]["UserDoesNotHaveADocumentNumber"] || ErrorType.UserDoesNotHaveADocumentNumber : ErrorType.UserDoesNotHaveADocumentNumber;
        }
        case ErrorType.UserIsLinkedByExternalProvider: {
            return strings ? strings["enum"]["ErrorType"]["UserIsLinkedByExternalProvider"] || ErrorType.UserIsLinkedByExternalProvider : ErrorType.UserIsLinkedByExternalProvider;
        }
        case ErrorType.PagarMeBankAccount: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeBankAccount"] || ErrorType.PagarMeBankAccount : ErrorType.PagarMeBankAccount;
        }
        case ErrorType.PagarMeCreditCard: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeCreditCard"] || ErrorType.PagarMeCreditCard : ErrorType.PagarMeCreditCard;
        }
        case ErrorType.PagarMeTransaction: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeTransaction"] || ErrorType.PagarMeTransaction : ErrorType.PagarMeTransaction;
        }
        case ErrorType.PagarMeNotPaid: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeNotPaid"] || ErrorType.PagarMeNotPaid : ErrorType.PagarMeNotPaid;
        }
        case ErrorType.PagarMeError: {
            return strings ? strings["enum"]["ErrorType"]["PagarMeError"] || ErrorType.PagarMeError : ErrorType.PagarMeError;
        }
        case ErrorType.OneCollectionPerMonth: {
            return strings ? strings["enum"]["ErrorType"]["OneCollectionPerMonth"] || ErrorType.OneCollectionPerMonth : ErrorType.OneCollectionPerMonth;
        }
        case ErrorType.QrCodeFailure: {
            return strings ? strings["enum"]["ErrorType"]["QrCodeFailure"] || ErrorType.QrCodeFailure : ErrorType.QrCodeFailure;
        }
        case ErrorType.Fatal: {
            return strings ? strings["enum"]["ErrorType"]["Fatal"] || ErrorType.Fatal : ErrorType.Fatal;
        }
        case ErrorType.Connection: {
            return strings ? strings["enum"]["ErrorType"]["Connection"] || ErrorType.Connection : ErrorType.Connection;
        }
    }
    return "";
}

export function allValuesErrorType(): ErrorType[] {
    return [
        ErrorType.NotFound,
        ErrorType.MissingArgument,
        ErrorType.InvalidArgument,
        ErrorType.InvalidPermission,
        ErrorType.BadFormattedResponse,
        ErrorType.InternalError,
        ErrorType.Validation,
        ErrorType.EmailOrPasswordWrong,
        ErrorType.AlreadyRegistered,
        ErrorType.AccessNotAllowed,
        ErrorType.EmailAlreadyRegistered,
        ErrorType.NickAlreadyRegistered,
        ErrorType.CPFAlreadyRegistered,
        ErrorType.CNPJAlreadyRegistered,
        ErrorType.NotLoggedIn,
        ErrorType.AdminUserIsNotALegalUser,
        ErrorType.LegalUserDoesNotHaveSubscription,
        ErrorType.InvalidAccessToken,
        ErrorType.ProiverUserIsNotRegistered,
        ErrorType.UserDoesNotHaveADocumentNumber,
        ErrorType.UserIsLinkedByExternalProvider,
        ErrorType.PagarMeBankAccount,
        ErrorType.PagarMeCreditCard,
        ErrorType.PagarMeTransaction,
        ErrorType.PagarMeNotPaid,
        ErrorType.PagarMeError,
        ErrorType.OneCollectionPerMonth,
        ErrorType.QrCodeFailure,
        ErrorType.Fatal,
        ErrorType.Connection,
    ];
}

export function allTranslatedValuesErrorType(): string[] {
    return [
        translateErrorType(ErrorType.NotFound),
        translateErrorType(ErrorType.MissingArgument),
        translateErrorType(ErrorType.InvalidArgument),
        translateErrorType(ErrorType.InvalidPermission),
        translateErrorType(ErrorType.BadFormattedResponse),
        translateErrorType(ErrorType.InternalError),
        translateErrorType(ErrorType.Validation),
        translateErrorType(ErrorType.EmailOrPasswordWrong),
        translateErrorType(ErrorType.AlreadyRegistered),
        translateErrorType(ErrorType.AccessNotAllowed),
        translateErrorType(ErrorType.EmailAlreadyRegistered),
        translateErrorType(ErrorType.NickAlreadyRegistered),
        translateErrorType(ErrorType.CPFAlreadyRegistered),
        translateErrorType(ErrorType.CNPJAlreadyRegistered),
        translateErrorType(ErrorType.NotLoggedIn),
        translateErrorType(ErrorType.AdminUserIsNotALegalUser),
        translateErrorType(ErrorType.LegalUserDoesNotHaveSubscription),
        translateErrorType(ErrorType.InvalidAccessToken),
        translateErrorType(ErrorType.ProiverUserIsNotRegistered),
        translateErrorType(ErrorType.UserDoesNotHaveADocumentNumber),
        translateErrorType(ErrorType.UserIsLinkedByExternalProvider),
        translateErrorType(ErrorType.PagarMeBankAccount),
        translateErrorType(ErrorType.PagarMeCreditCard),
        translateErrorType(ErrorType.PagarMeTransaction),
        translateErrorType(ErrorType.PagarMeNotPaid),
        translateErrorType(ErrorType.PagarMeError),
        translateErrorType(ErrorType.OneCollectionPerMonth),
        translateErrorType(ErrorType.QrCodeFailure),
        translateErrorType(ErrorType.Fatal),
        translateErrorType(ErrorType.Connection),
    ];
}

export function allDisplayableValuesErrorType(): string[] {
    return allTranslatedValuesErrorType().sort();
}

export function valueFromTranslationErrorType(translation: string): ErrorType {
    const index = allTranslatedValuesErrorType().indexOf(translation);
    return allValuesErrorType()[index] || ErrorType.NotFound;
}

export async function uploadImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedImage(image: Buffer, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: image.toString("base64"),
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function uploadUncompressedUncertainImage(image: UncertainImage, imageFormat: ImageFormat | null, progress?: (progress: number) => void): Promise<Image> {
    const args = {
        image: {
            bytes: image.bytes === null || image.bytes === undefined ? null : image.bytes.toString("base64"),
            image: image.image === null || image.image === undefined ? null : {
                thumb: {
                    width: image.image.thumb.width || 0,
                    height: image.image.thumb.height || 0,
                    url: image.image.thumb.url,
                },
                width: image.image.width || 0,
                height: image.image.height || 0,
                url: image.image.url,
            },
        },
        imageFormat: imageFormat === null || imageFormat === undefined ? null : imageFormat,
    };
    const ret = await makeRequest({name: "uploadUncompressedUncertainImage", args, progress});
    return {
        thumb: {
            width: ret.thumb.width || 0,
            height: ret.thumb.height || 0,
            url: ret.thumb.url,
        },
        width: ret.width || 0,
        height: ret.height || 0,
        url: ret.url,
    };
}

export async function getCurrentUser(progress?: (progress: number) => void): Promise<User> {
    const ret = await makeRequest({name: "getCurrentUser", args: {}, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function userLogin(email: string, password: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "userLogin", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function createUser(newUSer: NewUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        newUSer: {
            email: newUSer.email,
            password: newUSer.password,
            name: newUSer.name,
            phone: newUSer.phone,
            image: newUSer.image === null || newUSer.image === undefined ? null : {
                bytes: newUSer.image.bytes === null || newUSer.image.bytes === undefined ? null : newUSer.image.bytes.toString("base64"),
                image: newUSer.image.image === null || newUSer.image.image === undefined ? null : {
                    thumb: {
                        width: newUSer.image.image.thumb.width || 0,
                        height: newUSer.image.image.thumb.height || 0,
                        url: newUSer.image.image.thumb.url,
                    },
                    width: newUSer.image.image.width || 0,
                    height: newUSer.image.image.height || 0,
                    url: newUSer.image.image.url,
                },
            },
            documentNumber: newUSer.documentNumber === null || newUSer.documentNumber === undefined ? null : newUSer.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function editUser(id: string, editUser: EditUser, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
        editUser: {
            email: editUser.email,
            name: editUser.name,
            phone: editUser.phone,
            documentNumber: editUser.documentNumber === null || editUser.documentNumber === undefined ? null : editUser.documentNumber,
            image: editUser.image === null || editUser.image === undefined ? null : {
                bytes: editUser.image.bytes === null || editUser.image.bytes === undefined ? null : editUser.image.bytes.toString("base64"),
                image: editUser.image.image === null || editUser.image.image === undefined ? null : {
                    thumb: {
                        width: editUser.image.image.thumb.width || 0,
                        height: editUser.image.image.thumb.height || 0,
                        url: editUser.image.image.thumb.url,
                    },
                    width: editUser.image.image.width || 0,
                    height: editUser.image.image.height || 0,
                    url: editUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function logoutUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutUser", args: {}, progress});
    return undefined;
}

export async function getLegalUsers(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getLegalUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        email: e.email,
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        legalUser: e.legalUser === null || e.legalUser === undefined ? null : {
            id: e.legalUser.id,
            fantasyName: e.legalUser.fantasyName,
            documentNumber: e.legalUser.documentNumber,
            signatureId: e.legalUser.signatureId === null || e.legalUser.signatureId === undefined ? null : e.legalUser.signatureId || 0,
            subscriptionStatus: e.legalUser.subscriptionStatus,
        },
        establishment: e.establishment === null || e.establishment === undefined ? null : {
            id: e.establishment.id,
            name: e.establishment.name,
            documentNumber: e.establishment.documentNumber,
            phone: e.establishment.phone,
            logo: e.establishment.logo === null || e.establishment.logo === undefined ? null : {
                thumb: {
                    width: e.establishment.logo.thumb.width || 0,
                    height: e.establishment.logo.thumb.height || 0,
                    url: e.establishment.logo.thumb.url,
                },
                width: e.establishment.logo.width || 0,
                height: e.establishment.logo.height || 0,
                url: e.establishment.logo.url,
            },
            image: e.establishment.image === null || e.establishment.image === undefined ? null : {
                thumb: {
                    width: e.establishment.image.thumb.width || 0,
                    height: e.establishment.image.thumb.height || 0,
                    url: e.establishment.image.thumb.url,
                },
                width: e.establishment.image.width || 0,
                height: e.establishment.image.height || 0,
                url: e.establishment.image.url,
            },
            address: {
                zipcode: e.establishment.address.zipcode,
                street: e.establishment.address.street,
                streetNumber: e.establishment.address.streetNumber,
                complementary: e.establishment.address.complementary === null || e.establishment.address.complementary === undefined ? null : e.establishment.address.complementary,
                neighborhood: e.establishment.address.neighborhood,
                city: e.establishment.address.city,
                state: e.establishment.address.state,
                countryCode: e.establishment.address.countryCode,
                latLng: {
                    lat: e.establishment.address.latLng.lat,
                    lng: e.establishment.address.latLng.lng,
                },
            },
            segment: e.establishment.segment,
            showOnApp: !!e.establishment.showOnApp,
        },
    }));
}

export async function getLegalUser(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getLegalUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function editLegalUser(userId: string, editLegalUser: EditLegalUserWithApp, progress?: (progress: number) => void): Promise<LegalUser> {
    const args = {
        userId: userId,
        editLegalUser: {
            showOnApp: !!editLegalUser.showOnApp,
            fantasyName: editLegalUser.fantasyName,
            documentNumber: editLegalUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editLegalUser", args, progress});
    return {
        id: ret.id,
        fantasyName: ret.fantasyName,
        documentNumber: ret.documentNumber,
        signatureId: ret.signatureId === null || ret.signatureId === undefined ? null : ret.signatureId || 0,
        subscriptionStatus: ret.subscriptionStatus,
    };
}

export async function editCurrentLegalUser(editLegalUser: EditLegalUser, progress?: (progress: number) => void): Promise<LegalUser> {
    const args = {
        editLegalUser: {
            fantasyName: editLegalUser.fantasyName,
            documentNumber: editLegalUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "editCurrentLegalUser", args, progress});
    return {
        id: ret.id,
        fantasyName: ret.fantasyName,
        documentNumber: ret.documentNumber,
        signatureId: ret.signatureId === null || ret.signatureId === undefined ? null : ret.signatureId || 0,
        subscriptionStatus: ret.subscriptionStatus,
    };
}

export async function deleteLegalUser(userId: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        userId: userId,
    };
    const ret = await makeRequest({name: "deleteLegalUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function getCurrentAdminLogged(progress?: (progress: number) => void): Promise<AdminUser> {
    const ret = await makeRequest({name: "getCurrentAdminLogged", args: {}, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function getCooperativeAdminUser(pageOffset: number, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getCooperativeAdminUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        email: e.email,
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        legalUser: e.legalUser === null || e.legalUser === undefined ? null : {
            id: e.legalUser.id,
            fantasyName: e.legalUser.fantasyName,
            documentNumber: e.legalUser.documentNumber,
            signatureId: e.legalUser.signatureId === null || e.legalUser.signatureId === undefined ? null : e.legalUser.signatureId || 0,
            subscriptionStatus: e.legalUser.subscriptionStatus,
        },
        establishment: e.establishment === null || e.establishment === undefined ? null : {
            id: e.establishment.id,
            name: e.establishment.name,
            documentNumber: e.establishment.documentNumber,
            phone: e.establishment.phone,
            logo: e.establishment.logo === null || e.establishment.logo === undefined ? null : {
                thumb: {
                    width: e.establishment.logo.thumb.width || 0,
                    height: e.establishment.logo.thumb.height || 0,
                    url: e.establishment.logo.thumb.url,
                },
                width: e.establishment.logo.width || 0,
                height: e.establishment.logo.height || 0,
                url: e.establishment.logo.url,
            },
            image: e.establishment.image === null || e.establishment.image === undefined ? null : {
                thumb: {
                    width: e.establishment.image.thumb.width || 0,
                    height: e.establishment.image.thumb.height || 0,
                    url: e.establishment.image.thumb.url,
                },
                width: e.establishment.image.width || 0,
                height: e.establishment.image.height || 0,
                url: e.establishment.image.url,
            },
            address: {
                zipcode: e.establishment.address.zipcode,
                street: e.establishment.address.street,
                streetNumber: e.establishment.address.streetNumber,
                complementary: e.establishment.address.complementary === null || e.establishment.address.complementary === undefined ? null : e.establishment.address.complementary,
                neighborhood: e.establishment.address.neighborhood,
                city: e.establishment.address.city,
                state: e.establishment.address.state,
                countryCode: e.establishment.address.countryCode,
                latLng: {
                    lat: e.establishment.address.latLng.lat,
                    lng: e.establishment.address.latLng.lng,
                },
            },
            segment: e.establishment.segment,
            showOnApp: !!e.establishment.showOnApp,
        },
    }));
}

export async function getCooperativeAdminUserById(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getCooperativeAdminUserById", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function adminLogin(email: string, password: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        email: email,
        password: password,
    };
    const ret = await makeRequest({name: "adminLogin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function deleteAdminCooperative(id: string, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteAdminCooperative", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function createCooperativeAdminUser(newAdminUser: NewAdminUserCooperative, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        newAdminUser: {
            email: newAdminUser.email,
            name: newAdminUser.name,
            password: newAdminUser.password,
            fantasyName: newAdminUser.fantasyName,
            documentNumber: newAdminUser.documentNumber,
        },
    };
    const ret = await makeRequest({name: "createCooperativeAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function autoCompleteLegalUser(pageOffset: number, name: string, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name,
    };
    const ret = await makeRequest({name: "autoCompleteLegalUser", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        email: e.email,
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        legalUser: e.legalUser === null || e.legalUser === undefined ? null : {
            id: e.legalUser.id,
            fantasyName: e.legalUser.fantasyName,
            documentNumber: e.legalUser.documentNumber,
            signatureId: e.legalUser.signatureId === null || e.legalUser.signatureId === undefined ? null : e.legalUser.signatureId || 0,
            subscriptionStatus: e.legalUser.subscriptionStatus,
        },
        establishment: e.establishment === null || e.establishment === undefined ? null : {
            id: e.establishment.id,
            name: e.establishment.name,
            documentNumber: e.establishment.documentNumber,
            phone: e.establishment.phone,
            logo: e.establishment.logo === null || e.establishment.logo === undefined ? null : {
                thumb: {
                    width: e.establishment.logo.thumb.width || 0,
                    height: e.establishment.logo.thumb.height || 0,
                    url: e.establishment.logo.thumb.url,
                },
                width: e.establishment.logo.width || 0,
                height: e.establishment.logo.height || 0,
                url: e.establishment.logo.url,
            },
            image: e.establishment.image === null || e.establishment.image === undefined ? null : {
                thumb: {
                    width: e.establishment.image.thumb.width || 0,
                    height: e.establishment.image.thumb.height || 0,
                    url: e.establishment.image.thumb.url,
                },
                width: e.establishment.image.width || 0,
                height: e.establishment.image.height || 0,
                url: e.establishment.image.url,
            },
            address: {
                zipcode: e.establishment.address.zipcode,
                street: e.establishment.address.street,
                streetNumber: e.establishment.address.streetNumber,
                complementary: e.establishment.address.complementary === null || e.establishment.address.complementary === undefined ? null : e.establishment.address.complementary,
                neighborhood: e.establishment.address.neighborhood,
                city: e.establishment.address.city,
                state: e.establishment.address.state,
                countryCode: e.establishment.address.countryCode,
                latLng: {
                    lat: e.establishment.address.latLng.lat,
                    lng: e.establishment.address.latLng.lng,
                },
            },
            segment: e.establishment.segment,
            showOnApp: !!e.establishment.showOnApp,
        },
    }));
}

export async function autoCompleteCooperative(pageOffset: number, name: string, progress?: (progress: number) => void): Promise<AdminUser[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name,
    };
    const ret = await makeRequest({name: "autoCompleteCooperative", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        email: e.email,
        type: e.type,
        createdAt: new Date(e.createdAt + "Z"),
        legalUser: e.legalUser === null || e.legalUser === undefined ? null : {
            id: e.legalUser.id,
            fantasyName: e.legalUser.fantasyName,
            documentNumber: e.legalUser.documentNumber,
            signatureId: e.legalUser.signatureId === null || e.legalUser.signatureId === undefined ? null : e.legalUser.signatureId || 0,
            subscriptionStatus: e.legalUser.subscriptionStatus,
        },
        establishment: e.establishment === null || e.establishment === undefined ? null : {
            id: e.establishment.id,
            name: e.establishment.name,
            documentNumber: e.establishment.documentNumber,
            phone: e.establishment.phone,
            logo: e.establishment.logo === null || e.establishment.logo === undefined ? null : {
                thumb: {
                    width: e.establishment.logo.thumb.width || 0,
                    height: e.establishment.logo.thumb.height || 0,
                    url: e.establishment.logo.thumb.url,
                },
                width: e.establishment.logo.width || 0,
                height: e.establishment.logo.height || 0,
                url: e.establishment.logo.url,
            },
            image: e.establishment.image === null || e.establishment.image === undefined ? null : {
                thumb: {
                    width: e.establishment.image.thumb.width || 0,
                    height: e.establishment.image.thumb.height || 0,
                    url: e.establishment.image.thumb.url,
                },
                width: e.establishment.image.width || 0,
                height: e.establishment.image.height || 0,
                url: e.establishment.image.url,
            },
            address: {
                zipcode: e.establishment.address.zipcode,
                street: e.establishment.address.street,
                streetNumber: e.establishment.address.streetNumber,
                complementary: e.establishment.address.complementary === null || e.establishment.address.complementary === undefined ? null : e.establishment.address.complementary,
                neighborhood: e.establishment.address.neighborhood,
                city: e.establishment.address.city,
                state: e.establishment.address.state,
                countryCode: e.establishment.address.countryCode,
                latLng: {
                    lat: e.establishment.address.latLng.lat,
                    lng: e.establishment.address.latLng.lng,
                },
            },
            segment: e.establishment.segment,
            showOnApp: !!e.establishment.showOnApp,
        },
    }));
}

export async function editCurrentAdminUser(editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            image: editAdminUser.image === null || editAdminUser.image === undefined ? null : {
                bytes: editAdminUser.image.bytes === null || editAdminUser.image.bytes === undefined ? null : editAdminUser.image.bytes.toString("base64"),
                image: editAdminUser.image.image === null || editAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editAdminUser.image.image.thumb.width || 0,
                        height: editAdminUser.image.image.thumb.height || 0,
                        url: editAdminUser.image.image.thumb.url,
                    },
                    width: editAdminUser.image.image.width || 0,
                    height: editAdminUser.image.image.height || 0,
                    url: editAdminUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editCurrentAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function editAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            image: editAdminUser.image === null || editAdminUser.image === undefined ? null : {
                bytes: editAdminUser.image.bytes === null || editAdminUser.image.bytes === undefined ? null : editAdminUser.image.bytes.toString("base64"),
                image: editAdminUser.image.image === null || editAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editAdminUser.image.image.thumb.width || 0,
                        height: editAdminUser.image.image.thumb.height || 0,
                        url: editAdminUser.image.image.thumb.url,
                    },
                    width: editAdminUser.image.image.width || 0,
                    height: editAdminUser.image.image.height || 0,
                    url: editAdminUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function editCooperativeAdminUser(id: string, editAdminUser: EditAdminUser, progress?: (progress: number) => void): Promise<AdminUser> {
    const args = {
        id: id,
        editAdminUser: {
            name: editAdminUser.name,
            email: editAdminUser.email,
            image: editAdminUser.image === null || editAdminUser.image === undefined ? null : {
                bytes: editAdminUser.image.bytes === null || editAdminUser.image.bytes === undefined ? null : editAdminUser.image.bytes.toString("base64"),
                image: editAdminUser.image.image === null || editAdminUser.image.image === undefined ? null : {
                    thumb: {
                        width: editAdminUser.image.image.thumb.width || 0,
                        height: editAdminUser.image.image.thumb.height || 0,
                        url: editAdminUser.image.image.thumb.url,
                    },
                    width: editAdminUser.image.image.width || 0,
                    height: editAdminUser.image.image.height || 0,
                    url: editAdminUser.image.image.url,
                },
            },
        },
    };
    const ret = await makeRequest({name: "editCooperativeAdminUser", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        email: ret.email,
        type: ret.type,
        createdAt: new Date(ret.createdAt + "Z"),
        legalUser: ret.legalUser === null || ret.legalUser === undefined ? null : {
            id: ret.legalUser.id,
            fantasyName: ret.legalUser.fantasyName,
            documentNumber: ret.legalUser.documentNumber,
            signatureId: ret.legalUser.signatureId === null || ret.legalUser.signatureId === undefined ? null : ret.legalUser.signatureId || 0,
            subscriptionStatus: ret.legalUser.subscriptionStatus,
        },
        establishment: ret.establishment === null || ret.establishment === undefined ? null : {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
    };
}

export async function logoutAdminUser(progress?: (progress: number) => void): Promise<void> {
    await makeRequest({name: "logoutAdminUser", args: {}, progress});
    return undefined;
}

export async function getCheckIn(id: string, progress?: (progress: number) => void): Promise<CheckIn> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getCheckIn", args, progress});
    return {
        id: ret.id,
        invoice: ret.invoice,
        ticket: ret.ticket || 0,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            phone: ret.user.phone,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        isOnDraw: !!ret.isOnDraw,
        establishment: {
            id: ret.establishment.id,
            name: ret.establishment.name,
            documentNumber: ret.establishment.documentNumber,
            phone: ret.establishment.phone,
            logo: ret.establishment.logo === null || ret.establishment.logo === undefined ? null : {
                thumb: {
                    width: ret.establishment.logo.thumb.width || 0,
                    height: ret.establishment.logo.thumb.height || 0,
                    url: ret.establishment.logo.thumb.url,
                },
                width: ret.establishment.logo.width || 0,
                height: ret.establishment.logo.height || 0,
                url: ret.establishment.logo.url,
            },
            image: ret.establishment.image === null || ret.establishment.image === undefined ? null : {
                thumb: {
                    width: ret.establishment.image.thumb.width || 0,
                    height: ret.establishment.image.thumb.height || 0,
                    url: ret.establishment.image.thumb.url,
                },
                width: ret.establishment.image.width || 0,
                height: ret.establishment.image.height || 0,
                url: ret.establishment.image.url,
            },
            address: {
                zipcode: ret.establishment.address.zipcode,
                street: ret.establishment.address.street,
                streetNumber: ret.establishment.address.streetNumber,
                complementary: ret.establishment.address.complementary === null || ret.establishment.address.complementary === undefined ? null : ret.establishment.address.complementary,
                neighborhood: ret.establishment.address.neighborhood,
                city: ret.establishment.address.city,
                state: ret.establishment.address.state,
                countryCode: ret.establishment.address.countryCode,
                latLng: {
                    lat: ret.establishment.address.latLng.lat,
                    lng: ret.establishment.address.latLng.lng,
                },
            },
            segment: ret.establishment.segment,
            showOnApp: !!ret.establishment.showOnApp,
        },
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
    };
}

export async function getExportCSVCheckin(filterOptions: FilterByPeriod, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : typeof(filterOptions.startDate) === "string" ? new Date(new Date(filterOptions.startDate).getTime() - new Date(filterOptions.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.startDate.getTime() - filterOptions.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : typeof(filterOptions.endDate) === "string" ? new Date(new Date(filterOptions.endDate).getTime() - new Date(filterOptions.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.endDate.getTime() - filterOptions.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "getExportCSVCheckin", args, progress});
    return ret;
}

export async function switchParticipationDraw(checkinId: string, isOnDraw: boolean, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        checkinId: checkinId,
        isOnDraw: !!isOnDraw,
    };
    const ret = await makeRequest({name: "switchParticipationDraw", args, progress});
    return !!ret;
}

export async function getAllCheckIns(pageOffset: number, filterOptions: FilterByPeriod, progress?: (progress: number) => void): Promise<CheckIn[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : typeof(filterOptions.startDate) === "string" ? new Date(new Date(filterOptions.startDate).getTime() - new Date(filterOptions.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.startDate.getTime() - filterOptions.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : typeof(filterOptions.endDate) === "string" ? new Date(new Date(filterOptions.endDate).getTime() - new Date(filterOptions.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.endDate.getTime() - filterOptions.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "getAllCheckIns", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        invoice: e.invoice,
        ticket: e.ticket || 0,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        isOnDraw: !!e.isOnDraw,
        establishment: {
            id: e.establishment.id,
            name: e.establishment.name,
            documentNumber: e.establishment.documentNumber,
            phone: e.establishment.phone,
            logo: e.establishment.logo === null || e.establishment.logo === undefined ? null : {
                thumb: {
                    width: e.establishment.logo.thumb.width || 0,
                    height: e.establishment.logo.thumb.height || 0,
                    url: e.establishment.logo.thumb.url,
                },
                width: e.establishment.logo.width || 0,
                height: e.establishment.logo.height || 0,
                url: e.establishment.logo.url,
            },
            image: e.establishment.image === null || e.establishment.image === undefined ? null : {
                thumb: {
                    width: e.establishment.image.thumb.width || 0,
                    height: e.establishment.image.thumb.height || 0,
                    url: e.establishment.image.thumb.url,
                },
                width: e.establishment.image.width || 0,
                height: e.establishment.image.height || 0,
                url: e.establishment.image.url,
            },
            address: {
                zipcode: e.establishment.address.zipcode,
                street: e.establishment.address.street,
                streetNumber: e.establishment.address.streetNumber,
                complementary: e.establishment.address.complementary === null || e.establishment.address.complementary === undefined ? null : e.establishment.address.complementary,
                neighborhood: e.establishment.address.neighborhood,
                city: e.establishment.address.city,
                state: e.establishment.address.state,
                countryCode: e.establishment.address.countryCode,
                latLng: {
                    lat: e.establishment.address.latLng.lat,
                    lng: e.establishment.address.latLng.lng,
                },
            },
            segment: e.establishment.segment,
            showOnApp: !!e.establishment.showOnApp,
        },
        createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
    }));
}

export async function createNewCollectOrder(newCollectOrder: NewCollectOrder, progress?: (progress: number) => void): Promise<Collect> {
    const args = {
        newCollectOrder: {
            collectDate: newCollectOrder.collectDate === null || newCollectOrder.collectDate === undefined ? null : typeof(newCollectOrder.collectDate) === "string" ? new Date(new Date(newCollectOrder.collectDate).getTime() - new Date(newCollectOrder.collectDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newCollectOrder.collectDate.getTime() - newCollectOrder.collectDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            turn: newCollectOrder.turn,
            description: newCollectOrder.description === null || newCollectOrder.description === undefined ? null : newCollectOrder.description,
        },
    };
    const ret = await makeRequest({name: "createNewCollectOrder", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            image: ret.adminUser.image === null || ret.adminUser.image === undefined ? null : {
                thumb: {
                    width: ret.adminUser.image.thumb.width || 0,
                    height: ret.adminUser.image.thumb.height || 0,
                    url: ret.adminUser.image.thumb.url,
                },
                width: ret.adminUser.image.width || 0,
                height: ret.adminUser.image.height || 0,
                url: ret.adminUser.image.url,
            },
            email: ret.adminUser.email,
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            legalUser: ret.adminUser.legalUser === null || ret.adminUser.legalUser === undefined ? null : {
                id: ret.adminUser.legalUser.id,
                fantasyName: ret.adminUser.legalUser.fantasyName,
                documentNumber: ret.adminUser.legalUser.documentNumber,
                signatureId: ret.adminUser.legalUser.signatureId === null || ret.adminUser.legalUser.signatureId === undefined ? null : ret.adminUser.legalUser.signatureId || 0,
                subscriptionStatus: ret.adminUser.legalUser.subscriptionStatus,
            },
            establishment: ret.adminUser.establishment === null || ret.adminUser.establishment === undefined ? null : {
                id: ret.adminUser.establishment.id,
                name: ret.adminUser.establishment.name,
                documentNumber: ret.adminUser.establishment.documentNumber,
                phone: ret.adminUser.establishment.phone,
                logo: ret.adminUser.establishment.logo === null || ret.adminUser.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.logo.thumb.width || 0,
                        height: ret.adminUser.establishment.logo.thumb.height || 0,
                        url: ret.adminUser.establishment.logo.thumb.url,
                    },
                    width: ret.adminUser.establishment.logo.width || 0,
                    height: ret.adminUser.establishment.logo.height || 0,
                    url: ret.adminUser.establishment.logo.url,
                },
                image: ret.adminUser.establishment.image === null || ret.adminUser.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.image.thumb.width || 0,
                        height: ret.adminUser.establishment.image.thumb.height || 0,
                        url: ret.adminUser.establishment.image.thumb.url,
                    },
                    width: ret.adminUser.establishment.image.width || 0,
                    height: ret.adminUser.establishment.image.height || 0,
                    url: ret.adminUser.establishment.image.url,
                },
                address: {
                    zipcode: ret.adminUser.establishment.address.zipcode,
                    street: ret.adminUser.establishment.address.street,
                    streetNumber: ret.adminUser.establishment.address.streetNumber,
                    complementary: ret.adminUser.establishment.address.complementary === null || ret.adminUser.establishment.address.complementary === undefined ? null : ret.adminUser.establishment.address.complementary,
                    neighborhood: ret.adminUser.establishment.address.neighborhood,
                    city: ret.adminUser.establishment.address.city,
                    state: ret.adminUser.establishment.address.state,
                    countryCode: ret.adminUser.establishment.address.countryCode,
                    latLng: {
                        lat: ret.adminUser.establishment.address.latLng.lat,
                        lng: ret.adminUser.establishment.address.latLng.lng,
                    },
                },
                segment: ret.adminUser.establishment.segment,
                showOnApp: !!ret.adminUser.establishment.showOnApp,
            },
        },
        collectDate: ret.collectDate === null || ret.collectDate === undefined ? null : new Date(parseInt(ret.collectDate.split("-")[0], 10), parseInt(ret.collectDate.split("-")[1], 10) - 1, parseInt(ret.collectDate.split("-")[2], 10)),
        turn: ret.turn,
        status: ret.status,
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        recoveredCO2: ret.recoveredCO2 || 0,
        cooperative: ret.cooperative === null || ret.cooperative === undefined ? null : {
            id: ret.cooperative.id,
            name: ret.cooperative.name,
            image: ret.cooperative.image === null || ret.cooperative.image === undefined ? null : {
                thumb: {
                    width: ret.cooperative.image.thumb.width || 0,
                    height: ret.cooperative.image.thumb.height || 0,
                    url: ret.cooperative.image.thumb.url,
                },
                width: ret.cooperative.image.width || 0,
                height: ret.cooperative.image.height || 0,
                url: ret.cooperative.image.url,
            },
            email: ret.cooperative.email,
            type: ret.cooperative.type,
            createdAt: new Date(ret.cooperative.createdAt + "Z"),
            legalUser: ret.cooperative.legalUser === null || ret.cooperative.legalUser === undefined ? null : {
                id: ret.cooperative.legalUser.id,
                fantasyName: ret.cooperative.legalUser.fantasyName,
                documentNumber: ret.cooperative.legalUser.documentNumber,
                signatureId: ret.cooperative.legalUser.signatureId === null || ret.cooperative.legalUser.signatureId === undefined ? null : ret.cooperative.legalUser.signatureId || 0,
                subscriptionStatus: ret.cooperative.legalUser.subscriptionStatus,
            },
            establishment: ret.cooperative.establishment === null || ret.cooperative.establishment === undefined ? null : {
                id: ret.cooperative.establishment.id,
                name: ret.cooperative.establishment.name,
                documentNumber: ret.cooperative.establishment.documentNumber,
                phone: ret.cooperative.establishment.phone,
                logo: ret.cooperative.establishment.logo === null || ret.cooperative.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.logo.thumb.width || 0,
                        height: ret.cooperative.establishment.logo.thumb.height || 0,
                        url: ret.cooperative.establishment.logo.thumb.url,
                    },
                    width: ret.cooperative.establishment.logo.width || 0,
                    height: ret.cooperative.establishment.logo.height || 0,
                    url: ret.cooperative.establishment.logo.url,
                },
                image: ret.cooperative.establishment.image === null || ret.cooperative.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.image.thumb.width || 0,
                        height: ret.cooperative.establishment.image.thumb.height || 0,
                        url: ret.cooperative.establishment.image.thumb.url,
                    },
                    width: ret.cooperative.establishment.image.width || 0,
                    height: ret.cooperative.establishment.image.height || 0,
                    url: ret.cooperative.establishment.image.url,
                },
                address: {
                    zipcode: ret.cooperative.establishment.address.zipcode,
                    street: ret.cooperative.establishment.address.street,
                    streetNumber: ret.cooperative.establishment.address.streetNumber,
                    complementary: ret.cooperative.establishment.address.complementary === null || ret.cooperative.establishment.address.complementary === undefined ? null : ret.cooperative.establishment.address.complementary,
                    neighborhood: ret.cooperative.establishment.address.neighborhood,
                    city: ret.cooperative.establishment.address.city,
                    state: ret.cooperative.establishment.address.state,
                    countryCode: ret.cooperative.establishment.address.countryCode,
                    latLng: {
                        lat: ret.cooperative.establishment.address.latLng.lat,
                        lng: ret.cooperative.establishment.address.latLng.lng,
                    },
                },
                segment: ret.cooperative.establishment.segment,
                showOnApp: !!ret.cooperative.establishment.showOnApp,
            },
        },
    };
}

export async function editCollectOrder(id: string, editCollectOrder: EditCollectOrder, progress?: (progress: number) => void): Promise<Collect> {
    const args = {
        id: id,
        editCollectOrder: {
            collectDate: editCollectOrder.collectDate === null || editCollectOrder.collectDate === undefined ? null : typeof(editCollectOrder.collectDate) === "string" ? new Date(new Date(editCollectOrder.collectDate).getTime() - new Date(editCollectOrder.collectDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(editCollectOrder.collectDate.getTime() - editCollectOrder.collectDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            turn: editCollectOrder.turn,
            status: editCollectOrder.status,
            description: editCollectOrder.description === null || editCollectOrder.description === undefined ? null : editCollectOrder.description,
        },
    };
    const ret = await makeRequest({name: "editCollectOrder", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            image: ret.adminUser.image === null || ret.adminUser.image === undefined ? null : {
                thumb: {
                    width: ret.adminUser.image.thumb.width || 0,
                    height: ret.adminUser.image.thumb.height || 0,
                    url: ret.adminUser.image.thumb.url,
                },
                width: ret.adminUser.image.width || 0,
                height: ret.adminUser.image.height || 0,
                url: ret.adminUser.image.url,
            },
            email: ret.adminUser.email,
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            legalUser: ret.adminUser.legalUser === null || ret.adminUser.legalUser === undefined ? null : {
                id: ret.adminUser.legalUser.id,
                fantasyName: ret.adminUser.legalUser.fantasyName,
                documentNumber: ret.adminUser.legalUser.documentNumber,
                signatureId: ret.adminUser.legalUser.signatureId === null || ret.adminUser.legalUser.signatureId === undefined ? null : ret.adminUser.legalUser.signatureId || 0,
                subscriptionStatus: ret.adminUser.legalUser.subscriptionStatus,
            },
            establishment: ret.adminUser.establishment === null || ret.adminUser.establishment === undefined ? null : {
                id: ret.adminUser.establishment.id,
                name: ret.adminUser.establishment.name,
                documentNumber: ret.adminUser.establishment.documentNumber,
                phone: ret.adminUser.establishment.phone,
                logo: ret.adminUser.establishment.logo === null || ret.adminUser.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.logo.thumb.width || 0,
                        height: ret.adminUser.establishment.logo.thumb.height || 0,
                        url: ret.adminUser.establishment.logo.thumb.url,
                    },
                    width: ret.adminUser.establishment.logo.width || 0,
                    height: ret.adminUser.establishment.logo.height || 0,
                    url: ret.adminUser.establishment.logo.url,
                },
                image: ret.adminUser.establishment.image === null || ret.adminUser.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.image.thumb.width || 0,
                        height: ret.adminUser.establishment.image.thumb.height || 0,
                        url: ret.adminUser.establishment.image.thumb.url,
                    },
                    width: ret.adminUser.establishment.image.width || 0,
                    height: ret.adminUser.establishment.image.height || 0,
                    url: ret.adminUser.establishment.image.url,
                },
                address: {
                    zipcode: ret.adminUser.establishment.address.zipcode,
                    street: ret.adminUser.establishment.address.street,
                    streetNumber: ret.adminUser.establishment.address.streetNumber,
                    complementary: ret.adminUser.establishment.address.complementary === null || ret.adminUser.establishment.address.complementary === undefined ? null : ret.adminUser.establishment.address.complementary,
                    neighborhood: ret.adminUser.establishment.address.neighborhood,
                    city: ret.adminUser.establishment.address.city,
                    state: ret.adminUser.establishment.address.state,
                    countryCode: ret.adminUser.establishment.address.countryCode,
                    latLng: {
                        lat: ret.adminUser.establishment.address.latLng.lat,
                        lng: ret.adminUser.establishment.address.latLng.lng,
                    },
                },
                segment: ret.adminUser.establishment.segment,
                showOnApp: !!ret.adminUser.establishment.showOnApp,
            },
        },
        collectDate: ret.collectDate === null || ret.collectDate === undefined ? null : new Date(parseInt(ret.collectDate.split("-")[0], 10), parseInt(ret.collectDate.split("-")[1], 10) - 1, parseInt(ret.collectDate.split("-")[2], 10)),
        turn: ret.turn,
        status: ret.status,
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        recoveredCO2: ret.recoveredCO2 || 0,
        cooperative: ret.cooperative === null || ret.cooperative === undefined ? null : {
            id: ret.cooperative.id,
            name: ret.cooperative.name,
            image: ret.cooperative.image === null || ret.cooperative.image === undefined ? null : {
                thumb: {
                    width: ret.cooperative.image.thumb.width || 0,
                    height: ret.cooperative.image.thumb.height || 0,
                    url: ret.cooperative.image.thumb.url,
                },
                width: ret.cooperative.image.width || 0,
                height: ret.cooperative.image.height || 0,
                url: ret.cooperative.image.url,
            },
            email: ret.cooperative.email,
            type: ret.cooperative.type,
            createdAt: new Date(ret.cooperative.createdAt + "Z"),
            legalUser: ret.cooperative.legalUser === null || ret.cooperative.legalUser === undefined ? null : {
                id: ret.cooperative.legalUser.id,
                fantasyName: ret.cooperative.legalUser.fantasyName,
                documentNumber: ret.cooperative.legalUser.documentNumber,
                signatureId: ret.cooperative.legalUser.signatureId === null || ret.cooperative.legalUser.signatureId === undefined ? null : ret.cooperative.legalUser.signatureId || 0,
                subscriptionStatus: ret.cooperative.legalUser.subscriptionStatus,
            },
            establishment: ret.cooperative.establishment === null || ret.cooperative.establishment === undefined ? null : {
                id: ret.cooperative.establishment.id,
                name: ret.cooperative.establishment.name,
                documentNumber: ret.cooperative.establishment.documentNumber,
                phone: ret.cooperative.establishment.phone,
                logo: ret.cooperative.establishment.logo === null || ret.cooperative.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.logo.thumb.width || 0,
                        height: ret.cooperative.establishment.logo.thumb.height || 0,
                        url: ret.cooperative.establishment.logo.thumb.url,
                    },
                    width: ret.cooperative.establishment.logo.width || 0,
                    height: ret.cooperative.establishment.logo.height || 0,
                    url: ret.cooperative.establishment.logo.url,
                },
                image: ret.cooperative.establishment.image === null || ret.cooperative.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.image.thumb.width || 0,
                        height: ret.cooperative.establishment.image.thumb.height || 0,
                        url: ret.cooperative.establishment.image.thumb.url,
                    },
                    width: ret.cooperative.establishment.image.width || 0,
                    height: ret.cooperative.establishment.image.height || 0,
                    url: ret.cooperative.establishment.image.url,
                },
                address: {
                    zipcode: ret.cooperative.establishment.address.zipcode,
                    street: ret.cooperative.establishment.address.street,
                    streetNumber: ret.cooperative.establishment.address.streetNumber,
                    complementary: ret.cooperative.establishment.address.complementary === null || ret.cooperative.establishment.address.complementary === undefined ? null : ret.cooperative.establishment.address.complementary,
                    neighborhood: ret.cooperative.establishment.address.neighborhood,
                    city: ret.cooperative.establishment.address.city,
                    state: ret.cooperative.establishment.address.state,
                    countryCode: ret.cooperative.establishment.address.countryCode,
                    latLng: {
                        lat: ret.cooperative.establishment.address.latLng.lat,
                        lng: ret.cooperative.establishment.address.latLng.lng,
                    },
                },
                segment: ret.cooperative.establishment.segment,
                showOnApp: !!ret.cooperative.establishment.showOnApp,
            },
        },
    };
}

export async function createCollectForAdminUser(newCollectOrder: NewCollectOrderWithStatus, establishmentId: string, progress?: (progress: number) => void): Promise<Collect> {
    const args = {
        newCollectOrder: {
            collectDate: newCollectOrder.collectDate === null || newCollectOrder.collectDate === undefined ? null : typeof(newCollectOrder.collectDate) === "string" ? new Date(new Date(newCollectOrder.collectDate).getTime() - new Date(newCollectOrder.collectDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newCollectOrder.collectDate.getTime() - newCollectOrder.collectDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            turn: newCollectOrder.turn,
            status: newCollectOrder.status,
            description: newCollectOrder.description === null || newCollectOrder.description === undefined ? null : newCollectOrder.description,
            recoveredCO2: newCollectOrder.recoveredCO2 || 0,
        },
        establishmentId: establishmentId,
    };
    const ret = await makeRequest({name: "createCollectForAdminUser", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            image: ret.adminUser.image === null || ret.adminUser.image === undefined ? null : {
                thumb: {
                    width: ret.adminUser.image.thumb.width || 0,
                    height: ret.adminUser.image.thumb.height || 0,
                    url: ret.adminUser.image.thumb.url,
                },
                width: ret.adminUser.image.width || 0,
                height: ret.adminUser.image.height || 0,
                url: ret.adminUser.image.url,
            },
            email: ret.adminUser.email,
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            legalUser: ret.adminUser.legalUser === null || ret.adminUser.legalUser === undefined ? null : {
                id: ret.adminUser.legalUser.id,
                fantasyName: ret.adminUser.legalUser.fantasyName,
                documentNumber: ret.adminUser.legalUser.documentNumber,
                signatureId: ret.adminUser.legalUser.signatureId === null || ret.adminUser.legalUser.signatureId === undefined ? null : ret.adminUser.legalUser.signatureId || 0,
                subscriptionStatus: ret.adminUser.legalUser.subscriptionStatus,
            },
            establishment: ret.adminUser.establishment === null || ret.adminUser.establishment === undefined ? null : {
                id: ret.adminUser.establishment.id,
                name: ret.adminUser.establishment.name,
                documentNumber: ret.adminUser.establishment.documentNumber,
                phone: ret.adminUser.establishment.phone,
                logo: ret.adminUser.establishment.logo === null || ret.adminUser.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.logo.thumb.width || 0,
                        height: ret.adminUser.establishment.logo.thumb.height || 0,
                        url: ret.adminUser.establishment.logo.thumb.url,
                    },
                    width: ret.adminUser.establishment.logo.width || 0,
                    height: ret.adminUser.establishment.logo.height || 0,
                    url: ret.adminUser.establishment.logo.url,
                },
                image: ret.adminUser.establishment.image === null || ret.adminUser.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.image.thumb.width || 0,
                        height: ret.adminUser.establishment.image.thumb.height || 0,
                        url: ret.adminUser.establishment.image.thumb.url,
                    },
                    width: ret.adminUser.establishment.image.width || 0,
                    height: ret.adminUser.establishment.image.height || 0,
                    url: ret.adminUser.establishment.image.url,
                },
                address: {
                    zipcode: ret.adminUser.establishment.address.zipcode,
                    street: ret.adminUser.establishment.address.street,
                    streetNumber: ret.adminUser.establishment.address.streetNumber,
                    complementary: ret.adminUser.establishment.address.complementary === null || ret.adminUser.establishment.address.complementary === undefined ? null : ret.adminUser.establishment.address.complementary,
                    neighborhood: ret.adminUser.establishment.address.neighborhood,
                    city: ret.adminUser.establishment.address.city,
                    state: ret.adminUser.establishment.address.state,
                    countryCode: ret.adminUser.establishment.address.countryCode,
                    latLng: {
                        lat: ret.adminUser.establishment.address.latLng.lat,
                        lng: ret.adminUser.establishment.address.latLng.lng,
                    },
                },
                segment: ret.adminUser.establishment.segment,
                showOnApp: !!ret.adminUser.establishment.showOnApp,
            },
        },
        collectDate: ret.collectDate === null || ret.collectDate === undefined ? null : new Date(parseInt(ret.collectDate.split("-")[0], 10), parseInt(ret.collectDate.split("-")[1], 10) - 1, parseInt(ret.collectDate.split("-")[2], 10)),
        turn: ret.turn,
        status: ret.status,
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        recoveredCO2: ret.recoveredCO2 || 0,
        cooperative: ret.cooperative === null || ret.cooperative === undefined ? null : {
            id: ret.cooperative.id,
            name: ret.cooperative.name,
            image: ret.cooperative.image === null || ret.cooperative.image === undefined ? null : {
                thumb: {
                    width: ret.cooperative.image.thumb.width || 0,
                    height: ret.cooperative.image.thumb.height || 0,
                    url: ret.cooperative.image.thumb.url,
                },
                width: ret.cooperative.image.width || 0,
                height: ret.cooperative.image.height || 0,
                url: ret.cooperative.image.url,
            },
            email: ret.cooperative.email,
            type: ret.cooperative.type,
            createdAt: new Date(ret.cooperative.createdAt + "Z"),
            legalUser: ret.cooperative.legalUser === null || ret.cooperative.legalUser === undefined ? null : {
                id: ret.cooperative.legalUser.id,
                fantasyName: ret.cooperative.legalUser.fantasyName,
                documentNumber: ret.cooperative.legalUser.documentNumber,
                signatureId: ret.cooperative.legalUser.signatureId === null || ret.cooperative.legalUser.signatureId === undefined ? null : ret.cooperative.legalUser.signatureId || 0,
                subscriptionStatus: ret.cooperative.legalUser.subscriptionStatus,
            },
            establishment: ret.cooperative.establishment === null || ret.cooperative.establishment === undefined ? null : {
                id: ret.cooperative.establishment.id,
                name: ret.cooperative.establishment.name,
                documentNumber: ret.cooperative.establishment.documentNumber,
                phone: ret.cooperative.establishment.phone,
                logo: ret.cooperative.establishment.logo === null || ret.cooperative.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.logo.thumb.width || 0,
                        height: ret.cooperative.establishment.logo.thumb.height || 0,
                        url: ret.cooperative.establishment.logo.thumb.url,
                    },
                    width: ret.cooperative.establishment.logo.width || 0,
                    height: ret.cooperative.establishment.logo.height || 0,
                    url: ret.cooperative.establishment.logo.url,
                },
                image: ret.cooperative.establishment.image === null || ret.cooperative.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.image.thumb.width || 0,
                        height: ret.cooperative.establishment.image.thumb.height || 0,
                        url: ret.cooperative.establishment.image.thumb.url,
                    },
                    width: ret.cooperative.establishment.image.width || 0,
                    height: ret.cooperative.establishment.image.height || 0,
                    url: ret.cooperative.establishment.image.url,
                },
                address: {
                    zipcode: ret.cooperative.establishment.address.zipcode,
                    street: ret.cooperative.establishment.address.street,
                    streetNumber: ret.cooperative.establishment.address.streetNumber,
                    complementary: ret.cooperative.establishment.address.complementary === null || ret.cooperative.establishment.address.complementary === undefined ? null : ret.cooperative.establishment.address.complementary,
                    neighborhood: ret.cooperative.establishment.address.neighborhood,
                    city: ret.cooperative.establishment.address.city,
                    state: ret.cooperative.establishment.address.state,
                    countryCode: ret.cooperative.establishment.address.countryCode,
                    latLng: {
                        lat: ret.cooperative.establishment.address.latLng.lat,
                        lng: ret.cooperative.establishment.address.latLng.lng,
                    },
                },
                segment: ret.cooperative.establishment.segment,
                showOnApp: !!ret.cooperative.establishment.showOnApp,
            },
        },
    };
}

export async function getAllCollects(pageOffset: number, filterOptions: FilterByPeriod | null, progress?: (progress: number) => void): Promise<Collect[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filterOptions: filterOptions === null || filterOptions === undefined ? null : {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : typeof(filterOptions.startDate) === "string" ? new Date(new Date(filterOptions.startDate).getTime() - new Date(filterOptions.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.startDate.getTime() - filterOptions.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : typeof(filterOptions.endDate) === "string" ? new Date(new Date(filterOptions.endDate).getTime() - new Date(filterOptions.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.endDate.getTime() - filterOptions.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "getAllCollects", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        code: e.code,
        adminUser: {
            id: e.adminUser.id,
            name: e.adminUser.name,
            image: e.adminUser.image === null || e.adminUser.image === undefined ? null : {
                thumb: {
                    width: e.adminUser.image.thumb.width || 0,
                    height: e.adminUser.image.thumb.height || 0,
                    url: e.adminUser.image.thumb.url,
                },
                width: e.adminUser.image.width || 0,
                height: e.adminUser.image.height || 0,
                url: e.adminUser.image.url,
            },
            email: e.adminUser.email,
            type: e.adminUser.type,
            createdAt: new Date(e.adminUser.createdAt + "Z"),
            legalUser: e.adminUser.legalUser === null || e.adminUser.legalUser === undefined ? null : {
                id: e.adminUser.legalUser.id,
                fantasyName: e.adminUser.legalUser.fantasyName,
                documentNumber: e.adminUser.legalUser.documentNumber,
                signatureId: e.adminUser.legalUser.signatureId === null || e.adminUser.legalUser.signatureId === undefined ? null : e.adminUser.legalUser.signatureId || 0,
                subscriptionStatus: e.adminUser.legalUser.subscriptionStatus,
            },
            establishment: e.adminUser.establishment === null || e.adminUser.establishment === undefined ? null : {
                id: e.adminUser.establishment.id,
                name: e.adminUser.establishment.name,
                documentNumber: e.adminUser.establishment.documentNumber,
                phone: e.adminUser.establishment.phone,
                logo: e.adminUser.establishment.logo === null || e.adminUser.establishment.logo === undefined ? null : {
                    thumb: {
                        width: e.adminUser.establishment.logo.thumb.width || 0,
                        height: e.adminUser.establishment.logo.thumb.height || 0,
                        url: e.adminUser.establishment.logo.thumb.url,
                    },
                    width: e.adminUser.establishment.logo.width || 0,
                    height: e.adminUser.establishment.logo.height || 0,
                    url: e.adminUser.establishment.logo.url,
                },
                image: e.adminUser.establishment.image === null || e.adminUser.establishment.image === undefined ? null : {
                    thumb: {
                        width: e.adminUser.establishment.image.thumb.width || 0,
                        height: e.adminUser.establishment.image.thumb.height || 0,
                        url: e.adminUser.establishment.image.thumb.url,
                    },
                    width: e.adminUser.establishment.image.width || 0,
                    height: e.adminUser.establishment.image.height || 0,
                    url: e.adminUser.establishment.image.url,
                },
                address: {
                    zipcode: e.adminUser.establishment.address.zipcode,
                    street: e.adminUser.establishment.address.street,
                    streetNumber: e.adminUser.establishment.address.streetNumber,
                    complementary: e.adminUser.establishment.address.complementary === null || e.adminUser.establishment.address.complementary === undefined ? null : e.adminUser.establishment.address.complementary,
                    neighborhood: e.adminUser.establishment.address.neighborhood,
                    city: e.adminUser.establishment.address.city,
                    state: e.adminUser.establishment.address.state,
                    countryCode: e.adminUser.establishment.address.countryCode,
                    latLng: {
                        lat: e.adminUser.establishment.address.latLng.lat,
                        lng: e.adminUser.establishment.address.latLng.lng,
                    },
                },
                segment: e.adminUser.establishment.segment,
                showOnApp: !!e.adminUser.establishment.showOnApp,
            },
        },
        collectDate: e.collectDate === null || e.collectDate === undefined ? null : new Date(parseInt(e.collectDate.split("-")[0], 10), parseInt(e.collectDate.split("-")[1], 10) - 1, parseInt(e.collectDate.split("-")[2], 10)),
        turn: e.turn,
        status: e.status,
        description: e.description === null || e.description === undefined ? null : e.description,
        createdAt: new Date(parseInt(e.createdAt.split("-")[0], 10), parseInt(e.createdAt.split("-")[1], 10) - 1, parseInt(e.createdAt.split("-")[2], 10)),
        address: {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        recoveredCO2: e.recoveredCO2 || 0,
        cooperative: e.cooperative === null || e.cooperative === undefined ? null : {
            id: e.cooperative.id,
            name: e.cooperative.name,
            image: e.cooperative.image === null || e.cooperative.image === undefined ? null : {
                thumb: {
                    width: e.cooperative.image.thumb.width || 0,
                    height: e.cooperative.image.thumb.height || 0,
                    url: e.cooperative.image.thumb.url,
                },
                width: e.cooperative.image.width || 0,
                height: e.cooperative.image.height || 0,
                url: e.cooperative.image.url,
            },
            email: e.cooperative.email,
            type: e.cooperative.type,
            createdAt: new Date(e.cooperative.createdAt + "Z"),
            legalUser: e.cooperative.legalUser === null || e.cooperative.legalUser === undefined ? null : {
                id: e.cooperative.legalUser.id,
                fantasyName: e.cooperative.legalUser.fantasyName,
                documentNumber: e.cooperative.legalUser.documentNumber,
                signatureId: e.cooperative.legalUser.signatureId === null || e.cooperative.legalUser.signatureId === undefined ? null : e.cooperative.legalUser.signatureId || 0,
                subscriptionStatus: e.cooperative.legalUser.subscriptionStatus,
            },
            establishment: e.cooperative.establishment === null || e.cooperative.establishment === undefined ? null : {
                id: e.cooperative.establishment.id,
                name: e.cooperative.establishment.name,
                documentNumber: e.cooperative.establishment.documentNumber,
                phone: e.cooperative.establishment.phone,
                logo: e.cooperative.establishment.logo === null || e.cooperative.establishment.logo === undefined ? null : {
                    thumb: {
                        width: e.cooperative.establishment.logo.thumb.width || 0,
                        height: e.cooperative.establishment.logo.thumb.height || 0,
                        url: e.cooperative.establishment.logo.thumb.url,
                    },
                    width: e.cooperative.establishment.logo.width || 0,
                    height: e.cooperative.establishment.logo.height || 0,
                    url: e.cooperative.establishment.logo.url,
                },
                image: e.cooperative.establishment.image === null || e.cooperative.establishment.image === undefined ? null : {
                    thumb: {
                        width: e.cooperative.establishment.image.thumb.width || 0,
                        height: e.cooperative.establishment.image.thumb.height || 0,
                        url: e.cooperative.establishment.image.thumb.url,
                    },
                    width: e.cooperative.establishment.image.width || 0,
                    height: e.cooperative.establishment.image.height || 0,
                    url: e.cooperative.establishment.image.url,
                },
                address: {
                    zipcode: e.cooperative.establishment.address.zipcode,
                    street: e.cooperative.establishment.address.street,
                    streetNumber: e.cooperative.establishment.address.streetNumber,
                    complementary: e.cooperative.establishment.address.complementary === null || e.cooperative.establishment.address.complementary === undefined ? null : e.cooperative.establishment.address.complementary,
                    neighborhood: e.cooperative.establishment.address.neighborhood,
                    city: e.cooperative.establishment.address.city,
                    state: e.cooperative.establishment.address.state,
                    countryCode: e.cooperative.establishment.address.countryCode,
                    latLng: {
                        lat: e.cooperative.establishment.address.latLng.lat,
                        lng: e.cooperative.establishment.address.latLng.lng,
                    },
                },
                segment: e.cooperative.establishment.segment,
                showOnApp: !!e.cooperative.establishment.showOnApp,
            },
        },
    }));
}

export async function getCollect(id: string, progress?: (progress: number) => void): Promise<Collect> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getCollect", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            image: ret.adminUser.image === null || ret.adminUser.image === undefined ? null : {
                thumb: {
                    width: ret.adminUser.image.thumb.width || 0,
                    height: ret.adminUser.image.thumb.height || 0,
                    url: ret.adminUser.image.thumb.url,
                },
                width: ret.adminUser.image.width || 0,
                height: ret.adminUser.image.height || 0,
                url: ret.adminUser.image.url,
            },
            email: ret.adminUser.email,
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            legalUser: ret.adminUser.legalUser === null || ret.adminUser.legalUser === undefined ? null : {
                id: ret.adminUser.legalUser.id,
                fantasyName: ret.adminUser.legalUser.fantasyName,
                documentNumber: ret.adminUser.legalUser.documentNumber,
                signatureId: ret.adminUser.legalUser.signatureId === null || ret.adminUser.legalUser.signatureId === undefined ? null : ret.adminUser.legalUser.signatureId || 0,
                subscriptionStatus: ret.adminUser.legalUser.subscriptionStatus,
            },
            establishment: ret.adminUser.establishment === null || ret.adminUser.establishment === undefined ? null : {
                id: ret.adminUser.establishment.id,
                name: ret.adminUser.establishment.name,
                documentNumber: ret.adminUser.establishment.documentNumber,
                phone: ret.adminUser.establishment.phone,
                logo: ret.adminUser.establishment.logo === null || ret.adminUser.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.logo.thumb.width || 0,
                        height: ret.adminUser.establishment.logo.thumb.height || 0,
                        url: ret.adminUser.establishment.logo.thumb.url,
                    },
                    width: ret.adminUser.establishment.logo.width || 0,
                    height: ret.adminUser.establishment.logo.height || 0,
                    url: ret.adminUser.establishment.logo.url,
                },
                image: ret.adminUser.establishment.image === null || ret.adminUser.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.image.thumb.width || 0,
                        height: ret.adminUser.establishment.image.thumb.height || 0,
                        url: ret.adminUser.establishment.image.thumb.url,
                    },
                    width: ret.adminUser.establishment.image.width || 0,
                    height: ret.adminUser.establishment.image.height || 0,
                    url: ret.adminUser.establishment.image.url,
                },
                address: {
                    zipcode: ret.adminUser.establishment.address.zipcode,
                    street: ret.adminUser.establishment.address.street,
                    streetNumber: ret.adminUser.establishment.address.streetNumber,
                    complementary: ret.adminUser.establishment.address.complementary === null || ret.adminUser.establishment.address.complementary === undefined ? null : ret.adminUser.establishment.address.complementary,
                    neighborhood: ret.adminUser.establishment.address.neighborhood,
                    city: ret.adminUser.establishment.address.city,
                    state: ret.adminUser.establishment.address.state,
                    countryCode: ret.adminUser.establishment.address.countryCode,
                    latLng: {
                        lat: ret.adminUser.establishment.address.latLng.lat,
                        lng: ret.adminUser.establishment.address.latLng.lng,
                    },
                },
                segment: ret.adminUser.establishment.segment,
                showOnApp: !!ret.adminUser.establishment.showOnApp,
            },
        },
        collectDate: ret.collectDate === null || ret.collectDate === undefined ? null : new Date(parseInt(ret.collectDate.split("-")[0], 10), parseInt(ret.collectDate.split("-")[1], 10) - 1, parseInt(ret.collectDate.split("-")[2], 10)),
        turn: ret.turn,
        status: ret.status,
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        recoveredCO2: ret.recoveredCO2 || 0,
        cooperative: ret.cooperative === null || ret.cooperative === undefined ? null : {
            id: ret.cooperative.id,
            name: ret.cooperative.name,
            image: ret.cooperative.image === null || ret.cooperative.image === undefined ? null : {
                thumb: {
                    width: ret.cooperative.image.thumb.width || 0,
                    height: ret.cooperative.image.thumb.height || 0,
                    url: ret.cooperative.image.thumb.url,
                },
                width: ret.cooperative.image.width || 0,
                height: ret.cooperative.image.height || 0,
                url: ret.cooperative.image.url,
            },
            email: ret.cooperative.email,
            type: ret.cooperative.type,
            createdAt: new Date(ret.cooperative.createdAt + "Z"),
            legalUser: ret.cooperative.legalUser === null || ret.cooperative.legalUser === undefined ? null : {
                id: ret.cooperative.legalUser.id,
                fantasyName: ret.cooperative.legalUser.fantasyName,
                documentNumber: ret.cooperative.legalUser.documentNumber,
                signatureId: ret.cooperative.legalUser.signatureId === null || ret.cooperative.legalUser.signatureId === undefined ? null : ret.cooperative.legalUser.signatureId || 0,
                subscriptionStatus: ret.cooperative.legalUser.subscriptionStatus,
            },
            establishment: ret.cooperative.establishment === null || ret.cooperative.establishment === undefined ? null : {
                id: ret.cooperative.establishment.id,
                name: ret.cooperative.establishment.name,
                documentNumber: ret.cooperative.establishment.documentNumber,
                phone: ret.cooperative.establishment.phone,
                logo: ret.cooperative.establishment.logo === null || ret.cooperative.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.logo.thumb.width || 0,
                        height: ret.cooperative.establishment.logo.thumb.height || 0,
                        url: ret.cooperative.establishment.logo.thumb.url,
                    },
                    width: ret.cooperative.establishment.logo.width || 0,
                    height: ret.cooperative.establishment.logo.height || 0,
                    url: ret.cooperative.establishment.logo.url,
                },
                image: ret.cooperative.establishment.image === null || ret.cooperative.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.image.thumb.width || 0,
                        height: ret.cooperative.establishment.image.thumb.height || 0,
                        url: ret.cooperative.establishment.image.thumb.url,
                    },
                    width: ret.cooperative.establishment.image.width || 0,
                    height: ret.cooperative.establishment.image.height || 0,
                    url: ret.cooperative.establishment.image.url,
                },
                address: {
                    zipcode: ret.cooperative.establishment.address.zipcode,
                    street: ret.cooperative.establishment.address.street,
                    streetNumber: ret.cooperative.establishment.address.streetNumber,
                    complementary: ret.cooperative.establishment.address.complementary === null || ret.cooperative.establishment.address.complementary === undefined ? null : ret.cooperative.establishment.address.complementary,
                    neighborhood: ret.cooperative.establishment.address.neighborhood,
                    city: ret.cooperative.establishment.address.city,
                    state: ret.cooperative.establishment.address.state,
                    countryCode: ret.cooperative.establishment.address.countryCode,
                    latLng: {
                        lat: ret.cooperative.establishment.address.latLng.lat,
                        lng: ret.cooperative.establishment.address.latLng.lng,
                    },
                },
                segment: ret.cooperative.establishment.segment,
                showOnApp: !!ret.cooperative.establishment.showOnApp,
            },
        },
    };
}

export async function exportCSVCollect(filterOptions: FilterByPeriod, progress?: (progress: number) => void): Promise<string> {
    const args = {
        filterOptions: {
            startDate: filterOptions.startDate === null || filterOptions.startDate === undefined ? null : typeof(filterOptions.startDate) === "string" ? new Date(new Date(filterOptions.startDate).getTime() - new Date(filterOptions.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.startDate.getTime() - filterOptions.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            endDate: filterOptions.endDate === null || filterOptions.endDate === undefined ? null : typeof(filterOptions.endDate) === "string" ? new Date(new Date(filterOptions.endDate).getTime() - new Date(filterOptions.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filterOptions.endDate.getTime() - filterOptions.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "exportCSVCollect", args, progress});
    return ret;
}

export async function addCO2ToCollect(id: string, recoveredCO2: number, progress?: (progress: number) => void): Promise<Collect> {
    const args = {
        id: id,
        recoveredCO2: recoveredCO2 || 0,
    };
    const ret = await makeRequest({name: "addCO2ToCollect", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            image: ret.adminUser.image === null || ret.adminUser.image === undefined ? null : {
                thumb: {
                    width: ret.adminUser.image.thumb.width || 0,
                    height: ret.adminUser.image.thumb.height || 0,
                    url: ret.adminUser.image.thumb.url,
                },
                width: ret.adminUser.image.width || 0,
                height: ret.adminUser.image.height || 0,
                url: ret.adminUser.image.url,
            },
            email: ret.adminUser.email,
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            legalUser: ret.adminUser.legalUser === null || ret.adminUser.legalUser === undefined ? null : {
                id: ret.adminUser.legalUser.id,
                fantasyName: ret.adminUser.legalUser.fantasyName,
                documentNumber: ret.adminUser.legalUser.documentNumber,
                signatureId: ret.adminUser.legalUser.signatureId === null || ret.adminUser.legalUser.signatureId === undefined ? null : ret.adminUser.legalUser.signatureId || 0,
                subscriptionStatus: ret.adminUser.legalUser.subscriptionStatus,
            },
            establishment: ret.adminUser.establishment === null || ret.adminUser.establishment === undefined ? null : {
                id: ret.adminUser.establishment.id,
                name: ret.adminUser.establishment.name,
                documentNumber: ret.adminUser.establishment.documentNumber,
                phone: ret.adminUser.establishment.phone,
                logo: ret.adminUser.establishment.logo === null || ret.adminUser.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.logo.thumb.width || 0,
                        height: ret.adminUser.establishment.logo.thumb.height || 0,
                        url: ret.adminUser.establishment.logo.thumb.url,
                    },
                    width: ret.adminUser.establishment.logo.width || 0,
                    height: ret.adminUser.establishment.logo.height || 0,
                    url: ret.adminUser.establishment.logo.url,
                },
                image: ret.adminUser.establishment.image === null || ret.adminUser.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.image.thumb.width || 0,
                        height: ret.adminUser.establishment.image.thumb.height || 0,
                        url: ret.adminUser.establishment.image.thumb.url,
                    },
                    width: ret.adminUser.establishment.image.width || 0,
                    height: ret.adminUser.establishment.image.height || 0,
                    url: ret.adminUser.establishment.image.url,
                },
                address: {
                    zipcode: ret.adminUser.establishment.address.zipcode,
                    street: ret.adminUser.establishment.address.street,
                    streetNumber: ret.adminUser.establishment.address.streetNumber,
                    complementary: ret.adminUser.establishment.address.complementary === null || ret.adminUser.establishment.address.complementary === undefined ? null : ret.adminUser.establishment.address.complementary,
                    neighborhood: ret.adminUser.establishment.address.neighborhood,
                    city: ret.adminUser.establishment.address.city,
                    state: ret.adminUser.establishment.address.state,
                    countryCode: ret.adminUser.establishment.address.countryCode,
                    latLng: {
                        lat: ret.adminUser.establishment.address.latLng.lat,
                        lng: ret.adminUser.establishment.address.latLng.lng,
                    },
                },
                segment: ret.adminUser.establishment.segment,
                showOnApp: !!ret.adminUser.establishment.showOnApp,
            },
        },
        collectDate: ret.collectDate === null || ret.collectDate === undefined ? null : new Date(parseInt(ret.collectDate.split("-")[0], 10), parseInt(ret.collectDate.split("-")[1], 10) - 1, parseInt(ret.collectDate.split("-")[2], 10)),
        turn: ret.turn,
        status: ret.status,
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        recoveredCO2: ret.recoveredCO2 || 0,
        cooperative: ret.cooperative === null || ret.cooperative === undefined ? null : {
            id: ret.cooperative.id,
            name: ret.cooperative.name,
            image: ret.cooperative.image === null || ret.cooperative.image === undefined ? null : {
                thumb: {
                    width: ret.cooperative.image.thumb.width || 0,
                    height: ret.cooperative.image.thumb.height || 0,
                    url: ret.cooperative.image.thumb.url,
                },
                width: ret.cooperative.image.width || 0,
                height: ret.cooperative.image.height || 0,
                url: ret.cooperative.image.url,
            },
            email: ret.cooperative.email,
            type: ret.cooperative.type,
            createdAt: new Date(ret.cooperative.createdAt + "Z"),
            legalUser: ret.cooperative.legalUser === null || ret.cooperative.legalUser === undefined ? null : {
                id: ret.cooperative.legalUser.id,
                fantasyName: ret.cooperative.legalUser.fantasyName,
                documentNumber: ret.cooperative.legalUser.documentNumber,
                signatureId: ret.cooperative.legalUser.signatureId === null || ret.cooperative.legalUser.signatureId === undefined ? null : ret.cooperative.legalUser.signatureId || 0,
                subscriptionStatus: ret.cooperative.legalUser.subscriptionStatus,
            },
            establishment: ret.cooperative.establishment === null || ret.cooperative.establishment === undefined ? null : {
                id: ret.cooperative.establishment.id,
                name: ret.cooperative.establishment.name,
                documentNumber: ret.cooperative.establishment.documentNumber,
                phone: ret.cooperative.establishment.phone,
                logo: ret.cooperative.establishment.logo === null || ret.cooperative.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.logo.thumb.width || 0,
                        height: ret.cooperative.establishment.logo.thumb.height || 0,
                        url: ret.cooperative.establishment.logo.thumb.url,
                    },
                    width: ret.cooperative.establishment.logo.width || 0,
                    height: ret.cooperative.establishment.logo.height || 0,
                    url: ret.cooperative.establishment.logo.url,
                },
                image: ret.cooperative.establishment.image === null || ret.cooperative.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.image.thumb.width || 0,
                        height: ret.cooperative.establishment.image.thumb.height || 0,
                        url: ret.cooperative.establishment.image.thumb.url,
                    },
                    width: ret.cooperative.establishment.image.width || 0,
                    height: ret.cooperative.establishment.image.height || 0,
                    url: ret.cooperative.establishment.image.url,
                },
                address: {
                    zipcode: ret.cooperative.establishment.address.zipcode,
                    street: ret.cooperative.establishment.address.street,
                    streetNumber: ret.cooperative.establishment.address.streetNumber,
                    complementary: ret.cooperative.establishment.address.complementary === null || ret.cooperative.establishment.address.complementary === undefined ? null : ret.cooperative.establishment.address.complementary,
                    neighborhood: ret.cooperative.establishment.address.neighborhood,
                    city: ret.cooperative.establishment.address.city,
                    state: ret.cooperative.establishment.address.state,
                    countryCode: ret.cooperative.establishment.address.countryCode,
                    latLng: {
                        lat: ret.cooperative.establishment.address.latLng.lat,
                        lng: ret.cooperative.establishment.address.latLng.lng,
                    },
                },
                segment: ret.cooperative.establishment.segment,
                showOnApp: !!ret.cooperative.establishment.showOnApp,
            },
        },
    };
}

export async function addCooperativeToCollect(id: string, cooperaiveId: string, progress?: (progress: number) => void): Promise<Collect> {
    const args = {
        id: id,
        cooperaiveId: cooperaiveId,
    };
    const ret = await makeRequest({name: "addCooperativeToCollect", args, progress});
    return {
        id: ret.id,
        code: ret.code,
        adminUser: {
            id: ret.adminUser.id,
            name: ret.adminUser.name,
            image: ret.adminUser.image === null || ret.adminUser.image === undefined ? null : {
                thumb: {
                    width: ret.adminUser.image.thumb.width || 0,
                    height: ret.adminUser.image.thumb.height || 0,
                    url: ret.adminUser.image.thumb.url,
                },
                width: ret.adminUser.image.width || 0,
                height: ret.adminUser.image.height || 0,
                url: ret.adminUser.image.url,
            },
            email: ret.adminUser.email,
            type: ret.adminUser.type,
            createdAt: new Date(ret.adminUser.createdAt + "Z"),
            legalUser: ret.adminUser.legalUser === null || ret.adminUser.legalUser === undefined ? null : {
                id: ret.adminUser.legalUser.id,
                fantasyName: ret.adminUser.legalUser.fantasyName,
                documentNumber: ret.adminUser.legalUser.documentNumber,
                signatureId: ret.adminUser.legalUser.signatureId === null || ret.adminUser.legalUser.signatureId === undefined ? null : ret.adminUser.legalUser.signatureId || 0,
                subscriptionStatus: ret.adminUser.legalUser.subscriptionStatus,
            },
            establishment: ret.adminUser.establishment === null || ret.adminUser.establishment === undefined ? null : {
                id: ret.adminUser.establishment.id,
                name: ret.adminUser.establishment.name,
                documentNumber: ret.adminUser.establishment.documentNumber,
                phone: ret.adminUser.establishment.phone,
                logo: ret.adminUser.establishment.logo === null || ret.adminUser.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.logo.thumb.width || 0,
                        height: ret.adminUser.establishment.logo.thumb.height || 0,
                        url: ret.adminUser.establishment.logo.thumb.url,
                    },
                    width: ret.adminUser.establishment.logo.width || 0,
                    height: ret.adminUser.establishment.logo.height || 0,
                    url: ret.adminUser.establishment.logo.url,
                },
                image: ret.adminUser.establishment.image === null || ret.adminUser.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.adminUser.establishment.image.thumb.width || 0,
                        height: ret.adminUser.establishment.image.thumb.height || 0,
                        url: ret.adminUser.establishment.image.thumb.url,
                    },
                    width: ret.adminUser.establishment.image.width || 0,
                    height: ret.adminUser.establishment.image.height || 0,
                    url: ret.adminUser.establishment.image.url,
                },
                address: {
                    zipcode: ret.adminUser.establishment.address.zipcode,
                    street: ret.adminUser.establishment.address.street,
                    streetNumber: ret.adminUser.establishment.address.streetNumber,
                    complementary: ret.adminUser.establishment.address.complementary === null || ret.adminUser.establishment.address.complementary === undefined ? null : ret.adminUser.establishment.address.complementary,
                    neighborhood: ret.adminUser.establishment.address.neighborhood,
                    city: ret.adminUser.establishment.address.city,
                    state: ret.adminUser.establishment.address.state,
                    countryCode: ret.adminUser.establishment.address.countryCode,
                    latLng: {
                        lat: ret.adminUser.establishment.address.latLng.lat,
                        lng: ret.adminUser.establishment.address.latLng.lng,
                    },
                },
                segment: ret.adminUser.establishment.segment,
                showOnApp: !!ret.adminUser.establishment.showOnApp,
            },
        },
        collectDate: ret.collectDate === null || ret.collectDate === undefined ? null : new Date(parseInt(ret.collectDate.split("-")[0], 10), parseInt(ret.collectDate.split("-")[1], 10) - 1, parseInt(ret.collectDate.split("-")[2], 10)),
        turn: ret.turn,
        status: ret.status,
        description: ret.description === null || ret.description === undefined ? null : ret.description,
        createdAt: new Date(parseInt(ret.createdAt.split("-")[0], 10), parseInt(ret.createdAt.split("-")[1], 10) - 1, parseInt(ret.createdAt.split("-")[2], 10)),
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        recoveredCO2: ret.recoveredCO2 || 0,
        cooperative: ret.cooperative === null || ret.cooperative === undefined ? null : {
            id: ret.cooperative.id,
            name: ret.cooperative.name,
            image: ret.cooperative.image === null || ret.cooperative.image === undefined ? null : {
                thumb: {
                    width: ret.cooperative.image.thumb.width || 0,
                    height: ret.cooperative.image.thumb.height || 0,
                    url: ret.cooperative.image.thumb.url,
                },
                width: ret.cooperative.image.width || 0,
                height: ret.cooperative.image.height || 0,
                url: ret.cooperative.image.url,
            },
            email: ret.cooperative.email,
            type: ret.cooperative.type,
            createdAt: new Date(ret.cooperative.createdAt + "Z"),
            legalUser: ret.cooperative.legalUser === null || ret.cooperative.legalUser === undefined ? null : {
                id: ret.cooperative.legalUser.id,
                fantasyName: ret.cooperative.legalUser.fantasyName,
                documentNumber: ret.cooperative.legalUser.documentNumber,
                signatureId: ret.cooperative.legalUser.signatureId === null || ret.cooperative.legalUser.signatureId === undefined ? null : ret.cooperative.legalUser.signatureId || 0,
                subscriptionStatus: ret.cooperative.legalUser.subscriptionStatus,
            },
            establishment: ret.cooperative.establishment === null || ret.cooperative.establishment === undefined ? null : {
                id: ret.cooperative.establishment.id,
                name: ret.cooperative.establishment.name,
                documentNumber: ret.cooperative.establishment.documentNumber,
                phone: ret.cooperative.establishment.phone,
                logo: ret.cooperative.establishment.logo === null || ret.cooperative.establishment.logo === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.logo.thumb.width || 0,
                        height: ret.cooperative.establishment.logo.thumb.height || 0,
                        url: ret.cooperative.establishment.logo.thumb.url,
                    },
                    width: ret.cooperative.establishment.logo.width || 0,
                    height: ret.cooperative.establishment.logo.height || 0,
                    url: ret.cooperative.establishment.logo.url,
                },
                image: ret.cooperative.establishment.image === null || ret.cooperative.establishment.image === undefined ? null : {
                    thumb: {
                        width: ret.cooperative.establishment.image.thumb.width || 0,
                        height: ret.cooperative.establishment.image.thumb.height || 0,
                        url: ret.cooperative.establishment.image.thumb.url,
                    },
                    width: ret.cooperative.establishment.image.width || 0,
                    height: ret.cooperative.establishment.image.height || 0,
                    url: ret.cooperative.establishment.image.url,
                },
                address: {
                    zipcode: ret.cooperative.establishment.address.zipcode,
                    street: ret.cooperative.establishment.address.street,
                    streetNumber: ret.cooperative.establishment.address.streetNumber,
                    complementary: ret.cooperative.establishment.address.complementary === null || ret.cooperative.establishment.address.complementary === undefined ? null : ret.cooperative.establishment.address.complementary,
                    neighborhood: ret.cooperative.establishment.address.neighborhood,
                    city: ret.cooperative.establishment.address.city,
                    state: ret.cooperative.establishment.address.state,
                    countryCode: ret.cooperative.establishment.address.countryCode,
                    latLng: {
                        lat: ret.cooperative.establishment.address.latLng.lat,
                        lng: ret.cooperative.establishment.address.latLng.lng,
                    },
                },
                segment: ret.cooperative.establishment.segment,
                showOnApp: !!ret.cooperative.establishment.showOnApp,
            },
        },
    };
}

export async function getAllDeleteRequests(pageOffset: number, progress?: (progress: number) => void): Promise<DeleteRequest[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllDeleteRequests", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        user: {
            id: e.user.id,
            email: e.user.email,
            name: e.user.name,
            phone: e.user.phone,
            documentNumber: e.user.documentNumber === null || e.user.documentNumber === undefined ? null : e.user.documentNumber,
            image: e.user.image === null || e.user.image === undefined ? null : {
                thumb: {
                    width: e.user.image.thumb.width || 0,
                    height: e.user.image.thumb.height || 0,
                    url: e.user.image.thumb.url,
                },
                width: e.user.image.width || 0,
                height: e.user.image.height || 0,
                url: e.user.image.url,
            },
            createdAt: new Date(e.user.createdAt + "Z"),
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function approveDeleteRequest(deleteRequestId: string, progress?: (progress: number) => void): Promise<DeleteRequest> {
    const args = {
        deleteRequestId: deleteRequestId,
    };
    const ret = await makeRequest({name: "approveDeleteRequest", args, progress});
    return {
        id: ret.id,
        user: {
            id: ret.user.id,
            email: ret.user.email,
            name: ret.user.name,
            phone: ret.user.phone,
            documentNumber: ret.user.documentNumber === null || ret.user.documentNumber === undefined ? null : ret.user.documentNumber,
            image: ret.user.image === null || ret.user.image === undefined ? null : {
                thumb: {
                    width: ret.user.image.thumb.width || 0,
                    height: ret.user.image.thumb.height || 0,
                    url: ret.user.image.thumb.url,
                },
                width: ret.user.image.width || 0,
                height: ret.user.image.height || 0,
                url: ret.user.image.url,
            },
            createdAt: new Date(ret.user.createdAt + "Z"),
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getEstablishment(id: string, progress?: (progress: number) => void): Promise<Establishment> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getEstablishment", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        phone: ret.phone,
        logo: ret.logo === null || ret.logo === undefined ? null : {
            thumb: {
                width: ret.logo.thumb.width || 0,
                height: ret.logo.thumb.height || 0,
                url: ret.logo.thumb.url,
            },
            width: ret.logo.width || 0,
            height: ret.logo.height || 0,
            url: ret.logo.url,
        },
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        segment: ret.segment,
        showOnApp: !!ret.showOnApp,
    };
}

export async function getAllEstablishment(pageOffset: number, progress?: (progress: number) => void): Promise<Establishment[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllEstablishment", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        documentNumber: e.documentNumber,
        phone: e.phone,
        logo: e.logo === null || e.logo === undefined ? null : {
            thumb: {
                width: e.logo.thumb.width || 0,
                height: e.logo.thumb.height || 0,
                url: e.logo.thumb.url,
            },
            width: e.logo.width || 0,
            height: e.logo.height || 0,
            url: e.logo.url,
        },
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        address: {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        segment: e.segment,
        showOnApp: !!e.showOnApp,
    }));
}

export async function editEstablishmentForAdmin(id: string, elegalUser: LegalUser, editEstablishment: EditEstablishment, progress?: (progress: number) => void): Promise<Establishment> {
    const args = {
        id: id,
        elegalUser: {
            id: elegalUser.id,
            fantasyName: elegalUser.fantasyName,
            documentNumber: elegalUser.documentNumber,
            signatureId: elegalUser.signatureId === null || elegalUser.signatureId === undefined ? null : elegalUser.signatureId || 0,
            subscriptionStatus: elegalUser.subscriptionStatus,
        },
        editEstablishment: {
            phone: editEstablishment.phone,
            logo: editEstablishment.logo === null || editEstablishment.logo === undefined ? null : {
                bytes: editEstablishment.logo.bytes === null || editEstablishment.logo.bytes === undefined ? null : editEstablishment.logo.bytes.toString("base64"),
                image: editEstablishment.logo.image === null || editEstablishment.logo.image === undefined ? null : {
                    thumb: {
                        width: editEstablishment.logo.image.thumb.width || 0,
                        height: editEstablishment.logo.image.thumb.height || 0,
                        url: editEstablishment.logo.image.thumb.url,
                    },
                    width: editEstablishment.logo.image.width || 0,
                    height: editEstablishment.logo.image.height || 0,
                    url: editEstablishment.logo.image.url,
                },
            },
            image: editEstablishment.image === null || editEstablishment.image === undefined ? null : {
                bytes: editEstablishment.image.bytes === null || editEstablishment.image.bytes === undefined ? null : editEstablishment.image.bytes.toString("base64"),
                image: editEstablishment.image.image === null || editEstablishment.image.image === undefined ? null : {
                    thumb: {
                        width: editEstablishment.image.image.thumb.width || 0,
                        height: editEstablishment.image.image.thumb.height || 0,
                        url: editEstablishment.image.image.thumb.url,
                    },
                    width: editEstablishment.image.image.width || 0,
                    height: editEstablishment.image.image.height || 0,
                    url: editEstablishment.image.image.url,
                },
            },
            address: {
                zipcode: editEstablishment.address.zipcode,
                street: editEstablishment.address.street,
                streetNumber: editEstablishment.address.streetNumber,
                complementary: editEstablishment.address.complementary === null || editEstablishment.address.complementary === undefined ? null : editEstablishment.address.complementary,
                neighborhood: editEstablishment.address.neighborhood,
                city: editEstablishment.address.city,
                state: editEstablishment.address.state,
                countryCode: editEstablishment.address.countryCode,
                latLng: {
                    lat: editEstablishment.address.latLng.lat,
                    lng: editEstablishment.address.latLng.lng,
                },
            },
            segment: editEstablishment.segment,
        },
    };
    const ret = await makeRequest({name: "editEstablishmentForAdmin", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        phone: ret.phone,
        logo: ret.logo === null || ret.logo === undefined ? null : {
            thumb: {
                width: ret.logo.thumb.width || 0,
                height: ret.logo.thumb.height || 0,
                url: ret.logo.thumb.url,
            },
            width: ret.logo.width || 0,
            height: ret.logo.height || 0,
            url: ret.logo.url,
        },
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        segment: ret.segment,
        showOnApp: !!ret.showOnApp,
    };
}

export async function editEstablishment(id: string, editEstablishment: EditEstablishment, progress?: (progress: number) => void): Promise<Establishment> {
    const args = {
        id: id,
        editEstablishment: {
            phone: editEstablishment.phone,
            logo: editEstablishment.logo === null || editEstablishment.logo === undefined ? null : {
                bytes: editEstablishment.logo.bytes === null || editEstablishment.logo.bytes === undefined ? null : editEstablishment.logo.bytes.toString("base64"),
                image: editEstablishment.logo.image === null || editEstablishment.logo.image === undefined ? null : {
                    thumb: {
                        width: editEstablishment.logo.image.thumb.width || 0,
                        height: editEstablishment.logo.image.thumb.height || 0,
                        url: editEstablishment.logo.image.thumb.url,
                    },
                    width: editEstablishment.logo.image.width || 0,
                    height: editEstablishment.logo.image.height || 0,
                    url: editEstablishment.logo.image.url,
                },
            },
            image: editEstablishment.image === null || editEstablishment.image === undefined ? null : {
                bytes: editEstablishment.image.bytes === null || editEstablishment.image.bytes === undefined ? null : editEstablishment.image.bytes.toString("base64"),
                image: editEstablishment.image.image === null || editEstablishment.image.image === undefined ? null : {
                    thumb: {
                        width: editEstablishment.image.image.thumb.width || 0,
                        height: editEstablishment.image.image.thumb.height || 0,
                        url: editEstablishment.image.image.thumb.url,
                    },
                    width: editEstablishment.image.image.width || 0,
                    height: editEstablishment.image.image.height || 0,
                    url: editEstablishment.image.image.url,
                },
            },
            address: {
                zipcode: editEstablishment.address.zipcode,
                street: editEstablishment.address.street,
                streetNumber: editEstablishment.address.streetNumber,
                complementary: editEstablishment.address.complementary === null || editEstablishment.address.complementary === undefined ? null : editEstablishment.address.complementary,
                neighborhood: editEstablishment.address.neighborhood,
                city: editEstablishment.address.city,
                state: editEstablishment.address.state,
                countryCode: editEstablishment.address.countryCode,
                latLng: {
                    lat: editEstablishment.address.latLng.lat,
                    lng: editEstablishment.address.latLng.lng,
                },
            },
            segment: editEstablishment.segment,
        },
    };
    const ret = await makeRequest({name: "editEstablishment", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        phone: ret.phone,
        logo: ret.logo === null || ret.logo === undefined ? null : {
            thumb: {
                width: ret.logo.thumb.width || 0,
                height: ret.logo.thumb.height || 0,
                url: ret.logo.thumb.url,
            },
            width: ret.logo.width || 0,
            height: ret.logo.height || 0,
            url: ret.logo.url,
        },
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        segment: ret.segment,
        showOnApp: !!ret.showOnApp,
    };
}

export async function createEstablishment(NewEstablishment: NewEstablishment, progress?: (progress: number) => void): Promise<Establishment> {
    const args = {
        NewEstablishment: {
            phone: NewEstablishment.phone,
            logo: NewEstablishment.logo === null || NewEstablishment.logo === undefined ? null : {
                bytes: NewEstablishment.logo.bytes === null || NewEstablishment.logo.bytes === undefined ? null : NewEstablishment.logo.bytes.toString("base64"),
                image: NewEstablishment.logo.image === null || NewEstablishment.logo.image === undefined ? null : {
                    thumb: {
                        width: NewEstablishment.logo.image.thumb.width || 0,
                        height: NewEstablishment.logo.image.thumb.height || 0,
                        url: NewEstablishment.logo.image.thumb.url,
                    },
                    width: NewEstablishment.logo.image.width || 0,
                    height: NewEstablishment.logo.image.height || 0,
                    url: NewEstablishment.logo.image.url,
                },
            },
            image: NewEstablishment.image === null || NewEstablishment.image === undefined ? null : {
                bytes: NewEstablishment.image.bytes === null || NewEstablishment.image.bytes === undefined ? null : NewEstablishment.image.bytes.toString("base64"),
                image: NewEstablishment.image.image === null || NewEstablishment.image.image === undefined ? null : {
                    thumb: {
                        width: NewEstablishment.image.image.thumb.width || 0,
                        height: NewEstablishment.image.image.thumb.height || 0,
                        url: NewEstablishment.image.image.thumb.url,
                    },
                    width: NewEstablishment.image.image.width || 0,
                    height: NewEstablishment.image.image.height || 0,
                    url: NewEstablishment.image.image.url,
                },
            },
            address: {
                zipcode: NewEstablishment.address.zipcode,
                street: NewEstablishment.address.street,
                streetNumber: NewEstablishment.address.streetNumber,
                complementary: NewEstablishment.address.complementary === null || NewEstablishment.address.complementary === undefined ? null : NewEstablishment.address.complementary,
                neighborhood: NewEstablishment.address.neighborhood,
                city: NewEstablishment.address.city,
                state: NewEstablishment.address.state,
                countryCode: NewEstablishment.address.countryCode,
                latLng: {
                    lat: NewEstablishment.address.latLng.lat,
                    lng: NewEstablishment.address.latLng.lng,
                },
            },
            segment: NewEstablishment.segment,
        },
    };
    const ret = await makeRequest({name: "createEstablishment", args, progress});
    return {
        id: ret.id,
        name: ret.name,
        documentNumber: ret.documentNumber,
        phone: ret.phone,
        logo: ret.logo === null || ret.logo === undefined ? null : {
            thumb: {
                width: ret.logo.thumb.width || 0,
                height: ret.logo.thumb.height || 0,
                url: ret.logo.thumb.url,
            },
            width: ret.logo.width || 0,
            height: ret.logo.height || 0,
            url: ret.logo.url,
        },
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        address: {
            zipcode: ret.address.zipcode,
            street: ret.address.street,
            streetNumber: ret.address.streetNumber,
            complementary: ret.address.complementary === null || ret.address.complementary === undefined ? null : ret.address.complementary,
            neighborhood: ret.address.neighborhood,
            city: ret.address.city,
            state: ret.address.state,
            countryCode: ret.address.countryCode,
            latLng: {
                lat: ret.address.latLng.lat,
                lng: ret.address.latLng.lng,
            },
        },
        segment: ret.segment,
        showOnApp: !!ret.showOnApp,
    };
}

export async function autoCompleteEstablishment(pageOffset: number, name: string, progress?: (progress: number) => void): Promise<Establishment[]> {
    const args = {
        pageOffset: pageOffset || 0,
        name: name,
    };
    const ret = await makeRequest({name: "autoCompleteEstablishment", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        name: e.name,
        documentNumber: e.documentNumber,
        phone: e.phone,
        logo: e.logo === null || e.logo === undefined ? null : {
            thumb: {
                width: e.logo.thumb.width || 0,
                height: e.logo.thumb.height || 0,
                url: e.logo.thumb.url,
            },
            width: e.logo.width || 0,
            height: e.logo.height || 0,
            url: e.logo.url,
        },
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        address: {
            zipcode: e.address.zipcode,
            street: e.address.street,
            streetNumber: e.address.streetNumber,
            complementary: e.address.complementary === null || e.address.complementary === undefined ? null : e.address.complementary,
            neighborhood: e.address.neighborhood,
            city: e.address.city,
            state: e.address.state,
            countryCode: e.address.countryCode,
            latLng: {
                lat: e.address.latLng.lat,
                lng: e.address.latLng.lng,
            },
        },
        segment: e.segment,
        showOnApp: !!e.showOnApp,
    }));
}

export async function getAllMaterials(pageOffset: number, filter: FilterByPeriodAndLegalUser, progress?: (progress: number) => void): Promise<MaterialWithCollect[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: {
            adminUserId: filter.adminUserId === null || filter.adminUserId === undefined ? null : filter.adminUserId,
            startDate: filter.startDate === null || filter.startDate === undefined ? null : typeof(filter.startDate) === "string" ? new Date(new Date(filter.startDate).getTime() - new Date(filter.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startDate.getTime() - filter.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            endDate: filter.endDate === null || filter.endDate === undefined ? null : typeof(filter.endDate) === "string" ? new Date(new Date(filter.endDate).getTime() - new Date(filter.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endDate.getTime() - filter.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "getAllMaterials", args, progress});
    return ret.map((e: any) => ({
        collect: {
            id: e.collect.id,
            code: e.collect.code,
            adminUser: {
                id: e.collect.adminUser.id,
                name: e.collect.adminUser.name,
                image: e.collect.adminUser.image === null || e.collect.adminUser.image === undefined ? null : {
                    thumb: {
                        width: e.collect.adminUser.image.thumb.width || 0,
                        height: e.collect.adminUser.image.thumb.height || 0,
                        url: e.collect.adminUser.image.thumb.url,
                    },
                    width: e.collect.adminUser.image.width || 0,
                    height: e.collect.adminUser.image.height || 0,
                    url: e.collect.adminUser.image.url,
                },
                email: e.collect.adminUser.email,
                type: e.collect.adminUser.type,
                createdAt: new Date(e.collect.adminUser.createdAt + "Z"),
                legalUser: e.collect.adminUser.legalUser === null || e.collect.adminUser.legalUser === undefined ? null : {
                    id: e.collect.adminUser.legalUser.id,
                    fantasyName: e.collect.adminUser.legalUser.fantasyName,
                    documentNumber: e.collect.adminUser.legalUser.documentNumber,
                    signatureId: e.collect.adminUser.legalUser.signatureId === null || e.collect.adminUser.legalUser.signatureId === undefined ? null : e.collect.adminUser.legalUser.signatureId || 0,
                    subscriptionStatus: e.collect.adminUser.legalUser.subscriptionStatus,
                },
                establishment: e.collect.adminUser.establishment === null || e.collect.adminUser.establishment === undefined ? null : {
                    id: e.collect.adminUser.establishment.id,
                    name: e.collect.adminUser.establishment.name,
                    documentNumber: e.collect.adminUser.establishment.documentNumber,
                    phone: e.collect.adminUser.establishment.phone,
                    logo: e.collect.adminUser.establishment.logo === null || e.collect.adminUser.establishment.logo === undefined ? null : {
                        thumb: {
                            width: e.collect.adminUser.establishment.logo.thumb.width || 0,
                            height: e.collect.adminUser.establishment.logo.thumb.height || 0,
                            url: e.collect.adminUser.establishment.logo.thumb.url,
                        },
                        width: e.collect.adminUser.establishment.logo.width || 0,
                        height: e.collect.adminUser.establishment.logo.height || 0,
                        url: e.collect.adminUser.establishment.logo.url,
                    },
                    image: e.collect.adminUser.establishment.image === null || e.collect.adminUser.establishment.image === undefined ? null : {
                        thumb: {
                            width: e.collect.adminUser.establishment.image.thumb.width || 0,
                            height: e.collect.adminUser.establishment.image.thumb.height || 0,
                            url: e.collect.adminUser.establishment.image.thumb.url,
                        },
                        width: e.collect.adminUser.establishment.image.width || 0,
                        height: e.collect.adminUser.establishment.image.height || 0,
                        url: e.collect.adminUser.establishment.image.url,
                    },
                    address: {
                        zipcode: e.collect.adminUser.establishment.address.zipcode,
                        street: e.collect.adminUser.establishment.address.street,
                        streetNumber: e.collect.adminUser.establishment.address.streetNumber,
                        complementary: e.collect.adminUser.establishment.address.complementary === null || e.collect.adminUser.establishment.address.complementary === undefined ? null : e.collect.adminUser.establishment.address.complementary,
                        neighborhood: e.collect.adminUser.establishment.address.neighborhood,
                        city: e.collect.adminUser.establishment.address.city,
                        state: e.collect.adminUser.establishment.address.state,
                        countryCode: e.collect.adminUser.establishment.address.countryCode,
                        latLng: {
                            lat: e.collect.adminUser.establishment.address.latLng.lat,
                            lng: e.collect.adminUser.establishment.address.latLng.lng,
                        },
                    },
                    segment: e.collect.adminUser.establishment.segment,
                    showOnApp: !!e.collect.adminUser.establishment.showOnApp,
                },
            },
            collectDate: e.collect.collectDate === null || e.collect.collectDate === undefined ? null : new Date(parseInt(e.collect.collectDate.split("-")[0], 10), parseInt(e.collect.collectDate.split("-")[1], 10) - 1, parseInt(e.collect.collectDate.split("-")[2], 10)),
            turn: e.collect.turn,
            status: e.collect.status,
            description: e.collect.description === null || e.collect.description === undefined ? null : e.collect.description,
            createdAt: new Date(parseInt(e.collect.createdAt.split("-")[0], 10), parseInt(e.collect.createdAt.split("-")[1], 10) - 1, parseInt(e.collect.createdAt.split("-")[2], 10)),
            address: {
                zipcode: e.collect.address.zipcode,
                street: e.collect.address.street,
                streetNumber: e.collect.address.streetNumber,
                complementary: e.collect.address.complementary === null || e.collect.address.complementary === undefined ? null : e.collect.address.complementary,
                neighborhood: e.collect.address.neighborhood,
                city: e.collect.address.city,
                state: e.collect.address.state,
                countryCode: e.collect.address.countryCode,
                latLng: {
                    lat: e.collect.address.latLng.lat,
                    lng: e.collect.address.latLng.lng,
                },
            },
            recoveredCO2: e.collect.recoveredCO2 || 0,
            cooperative: e.collect.cooperative === null || e.collect.cooperative === undefined ? null : {
                id: e.collect.cooperative.id,
                name: e.collect.cooperative.name,
                image: e.collect.cooperative.image === null || e.collect.cooperative.image === undefined ? null : {
                    thumb: {
                        width: e.collect.cooperative.image.thumb.width || 0,
                        height: e.collect.cooperative.image.thumb.height || 0,
                        url: e.collect.cooperative.image.thumb.url,
                    },
                    width: e.collect.cooperative.image.width || 0,
                    height: e.collect.cooperative.image.height || 0,
                    url: e.collect.cooperative.image.url,
                },
                email: e.collect.cooperative.email,
                type: e.collect.cooperative.type,
                createdAt: new Date(e.collect.cooperative.createdAt + "Z"),
                legalUser: e.collect.cooperative.legalUser === null || e.collect.cooperative.legalUser === undefined ? null : {
                    id: e.collect.cooperative.legalUser.id,
                    fantasyName: e.collect.cooperative.legalUser.fantasyName,
                    documentNumber: e.collect.cooperative.legalUser.documentNumber,
                    signatureId: e.collect.cooperative.legalUser.signatureId === null || e.collect.cooperative.legalUser.signatureId === undefined ? null : e.collect.cooperative.legalUser.signatureId || 0,
                    subscriptionStatus: e.collect.cooperative.legalUser.subscriptionStatus,
                },
                establishment: e.collect.cooperative.establishment === null || e.collect.cooperative.establishment === undefined ? null : {
                    id: e.collect.cooperative.establishment.id,
                    name: e.collect.cooperative.establishment.name,
                    documentNumber: e.collect.cooperative.establishment.documentNumber,
                    phone: e.collect.cooperative.establishment.phone,
                    logo: e.collect.cooperative.establishment.logo === null || e.collect.cooperative.establishment.logo === undefined ? null : {
                        thumb: {
                            width: e.collect.cooperative.establishment.logo.thumb.width || 0,
                            height: e.collect.cooperative.establishment.logo.thumb.height || 0,
                            url: e.collect.cooperative.establishment.logo.thumb.url,
                        },
                        width: e.collect.cooperative.establishment.logo.width || 0,
                        height: e.collect.cooperative.establishment.logo.height || 0,
                        url: e.collect.cooperative.establishment.logo.url,
                    },
                    image: e.collect.cooperative.establishment.image === null || e.collect.cooperative.establishment.image === undefined ? null : {
                        thumb: {
                            width: e.collect.cooperative.establishment.image.thumb.width || 0,
                            height: e.collect.cooperative.establishment.image.thumb.height || 0,
                            url: e.collect.cooperative.establishment.image.thumb.url,
                        },
                        width: e.collect.cooperative.establishment.image.width || 0,
                        height: e.collect.cooperative.establishment.image.height || 0,
                        url: e.collect.cooperative.establishment.image.url,
                    },
                    address: {
                        zipcode: e.collect.cooperative.establishment.address.zipcode,
                        street: e.collect.cooperative.establishment.address.street,
                        streetNumber: e.collect.cooperative.establishment.address.streetNumber,
                        complementary: e.collect.cooperative.establishment.address.complementary === null || e.collect.cooperative.establishment.address.complementary === undefined ? null : e.collect.cooperative.establishment.address.complementary,
                        neighborhood: e.collect.cooperative.establishment.address.neighborhood,
                        city: e.collect.cooperative.establishment.address.city,
                        state: e.collect.cooperative.establishment.address.state,
                        countryCode: e.collect.cooperative.establishment.address.countryCode,
                        latLng: {
                            lat: e.collect.cooperative.establishment.address.latLng.lat,
                            lng: e.collect.cooperative.establishment.address.latLng.lng,
                        },
                    },
                    segment: e.collect.cooperative.establishment.segment,
                    showOnApp: !!e.collect.cooperative.establishment.showOnApp,
                },
            },
        },
        id: e.id,
        materialCode: e.materialCode,
        category: e.category,
        weight: e.weight || 0,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
    }));
}

export async function getMaterial(id: string, progress?: (progress: number) => void): Promise<MaterialWithCollect> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getMaterial", args, progress});
    return {
        collect: {
            id: ret.collect.id,
            code: ret.collect.code,
            adminUser: {
                id: ret.collect.adminUser.id,
                name: ret.collect.adminUser.name,
                image: ret.collect.adminUser.image === null || ret.collect.adminUser.image === undefined ? null : {
                    thumb: {
                        width: ret.collect.adminUser.image.thumb.width || 0,
                        height: ret.collect.adminUser.image.thumb.height || 0,
                        url: ret.collect.adminUser.image.thumb.url,
                    },
                    width: ret.collect.adminUser.image.width || 0,
                    height: ret.collect.adminUser.image.height || 0,
                    url: ret.collect.adminUser.image.url,
                },
                email: ret.collect.adminUser.email,
                type: ret.collect.adminUser.type,
                createdAt: new Date(ret.collect.adminUser.createdAt + "Z"),
                legalUser: ret.collect.adminUser.legalUser === null || ret.collect.adminUser.legalUser === undefined ? null : {
                    id: ret.collect.adminUser.legalUser.id,
                    fantasyName: ret.collect.adminUser.legalUser.fantasyName,
                    documentNumber: ret.collect.adminUser.legalUser.documentNumber,
                    signatureId: ret.collect.adminUser.legalUser.signatureId === null || ret.collect.adminUser.legalUser.signatureId === undefined ? null : ret.collect.adminUser.legalUser.signatureId || 0,
                    subscriptionStatus: ret.collect.adminUser.legalUser.subscriptionStatus,
                },
                establishment: ret.collect.adminUser.establishment === null || ret.collect.adminUser.establishment === undefined ? null : {
                    id: ret.collect.adminUser.establishment.id,
                    name: ret.collect.adminUser.establishment.name,
                    documentNumber: ret.collect.adminUser.establishment.documentNumber,
                    phone: ret.collect.adminUser.establishment.phone,
                    logo: ret.collect.adminUser.establishment.logo === null || ret.collect.adminUser.establishment.logo === undefined ? null : {
                        thumb: {
                            width: ret.collect.adminUser.establishment.logo.thumb.width || 0,
                            height: ret.collect.adminUser.establishment.logo.thumb.height || 0,
                            url: ret.collect.adminUser.establishment.logo.thumb.url,
                        },
                        width: ret.collect.adminUser.establishment.logo.width || 0,
                        height: ret.collect.adminUser.establishment.logo.height || 0,
                        url: ret.collect.adminUser.establishment.logo.url,
                    },
                    image: ret.collect.adminUser.establishment.image === null || ret.collect.adminUser.establishment.image === undefined ? null : {
                        thumb: {
                            width: ret.collect.adminUser.establishment.image.thumb.width || 0,
                            height: ret.collect.adminUser.establishment.image.thumb.height || 0,
                            url: ret.collect.adminUser.establishment.image.thumb.url,
                        },
                        width: ret.collect.adminUser.establishment.image.width || 0,
                        height: ret.collect.adminUser.establishment.image.height || 0,
                        url: ret.collect.adminUser.establishment.image.url,
                    },
                    address: {
                        zipcode: ret.collect.adminUser.establishment.address.zipcode,
                        street: ret.collect.adminUser.establishment.address.street,
                        streetNumber: ret.collect.adminUser.establishment.address.streetNumber,
                        complementary: ret.collect.adminUser.establishment.address.complementary === null || ret.collect.adminUser.establishment.address.complementary === undefined ? null : ret.collect.adminUser.establishment.address.complementary,
                        neighborhood: ret.collect.adminUser.establishment.address.neighborhood,
                        city: ret.collect.adminUser.establishment.address.city,
                        state: ret.collect.adminUser.establishment.address.state,
                        countryCode: ret.collect.adminUser.establishment.address.countryCode,
                        latLng: {
                            lat: ret.collect.adminUser.establishment.address.latLng.lat,
                            lng: ret.collect.adminUser.establishment.address.latLng.lng,
                        },
                    },
                    segment: ret.collect.adminUser.establishment.segment,
                    showOnApp: !!ret.collect.adminUser.establishment.showOnApp,
                },
            },
            collectDate: ret.collect.collectDate === null || ret.collect.collectDate === undefined ? null : new Date(parseInt(ret.collect.collectDate.split("-")[0], 10), parseInt(ret.collect.collectDate.split("-")[1], 10) - 1, parseInt(ret.collect.collectDate.split("-")[2], 10)),
            turn: ret.collect.turn,
            status: ret.collect.status,
            description: ret.collect.description === null || ret.collect.description === undefined ? null : ret.collect.description,
            createdAt: new Date(parseInt(ret.collect.createdAt.split("-")[0], 10), parseInt(ret.collect.createdAt.split("-")[1], 10) - 1, parseInt(ret.collect.createdAt.split("-")[2], 10)),
            address: {
                zipcode: ret.collect.address.zipcode,
                street: ret.collect.address.street,
                streetNumber: ret.collect.address.streetNumber,
                complementary: ret.collect.address.complementary === null || ret.collect.address.complementary === undefined ? null : ret.collect.address.complementary,
                neighborhood: ret.collect.address.neighborhood,
                city: ret.collect.address.city,
                state: ret.collect.address.state,
                countryCode: ret.collect.address.countryCode,
                latLng: {
                    lat: ret.collect.address.latLng.lat,
                    lng: ret.collect.address.latLng.lng,
                },
            },
            recoveredCO2: ret.collect.recoveredCO2 || 0,
            cooperative: ret.collect.cooperative === null || ret.collect.cooperative === undefined ? null : {
                id: ret.collect.cooperative.id,
                name: ret.collect.cooperative.name,
                image: ret.collect.cooperative.image === null || ret.collect.cooperative.image === undefined ? null : {
                    thumb: {
                        width: ret.collect.cooperative.image.thumb.width || 0,
                        height: ret.collect.cooperative.image.thumb.height || 0,
                        url: ret.collect.cooperative.image.thumb.url,
                    },
                    width: ret.collect.cooperative.image.width || 0,
                    height: ret.collect.cooperative.image.height || 0,
                    url: ret.collect.cooperative.image.url,
                },
                email: ret.collect.cooperative.email,
                type: ret.collect.cooperative.type,
                createdAt: new Date(ret.collect.cooperative.createdAt + "Z"),
                legalUser: ret.collect.cooperative.legalUser === null || ret.collect.cooperative.legalUser === undefined ? null : {
                    id: ret.collect.cooperative.legalUser.id,
                    fantasyName: ret.collect.cooperative.legalUser.fantasyName,
                    documentNumber: ret.collect.cooperative.legalUser.documentNumber,
                    signatureId: ret.collect.cooperative.legalUser.signatureId === null || ret.collect.cooperative.legalUser.signatureId === undefined ? null : ret.collect.cooperative.legalUser.signatureId || 0,
                    subscriptionStatus: ret.collect.cooperative.legalUser.subscriptionStatus,
                },
                establishment: ret.collect.cooperative.establishment === null || ret.collect.cooperative.establishment === undefined ? null : {
                    id: ret.collect.cooperative.establishment.id,
                    name: ret.collect.cooperative.establishment.name,
                    documentNumber: ret.collect.cooperative.establishment.documentNumber,
                    phone: ret.collect.cooperative.establishment.phone,
                    logo: ret.collect.cooperative.establishment.logo === null || ret.collect.cooperative.establishment.logo === undefined ? null : {
                        thumb: {
                            width: ret.collect.cooperative.establishment.logo.thumb.width || 0,
                            height: ret.collect.cooperative.establishment.logo.thumb.height || 0,
                            url: ret.collect.cooperative.establishment.logo.thumb.url,
                        },
                        width: ret.collect.cooperative.establishment.logo.width || 0,
                        height: ret.collect.cooperative.establishment.logo.height || 0,
                        url: ret.collect.cooperative.establishment.logo.url,
                    },
                    image: ret.collect.cooperative.establishment.image === null || ret.collect.cooperative.establishment.image === undefined ? null : {
                        thumb: {
                            width: ret.collect.cooperative.establishment.image.thumb.width || 0,
                            height: ret.collect.cooperative.establishment.image.thumb.height || 0,
                            url: ret.collect.cooperative.establishment.image.thumb.url,
                        },
                        width: ret.collect.cooperative.establishment.image.width || 0,
                        height: ret.collect.cooperative.establishment.image.height || 0,
                        url: ret.collect.cooperative.establishment.image.url,
                    },
                    address: {
                        zipcode: ret.collect.cooperative.establishment.address.zipcode,
                        street: ret.collect.cooperative.establishment.address.street,
                        streetNumber: ret.collect.cooperative.establishment.address.streetNumber,
                        complementary: ret.collect.cooperative.establishment.address.complementary === null || ret.collect.cooperative.establishment.address.complementary === undefined ? null : ret.collect.cooperative.establishment.address.complementary,
                        neighborhood: ret.collect.cooperative.establishment.address.neighborhood,
                        city: ret.collect.cooperative.establishment.address.city,
                        state: ret.collect.cooperative.establishment.address.state,
                        countryCode: ret.collect.cooperative.establishment.address.countryCode,
                        latLng: {
                            lat: ret.collect.cooperative.establishment.address.latLng.lat,
                            lng: ret.collect.cooperative.establishment.address.latLng.lng,
                        },
                    },
                    segment: ret.collect.cooperative.establishment.segment,
                    showOnApp: !!ret.collect.cooperative.establishment.showOnApp,
                },
            },
        },
        id: ret.id,
        materialCode: ret.materialCode,
        category: ret.category,
        weight: ret.weight || 0,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function getAllMaterialsFromLegalUser(pageOffset: number, filter: FilterByPeriod, progress?: (progress: number) => void): Promise<MaterialWithCollect[]> {
    const args = {
        pageOffset: pageOffset || 0,
        filter: {
            startDate: filter.startDate === null || filter.startDate === undefined ? null : typeof(filter.startDate) === "string" ? new Date(new Date(filter.startDate).getTime() - new Date(filter.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startDate.getTime() - filter.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            endDate: filter.endDate === null || filter.endDate === undefined ? null : typeof(filter.endDate) === "string" ? new Date(new Date(filter.endDate).getTime() - new Date(filter.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endDate.getTime() - filter.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "getAllMaterialsFromLegalUser", args, progress});
    return ret.map((e: any) => ({
        collect: {
            id: e.collect.id,
            code: e.collect.code,
            adminUser: {
                id: e.collect.adminUser.id,
                name: e.collect.adminUser.name,
                image: e.collect.adminUser.image === null || e.collect.adminUser.image === undefined ? null : {
                    thumb: {
                        width: e.collect.adminUser.image.thumb.width || 0,
                        height: e.collect.adminUser.image.thumb.height || 0,
                        url: e.collect.adminUser.image.thumb.url,
                    },
                    width: e.collect.adminUser.image.width || 0,
                    height: e.collect.adminUser.image.height || 0,
                    url: e.collect.adminUser.image.url,
                },
                email: e.collect.adminUser.email,
                type: e.collect.adminUser.type,
                createdAt: new Date(e.collect.adminUser.createdAt + "Z"),
                legalUser: e.collect.adminUser.legalUser === null || e.collect.adminUser.legalUser === undefined ? null : {
                    id: e.collect.adminUser.legalUser.id,
                    fantasyName: e.collect.adminUser.legalUser.fantasyName,
                    documentNumber: e.collect.adminUser.legalUser.documentNumber,
                    signatureId: e.collect.adminUser.legalUser.signatureId === null || e.collect.adminUser.legalUser.signatureId === undefined ? null : e.collect.adminUser.legalUser.signatureId || 0,
                    subscriptionStatus: e.collect.adminUser.legalUser.subscriptionStatus,
                },
                establishment: e.collect.adminUser.establishment === null || e.collect.adminUser.establishment === undefined ? null : {
                    id: e.collect.adminUser.establishment.id,
                    name: e.collect.adminUser.establishment.name,
                    documentNumber: e.collect.adminUser.establishment.documentNumber,
                    phone: e.collect.adminUser.establishment.phone,
                    logo: e.collect.adminUser.establishment.logo === null || e.collect.adminUser.establishment.logo === undefined ? null : {
                        thumb: {
                            width: e.collect.adminUser.establishment.logo.thumb.width || 0,
                            height: e.collect.adminUser.establishment.logo.thumb.height || 0,
                            url: e.collect.adminUser.establishment.logo.thumb.url,
                        },
                        width: e.collect.adminUser.establishment.logo.width || 0,
                        height: e.collect.adminUser.establishment.logo.height || 0,
                        url: e.collect.adminUser.establishment.logo.url,
                    },
                    image: e.collect.adminUser.establishment.image === null || e.collect.adminUser.establishment.image === undefined ? null : {
                        thumb: {
                            width: e.collect.adminUser.establishment.image.thumb.width || 0,
                            height: e.collect.adminUser.establishment.image.thumb.height || 0,
                            url: e.collect.adminUser.establishment.image.thumb.url,
                        },
                        width: e.collect.adminUser.establishment.image.width || 0,
                        height: e.collect.adminUser.establishment.image.height || 0,
                        url: e.collect.adminUser.establishment.image.url,
                    },
                    address: {
                        zipcode: e.collect.adminUser.establishment.address.zipcode,
                        street: e.collect.adminUser.establishment.address.street,
                        streetNumber: e.collect.adminUser.establishment.address.streetNumber,
                        complementary: e.collect.adminUser.establishment.address.complementary === null || e.collect.adminUser.establishment.address.complementary === undefined ? null : e.collect.adminUser.establishment.address.complementary,
                        neighborhood: e.collect.adminUser.establishment.address.neighborhood,
                        city: e.collect.adminUser.establishment.address.city,
                        state: e.collect.adminUser.establishment.address.state,
                        countryCode: e.collect.adminUser.establishment.address.countryCode,
                        latLng: {
                            lat: e.collect.adminUser.establishment.address.latLng.lat,
                            lng: e.collect.adminUser.establishment.address.latLng.lng,
                        },
                    },
                    segment: e.collect.adminUser.establishment.segment,
                    showOnApp: !!e.collect.adminUser.establishment.showOnApp,
                },
            },
            collectDate: e.collect.collectDate === null || e.collect.collectDate === undefined ? null : new Date(parseInt(e.collect.collectDate.split("-")[0], 10), parseInt(e.collect.collectDate.split("-")[1], 10) - 1, parseInt(e.collect.collectDate.split("-")[2], 10)),
            turn: e.collect.turn,
            status: e.collect.status,
            description: e.collect.description === null || e.collect.description === undefined ? null : e.collect.description,
            createdAt: new Date(parseInt(e.collect.createdAt.split("-")[0], 10), parseInt(e.collect.createdAt.split("-")[1], 10) - 1, parseInt(e.collect.createdAt.split("-")[2], 10)),
            address: {
                zipcode: e.collect.address.zipcode,
                street: e.collect.address.street,
                streetNumber: e.collect.address.streetNumber,
                complementary: e.collect.address.complementary === null || e.collect.address.complementary === undefined ? null : e.collect.address.complementary,
                neighborhood: e.collect.address.neighborhood,
                city: e.collect.address.city,
                state: e.collect.address.state,
                countryCode: e.collect.address.countryCode,
                latLng: {
                    lat: e.collect.address.latLng.lat,
                    lng: e.collect.address.latLng.lng,
                },
            },
            recoveredCO2: e.collect.recoveredCO2 || 0,
            cooperative: e.collect.cooperative === null || e.collect.cooperative === undefined ? null : {
                id: e.collect.cooperative.id,
                name: e.collect.cooperative.name,
                image: e.collect.cooperative.image === null || e.collect.cooperative.image === undefined ? null : {
                    thumb: {
                        width: e.collect.cooperative.image.thumb.width || 0,
                        height: e.collect.cooperative.image.thumb.height || 0,
                        url: e.collect.cooperative.image.thumb.url,
                    },
                    width: e.collect.cooperative.image.width || 0,
                    height: e.collect.cooperative.image.height || 0,
                    url: e.collect.cooperative.image.url,
                },
                email: e.collect.cooperative.email,
                type: e.collect.cooperative.type,
                createdAt: new Date(e.collect.cooperative.createdAt + "Z"),
                legalUser: e.collect.cooperative.legalUser === null || e.collect.cooperative.legalUser === undefined ? null : {
                    id: e.collect.cooperative.legalUser.id,
                    fantasyName: e.collect.cooperative.legalUser.fantasyName,
                    documentNumber: e.collect.cooperative.legalUser.documentNumber,
                    signatureId: e.collect.cooperative.legalUser.signatureId === null || e.collect.cooperative.legalUser.signatureId === undefined ? null : e.collect.cooperative.legalUser.signatureId || 0,
                    subscriptionStatus: e.collect.cooperative.legalUser.subscriptionStatus,
                },
                establishment: e.collect.cooperative.establishment === null || e.collect.cooperative.establishment === undefined ? null : {
                    id: e.collect.cooperative.establishment.id,
                    name: e.collect.cooperative.establishment.name,
                    documentNumber: e.collect.cooperative.establishment.documentNumber,
                    phone: e.collect.cooperative.establishment.phone,
                    logo: e.collect.cooperative.establishment.logo === null || e.collect.cooperative.establishment.logo === undefined ? null : {
                        thumb: {
                            width: e.collect.cooperative.establishment.logo.thumb.width || 0,
                            height: e.collect.cooperative.establishment.logo.thumb.height || 0,
                            url: e.collect.cooperative.establishment.logo.thumb.url,
                        },
                        width: e.collect.cooperative.establishment.logo.width || 0,
                        height: e.collect.cooperative.establishment.logo.height || 0,
                        url: e.collect.cooperative.establishment.logo.url,
                    },
                    image: e.collect.cooperative.establishment.image === null || e.collect.cooperative.establishment.image === undefined ? null : {
                        thumb: {
                            width: e.collect.cooperative.establishment.image.thumb.width || 0,
                            height: e.collect.cooperative.establishment.image.thumb.height || 0,
                            url: e.collect.cooperative.establishment.image.thumb.url,
                        },
                        width: e.collect.cooperative.establishment.image.width || 0,
                        height: e.collect.cooperative.establishment.image.height || 0,
                        url: e.collect.cooperative.establishment.image.url,
                    },
                    address: {
                        zipcode: e.collect.cooperative.establishment.address.zipcode,
                        street: e.collect.cooperative.establishment.address.street,
                        streetNumber: e.collect.cooperative.establishment.address.streetNumber,
                        complementary: e.collect.cooperative.establishment.address.complementary === null || e.collect.cooperative.establishment.address.complementary === undefined ? null : e.collect.cooperative.establishment.address.complementary,
                        neighborhood: e.collect.cooperative.establishment.address.neighborhood,
                        city: e.collect.cooperative.establishment.address.city,
                        state: e.collect.cooperative.establishment.address.state,
                        countryCode: e.collect.cooperative.establishment.address.countryCode,
                        latLng: {
                            lat: e.collect.cooperative.establishment.address.latLng.lat,
                            lng: e.collect.cooperative.establishment.address.latLng.lng,
                        },
                    },
                    segment: e.collect.cooperative.establishment.segment,
                    showOnApp: !!e.collect.cooperative.establishment.showOnApp,
                },
            },
        },
        id: e.id,
        materialCode: e.materialCode,
        category: e.category,
        weight: e.weight || 0,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
    }));
}

export async function deleteMaterial(materialId: string, progress?: (progress: number) => void): Promise<Material> {
    const args = {
        materialId: materialId,
    };
    const ret = await makeRequest({name: "deleteMaterial", args, progress});
    return {
        id: ret.id,
        materialCode: ret.materialCode,
        category: ret.category,
        weight: ret.weight || 0,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function getAllMaterialsFromCollect(collectId: string, pageOffset: number, progress?: (progress: number) => void): Promise<Material[]> {
    const args = {
        collectId: collectId,
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllMaterialsFromCollect", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        materialCode: e.materialCode,
        category: e.category,
        weight: e.weight || 0,
        date: new Date(parseInt(e.date.split("-")[0], 10), parseInt(e.date.split("-")[1], 10) - 1, parseInt(e.date.split("-")[2], 10)),
    }));
}

export async function createMaterial(newMaterial: NewMaterial, progress?: (progress: number) => void): Promise<Material> {
    const args = {
        newMaterial: {
            category: newMaterial.category,
            weight: newMaterial.weight || 0,
            date: typeof(newMaterial.date) === "string" ? new Date(new Date(newMaterial.date).getTime() - new Date(newMaterial.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newMaterial.date.getTime() - newMaterial.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            collectId: newMaterial.collectId,
        },
    };
    const ret = await makeRequest({name: "createMaterial", args, progress});
    return {
        id: ret.id,
        materialCode: ret.materialCode,
        category: ret.category,
        weight: ret.weight || 0,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function editMaterial(materialId: string, newMaterial: EditMaterial, progress?: (progress: number) => void): Promise<Material> {
    const args = {
        materialId: materialId,
        newMaterial: {
            category: newMaterial.category,
            weight: newMaterial.weight || 0,
            date: typeof(newMaterial.date) === "string" ? new Date(new Date(newMaterial.date).getTime() - new Date(newMaterial.date).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(newMaterial.date.getTime() - newMaterial.date.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "editMaterial", args, progress});
    return {
        id: ret.id,
        materialCode: ret.materialCode,
        category: ret.category,
        weight: ret.weight || 0,
        date: new Date(parseInt(ret.date.split("-")[0], 10), parseInt(ret.date.split("-")[1], 10) - 1, parseInt(ret.date.split("-")[2], 10)),
    };
}

export async function getAllMaterialStatistics(filter: FilterByPeriodAndLegalUser, progress?: (progress: number) => void): Promise<MaterialStatistics> {
    const args = {
        filter: {
            adminUserId: filter.adminUserId === null || filter.adminUserId === undefined ? null : filter.adminUserId,
            startDate: filter.startDate === null || filter.startDate === undefined ? null : typeof(filter.startDate) === "string" ? new Date(new Date(filter.startDate).getTime() - new Date(filter.startDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.startDate.getTime() - filter.startDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
            endDate: filter.endDate === null || filter.endDate === undefined ? null : typeof(filter.endDate) === "string" ? new Date(new Date(filter.endDate).getTime() - new Date(filter.endDate).getTimezoneOffset() * 60000).toISOString().split("T")[0] : new Date(filter.endDate.getTime() - filter.endDate.getTimezoneOffset() * 60000).toISOString().split("T")[0],
        },
    };
    const ret = await makeRequest({name: "getAllMaterialStatistics", args, progress});
    return {
        Plastic: ret.Plastic || 0,
        PaperOrCardboard: ret.PaperOrCardboard || 0,
        Metal: ret.Metal || 0,
        Electronics: ret.Electronics || 0,
        BatteriesOrLamps: ret.BatteriesOrLamps || 0,
    };
}

export async function sendRequestResetPassword(email: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        email: email,
    };
    await makeRequest({name: "sendRequestResetPassword", args, progress});
    return undefined;
}

export async function validateToken(token: string, progress?: (progress: number) => void): Promise<boolean> {
    const args = {
        token: token,
    };
    const ret = await makeRequest({name: "validateToken", args, progress});
    return !!ret;
}

export async function resetPassword(token: string, newPassword: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
        newPassword: newPassword,
    };
    await makeRequest({name: "resetPassword", args, progress});
    return undefined;
}

export async function getUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "getUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function getAllUsers(pageOffset: number, progress?: (progress: number) => void): Promise<User[]> {
    const args = {
        pageOffset: pageOffset || 0,
    };
    const ret = await makeRequest({name: "getAllUsers", args, progress});
    return ret.map((e: any) => ({
        id: e.id,
        email: e.email,
        name: e.name,
        phone: e.phone,
        documentNumber: e.documentNumber === null || e.documentNumber === undefined ? null : e.documentNumber,
        image: e.image === null || e.image === undefined ? null : {
            thumb: {
                width: e.image.thumb.width || 0,
                height: e.image.thumb.height || 0,
                url: e.image.thumb.url,
            },
            width: e.image.width || 0,
            height: e.image.height || 0,
            url: e.image.url,
        },
        createdAt: new Date(e.createdAt + "Z"),
    }));
}

export async function deleteUser(id: string, progress?: (progress: number) => void): Promise<User> {
    const args = {
        id: id,
    };
    const ret = await makeRequest({name: "deleteUser", args, progress});
    return {
        id: ret.id,
        email: ret.email,
        name: ret.name,
        phone: ret.phone,
        documentNumber: ret.documentNumber === null || ret.documentNumber === undefined ? null : ret.documentNumber,
        image: ret.image === null || ret.image === undefined ? null : {
            thumb: {
                width: ret.image.thumb.width || 0,
                height: ret.image.thumb.height || 0,
                url: ret.image.thumb.url,
            },
            width: ret.image.width || 0,
            height: ret.image.height || 0,
            url: ret.image.url,
        },
        createdAt: new Date(ret.createdAt + "Z"),
    };
}

export async function ping(progress?: (progress: number) => void): Promise<string> {
    const ret = await makeRequest({name: "ping", args: {}, progress});
    return ret;
}

export async function setPushToken(token: string, progress?: (progress: number) => void): Promise<void> {
    const args = {
        token: token,
    };
    await makeRequest({name: "setPushToken", args, progress});
    return undefined;
}

//////////////////////////////////////////////////////

let fallbackDeviceId: string | null = null;

function setDeviceId(deviceId: string): void {
    fallbackDeviceId = deviceId;
    try {
        localStorage.setItem("deviceId", deviceId);
    } catch (e) {}
}

function getDeviceId(): string | null {
    try {
        return localStorage.getItem("deviceId") || fallbackDeviceId;
    } catch (e) {}

    return fallbackDeviceId;
}

async function device(): Promise<any> {
    const parser = new UAParser();
    parser.setUA(navigator.userAgent);
    const agent = parser.getResult();
    const me = document.currentScript as HTMLScriptElement;
    const device: any = {
            type: "web",
            platform: {
                browser: agent.browser.name,
                browserVersion: agent.browser.version,
                os: agent.os.name,
                osVersion: agent.os.version,
            },
            screen: {
                width: screen.width,
                height: screen.height,
            },
            version: me ? me.src : "",
            language: navigator.language,
    };

    const deviceId = getDeviceId();
    if (deviceId)
        device.id = deviceId;

    return device;
}

function randomBytesHex(len: number): string {
    let hex = "";
    for (let i = 0; i < 2 * len; ++i) {
        hex += "0123456789abcdef"[Math.floor(Math.random() * 16)];
    }

    return hex;
}

export interface ListenerTypes {
    fail: (e: Error, name: string, args: any) => void;
    fatal: (e: Error, name: string, args: any) => void;
    success: (res: string, name: string, args: any) => void;
}

// tslint:disable-next-line: ban-types
type HookArray = Function[];
export type Listenables = keyof ListenerTypes;
export type ListenersDict = { [k in Listenables]: Array<ListenerTypes[k]> };

const listenersDict: ListenersDict = {
    fail: [],
    fatal: [],
    success: [],
};

export function addEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listeners.push(hook);
}

export function removeEventListener(listenable: Listenables, hook: ListenerTypes[typeof listenable]): void {
    const listeners: HookArray = listenersDict[listenable];
    listenersDict[listenable] = listeners.filter((h) => h !== hook) as any;
}

async function makeRequest({name, args, progress}: {name: string, args: any, progress?: (progress: number) => void}): Promise<any> {
    const deviceData = await device();
    return new Promise<any>((resolve, reject) => {
        const req = new XMLHttpRequest();
        req.open(
            "POST",
            `${baseUrl.startsWith("http") || baseUrl.startsWith("localhost") ?
                "" :
                "https://"
            }${baseUrl}/${name}`,
        );

        const body = {
            id: randomBytesHex(8),
            device: deviceData,
            name: name,
            args: args,
        };

        function roughSizeOfObject(object: any): number {
            const objectList: any = [];
            const stack: any = [ object ];
            let bytes = 0;

            while (stack.length) {
                const value = stack.pop();
                if (typeof value === "boolean") {
                    bytes += 4;
                } else if (typeof value === "string") {
                    bytes += value.length * 2;
                } else if (typeof value === "number") {
                    bytes += 8;
                } else if (
                    typeof value === "object"
                    && objectList.indexOf(value) === -1
                ) {
                    objectList.push(value);
                    for (const i in value) {
                        stack.push(value[i]);
                    }
                }
            }

            return bytes;
        }

        req.upload.onprogress = (event: ProgressEvent) => {
            if (event.lengthComputable && progress) {
                progress(Math.ceil(((event.loaded) / event.total) * 100));
            }
        };

        req.onreadystatechange = () => {
            if (req.readyState !== 4) return;
            try {
                const response = JSON.parse(req.responseText);

                try {
                    setDeviceId(response.deviceId);

                    if (response.ok) {
                        resolve(response.result);
                        listenersDict["success"].forEach((hook) => hook(response.result, name, args));
                    } else {
                        const error = typeof response.error === "object" ?
                            response.error :
                            { type: "Fatal", message: response.toString() };

                        reject(error);

                        listenersDict["fail"].forEach((hook) => hook(error, name, args));
                    }
                } catch (e) {
                    console.error(e);
                    reject({type: "Fatal", message: `[${name}] ${e.toString()}`});

                    listenersDict["fatal"].forEach((hook) => hook(e, name, args));
                }
            } catch (e) {
                console.error(e);
                reject({ type: "BadFormattedResponse", message: `Response couldn't be parsed as JSON (${req.responseText}):\n${e.toString()}` });
                listenersDict["fatal"].forEach((hook) => hook(e, name, args));
            }
        };

        req.send(JSON.stringify(body));
    });
}
