import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, Heading, FlexProps } from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";

import { Card, Fetchable, EmptyList } from "~/components";
import strings from "~/resources/strings";
import { colors } from "~/themes/colors";

interface IProps extends FlexProps {
	label: string;
	graphicLabel: string[];
	barLabel?: string;
	data?: number[];
	loading?: boolean;
	hasError?: boolean;
}

export const Graphic: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.components.graphic;
	const {
		label,
		graphicLabel,
		barLabel,
		data,
		loading,
		hasError,
		...rest
	} = props;

	return (
		<Flex
			w="100%"
			flexDir="column"
			{...rest}
		>
			<Card
				d="initial"
				w={{base: "100%", xl: "70%"}}
				my={0}
				mx="auto"
				p={{base:2, sm: 5}}
			>
				<Fetchable loading={loading}>
					{
						!hasError
							? (
								<>
									<Heading
										size="md"
										textAlign="center"
										mb={5}
									>
										{label}
									</Heading>
									<Bar
										data={{
											labels: graphicLabel,
											datasets: [
												{
													label: barLabel || "",
													type: "bar",
													data,
													backgroundColor: colors.secondary[500],
												},
											],
										}}
									/>
								</>
							)
							: <EmptyList text={componentStrings.error} />
					}
				</Fetchable>
			</Card>
		</Flex>
	);
});
