import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, Button, VStack, useToast } from "@chakra-ui/react";
import {
	Card,
	TextInput,
	Logo,
} from "../../components";
import strings from "../../resources/strings";
import { useGlobalStore } from "../../contexts/useGlobalContext";
import { useHistory } from "react-router-dom";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import { NavbarPWA } from "~/layout/index";

const Login: React.FC = () => {
	const pageStrings = strings.login;
	const toast = useToast();
	const { authStore } = useGlobalStore();
	const history = useHistory();

	const onSuccess = (userName: string) => {
		toast({ status: "success", title: pageStrings.success(userName) });
		history.replace("/");
	};

	const onError = (errorMessage: string) => {
		toast({ status: "error", title: errorMessage });
	};

	const login = () => {
		authStore.login(onSuccess, onError);
	};

	const toRecovery = () => {
		history.push("/recovery");
	};

	useAuthRoute(() => history.push("/dashboard"));

	return (
		<Flex flexDirection="column">
			<NavbarPWA />
			<Flex
				justifyContent="center"
				alignItems="center"
				w="100%"
				minH="100vh"
				bg="primary.800"
			>
				<Card
					maxW={{ base: "100vw", sm: "25rem" }}
					minH={{ base: "100vh", sm: "30rem" }}
					rounded={{ base: 0, sm: 10 }}
					w="100%"
				>
					<VStack spacing="8" w="100%">
						<Logo />
						<TextInput
							labelText={pageStrings.fields.email}
							type="email"
							isDisabled={authStore.loader.isLoading}
							{...authStore.form.field("email")}
						/>
						<TextInput
							labelText={pageStrings.fields.password}
							type="password"
							isDisabled={authStore.loader.isLoading}
							{...authStore.form.field("password")}
						/>
						<Button
							w="12rem"
							onClick={login}
							isLoading={authStore.loader.isLoading}
						>
							{pageStrings.loginIn}
						</Button>

						<Button
							colorScheme="primary"
							variant="link"
							onClick={toRecovery}
						>
							{pageStrings.recoveryPassword}
						</Button>
					</VStack>
				</Card>
			</Flex>
		</Flex>

	);
};

export default observer(Login);
