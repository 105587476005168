import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Button,
	Flex,
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";

import {
	Table,
	TableCellWithActionButtons,
} from "../../../../components";
import strings from "../../../../resources/strings";
import { useGlobalStore } from "../../../../contexts/useGlobalContext";
import Store from "./store";
import API from "../../../../resources/api";
import { useHistory } from "react-router-dom";

const TableView: React.FC = () => {
	const pageStrings = strings.cooperativeAdminUsers;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onDelete = (id: string) => {
		store.deleteCoperativeUser(id);
		dialog.closeDialog();
	};

	const onGoToEdit = (id: string) => history.push(`cooperativeAdminUsers/edit/${id}`);
	const onGoToDetails = (id: string) => history.push(`cooperativeAdminUsers/details/${id}`);
	const onGoToCreate = () => history.push("cooperativeAdminUsers/create");

	const openDialog = (user: API.AdminUser) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(user.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDelete(user.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex direction="column" p={{ base: "2", lg: "16" }}>
			<Table
				data={store._items}
				tableTitle={pageStrings.table.title}
				headers={pageStrings.table.header}
				hasNextPage={store._isHaveNextPage}
				renderRow={(item) => (
					<Tr key={item.id}>
						<Td>
							<Text>{item.name}</Text>
						</Td>
						<Td>
							<Text>{item.email}</Text>
						</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEdit(item.id)}
							onDelete={() => openDialog(item)}
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
			/>
			<Button
				mx="auto"
				onClick={onGoToCreate}
				mt={10}
				w={{
					md: "30%",
					base: "80%",
				}}
			>
				{pageStrings.table.create}
			</Button>
		</Flex>
	);
};

export default observer(TableView);
