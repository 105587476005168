import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import EditUser from "./EditUser";

const Users: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/users" component={Table} />
		<Route path="/dashboard/users/edit/:id" component={EditUser} />
	</Switch>
);

export default observer(Users);
