import { action, makeObservable, observable, reaction } from "mobx";
import {
	LoaderShelf,
	AbstractPaginatedListStore,
	AttributeShelf,
} from "@startapp/mobx-utils";

import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import SimpleStateShelf from "~/shelves/SimpleStateShelf";
import { Errors } from "~/resources/errors";
import ListShelf from "~/shelves/ListShelf";

export default class Store extends AbstractPaginatedListStore<api.MaterialWithCollect> {

	public loader = new LoaderShelf();
	public statisticsLoader = new LoaderShelf();
	public statistics = new SimpleStateShelf<api.MaterialStatistics | null>(null);
	public isLegalUser = false;
	public startDate: AttributeShelf<Date | null>;
	public searchLegalUser = new AttributeShelf("");
	public legalUser: AttributeShelf<api.AdminUser | null> = new AttributeShelf(null);
	public autoCompleteLegalUser = new ListShelf(
		async (page: number) => await api.autoCompleteLegalUser(page, this.searchLegalUser.value),
	);
	public finalDate: AttributeShelf<Date | null>;

	constructor() {
		super();
		this.startDate = new AttributeShelf(null, () => this.fetchPage(0));
		this.finalDate = new AttributeShelf(null, () => this.fetchPage(0));
		this.legalUser = new AttributeShelf(null, () => this.fetchPage(0));
		makeObservable(this, {
			isLegalUser: observable,
			setLegalUser: action,
		});
	}

	private autoCompleteReaction = reaction(() => this.searchLegalUser.value,
		() => this.autoCompleteLegalUser.refresh(),
	);

	public get filterElements(): api.FilterByPeriodAndLegalUser {
		return {
			startDate: this.startDate.value,
			endDate: this.finalDate.value,
			adminUserId: this.legalUser.value ? this.legalUser.value.id : null,
		};
	}

	public setLegalUser = (isLegalUser: boolean) => {
		this.isLegalUser = isLegalUser;

		this.verifyIfIsSuperAdmin(isLegalUser);

		this.fetchPage(0);
	};

	public verifyIfIsSuperAdmin = (isLegalUser: boolean) => {
		if (!isLegalUser) {
			this.autoCompleteLegalUser.fetchPage(0);
		}
	};

	protected getDataItemsPerPage(page: number): Promise<api.MaterialWithCollect[]> {

		this.getStatistics();
		if (this.isLegalUser) {
			return api.getAllMaterialsFromLegalUser(page, this.filterElements);
		}
		return api.getAllMaterials(page, this.filterElements);
	}

	public onClean = () => {
		this.legalUser.setValue(null);
	};

	public deleteMaterial = async (id: string) => {
		this.loader.tryStart();
		try {
			await api.deleteMaterial(id);
			showSuccessToast(strings.materialsPage.onDelete);
			this.refresh();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public getStatistics = async () => {
		this.statisticsLoader.tryStart();
		try {
			const statistics = await api.getAllMaterialStatistics(this.filterElements);
			this.statistics.setState(statistics);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.statisticsLoader.end();
		}
	};

	public get labelStatistics() {
		return [
			api.MaterialCategory.Plastic,
			api.MaterialCategory.Metal,
			api.MaterialCategory.PaperOrCardboard,
			api.MaterialCategory.Electronics,
			api.MaterialCategory.BatteriesOrLamps,
		].map((category) => api.translateMaterialCategory(category));
	}

	public get dataStatistics() {
		const statistics = this.statistics.state;
		if (!statistics) {
			return;
		}
		return [
			statistics.Plastic,
			statistics.Metal,
			statistics.PaperOrCardboard,
			statistics.Electronics,
			statistics.BatteriesOrLamps,
		];
	}
}
