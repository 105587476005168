import { makeAutoObservable } from "mobx";
import { LoaderShelf } from "@startapp/mobx-utils";

export default class PaginatedListStore<ItemType> {

	public loader = new LoaderShelf();
	public page = 0;
	public items: ItemType[] = [];
	public error: string | null = null;
	public hasNextPage = false;

	private readonly getItems: (page: number) => Promise<ItemType[]>;

	constructor(getItems: (page: number) => Promise<ItemType[]>) {
		this.getItems = getItems;
		makeAutoObservable(this);
	}

	public fetchPage = async (page: number) => {
		if (page < 0) {
			return;
		}

		try {

			if (this.loader.isLoading) {
				throw new Error("Cannot fetch again. The last fetch still loading.");
			}

			this.loader.start();

			const newItems: ItemType[] = await this.getItems(page);

			this.afterFetch(newItems, page);
		} catch (e) {
			this.setError(e);
			throw e;
		} finally {
			this.loader.end();
		}
	};

	public afterFetch = (newItems: ItemType[], page: number) => {
		if (newItems.length > 0) {

			this.items = newItems;
			this.page = page;
			this.hasNextPage = true;
		} else {
			this.hasNextPage = false;
		}
	};

	public refresh = () => {
		this.fetchPage(this.page);
	};

	public clear = () => {
		this.items = [];
		this.error = null;
	};

	public setError = (error: any) => {
		this.error = error.message || "error";
	};

	public nextPage = async () => await this.fetchPage(this.page + 1);

	public previousPage = async () => await this.fetchPage(this.page - 1);

}
