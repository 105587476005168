import React from "react";
import { observer } from "mobx-react-lite";
import { Text, Box, Button, VStack } from "@chakra-ui/react";

import {
	Modal,
	IModalProps,
} from "~/components";
import strings from "~/resources/strings";

export interface IProps extends IModalProps{
	onPayWithCreditCard?: () => void;
	onPayWithBoleto?: () => void;
	loading?: boolean;
}

export const PaymentMethodModal: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.components.paymentMethod;
	const { onPayWithBoleto, onPayWithCreditCard, loading } = props;

	return (
		<Modal title={componentStrings.title} {...props}>
			<VStack spacing={8}>
				<Box bg="gray.100" rounded="md" w="75%">
					<Text
						mx={4}
						my={2}
						fontSize="md"
						fontWeight="bold"
						textColor="gray.800"
						textAlign="center"
					>
						{componentStrings.creditCard}
					</Text>
					<Button
						w="100%"
						onClick={onPayWithCreditCard}
						isLoading={loading}
					>
						{strings.actions.pay}
					</Button>
				</Box>

				<Box bg="gray.100" rounded="md" w="75%">
					<Text
						mx={4}
						my={2}
						fontSize="md"
						fontWeight="bold"
						textColor="gray.800"
						textAlign="center"
					>
						{componentStrings.boleto}
					</Text>
					<Button
						w="100%"
						onClick={onPayWithBoleto}
						isLoading={loading}
					>
						{strings.actions.pay}
					</Button>
				</Box>
			</VStack>
		</Modal>
	);
});
