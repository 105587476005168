import { makeAutoObservable, reaction } from "mobx";
import { LoaderShelf, AttributeShelf, FormShelf } from "@startapp/mobx-utils";

import { Errors } from "~/resources/errors";
import strings from "~/resources/strings";
import ListShelf from "~/shelves/ListShelf";
import api from "~/resources/api";
import { showErrorToast } from "~/resources/toast";

export default class Store {

	public formShelf = new FormShelf({
		description: "",
	});

	public collectDate = new AttributeShelf(new Date());
	public status = new AttributeShelf(api.CollectStatus.scheduled);
	public turn = new AttributeShelf(api.Turn.morning);
	public establishment: AttributeShelf<api.Establishment | null> = new AttributeShelf(null);
	public cooperativeId = new AttributeShelf<string>("");
	public searchCooperative = new AttributeShelf<string>("");
	public modalCooperative = new AttributeShelf<boolean>(false);

	public searchEstablishment = new AttributeShelf("");

	public autoCompleteEstablishment = new ListShelf(
		async (page: number) => await api.autoCompleteEstablishment(page, this.searchEstablishment.value),
	);

	public loader = new LoaderShelf();

	public id?: string;

	private autoCompleteEstablishmentReaction = reaction(() => this.searchEstablishment.value,
		() => this.autoCompleteEstablishment.refresh(),
	);

	private autoCompleteCooperativeReaction = reaction(() => this.searchCooperative.value,
		() => this.autoCompleteCooperative.refresh(),
	);

	constructor(id?: string) {
		makeAutoObservable(this);
		if (id) {
			this.id = id;
			this.getCollect(id);
		}
		this.autoCompleteCooperative.fetchPage(0);
		this.autoCompleteEstablishment.fetchPage(0);
	}
	public autoCompleteCooperative = new ListShelf(
		async (page: number) => await api.autoCompleteCooperative(page, this.searchCooperative.value),
	);

	public dispose = () => {
		this.autoCompleteEstablishmentReaction();
		this.autoCompleteCooperativeReaction();
	};

	public getCollect = async (id: string) => {
		this.loader.tryStart();
		try {
			const collect = await api.getCollect(id);
			this.setInitValues(collect);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public addCooperativeToCollect = async () => {
		this.loader.tryStart();
		try {
			await api.addCooperativeToCollect(this.id || "", this.cooperativeId.value);
		} catch (e) {
			Errors.handleError(e);
		} finally {
			this.loader.end();
		}
	};

	public setInitValues = (collect: api.Collect) => {
		this.formShelf = new FormShelf({
			description: collect.description || "",
		});
		this.turn.setValue(collect.turn);
		if (collect.collectDate) {
			this.collectDate.setValue(collect.collectDate);
		}
		this.status.setValue(collect.status);
		this.establishment.setValue(collect.adminUser.establishment);
		this.searchCooperative.setValue(collect.cooperative?.name || "");
	};

	public postPromise = async (promiseMethod: () => Promise<api.Collect>, onSuccess: () => void, onError?: (e: any) => void) => {
		this.loader.tryStart();
		try {

			await promiseMethod();
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			if (onError) {
				onError(e);
			}
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public get communCollectorProps() {
		const data = this.formShelf.getValues();
		return {
			status: this.status.value,
			turn: this.turn.value,
			description: data.description === "" ? null : data.description,
			collectDate: this.collectDate.value,
		};
	}

	public createCollect = (
		adminUser: api.AdminUser | null,
		onSuccess: () => void,
		onError?: (e: any) => void,
	) => {
		const newCollectOrder: api.NewCollectOrder = {
			...this.communCollectorProps,
		};
		const create = () => api.createNewCollectOrder(newCollectOrder);

		this.postPromise(create, onSuccess, onError);
	};

	public editCollect = (onSuccess: () => void) => {
		const id = this.id;
		if (!id) {
			showErrorToast(strings.error.default);
			return;
		}

		const editCollectOrder: api.EditCollectOrder = {
			...this.communCollectorProps,
		};

		const edit = () => api.editCollectOrder(id, editCollectOrder);

		this.postPromise(edit, onSuccess);
	};


	public createCollectForAdminUser = (onSuccess: () => void) => {
		const establishment = this.establishment.value;
		if (!establishment) {
			showErrorToast(strings.collects.error.selectEstablishment);
			return;
		}

		const newCollectOrder: api.NewCollectOrderWithStatus = {
			...this.communCollectorProps,
			recoveredCO2: 0,
		};

		const createForAdminUser = () => api.createCollectForAdminUser(newCollectOrder, establishment.id);
		this.postPromise(createForAdminUser, onSuccess);
	};
}
