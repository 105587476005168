import "moment/locale/pt-br";
import LocalizedStrings from "localized-strings";
import format from "./format";
import moment from "moment";

// MARK: Implementation
const strings = new LocalizedStrings({
	ptBR: {
		enum: {
			StateUF: {
				AC: "Acre",
				AL: "Alagoas",
				AP: "Amapá",
				AM: "Amazonas",
				BA: "Bahia",
				CE: "Ceará",
				DF: "Distrito Federal",
				ES: "Espírito Santo",
				GO: "Goiás",
				MA: "Maranhão",
				MT: "Mato Grosso",
				MS: "Mato Grosso do Sul",
				MG: "Minas Gerais",
				PA: "Pará",
				PB: "Paraíba",
				PR: "Paraná",
				PE: "Pernambuco",
				PI: "Piauí",
				RJ: "Rio de Janeiro",
				RN: "Rio Grande do Norte",
				RS: "Rio Grande do Sul",
				RO: "Rondônia",
				RR: "Roraima",
				SC: "Santa Catarina",
				SP: "São Paulo",
				SE: "Sergipe",
				TO: "Tocantins",
			},

			MaterialCategory: {
				Plastic: "Plástico",
				PaperOrCardboard: "Papel ou Papelão",
				Metal: "Metal",
				Electronics: "Eletrônicos",
				BatteriesOrLamps: "Vidro",
			},
			Segment: {
				retail: "Varejo",
				clothes: "Roupas",
				vegan: "Vegano",
				drugstore: "Farmácia",
				bakery: "Padaria",
				hotels: "Hotéis",
				organic: "Orgânicos",
				market: "Mercados",
				restaurant: "Restaurantes",
				bar: "Bares",
				snackShop: "Lanches",
				coffeeShop: "Cafeterias",
				ice: "Gelados",
				shoeStore: "Calçados",
				toyStore: "Brinquedos",
				pizzeria: "Pizzas",
				cosmeticStore: "Cosméticos",
				healthBranch: "Saúde",
				leisure: "Outros",
			},
			Weekday: {
				Sunday: "Domingo",
				Monday: "Segunda-feira",
				Tuesday: "Terça-feira",
				Wednesday: "Quarta-feira",
				Thursday: "Quinta-feira",
				Friday: "Sexta-feira",
				Saturday: "Sábado",
			},
			Turn: {
				morning: "Matutino",
				evening: "Vespertino",
			},
			CollectStatus: {
				scheduled: "Agendado",
				cancelled: "Cancelado",
				waitingPayment: "Processando",
				done: "Feito",
			},
			SignatureStatus: {
				NoPaid: "Não Pago",
				Paid: "Pago",
			},
			Plan: {
				GreenSeal: "Selo Ciclik",
				EcoPoint: "Selo Ecoponto",
			},
			SubscriptionStatus: {
				trialing: "Experimentando",
				paid: "Pago",
				pendingPayment: "Pagamento pendente",
				unpaid: "Nenhum pagamento",
				canceled: "Cancelado",
				ended: "Finalizou",
			},
			TransactionStatus: {
				processing: "Processando",
				authorized: "Autorizado",
				paid: "Pago",
				refunded: "Reembolsado",
				waitingPayment: "Esperando Pagamento",
				pendingRefund: "Reembolso pendente",
				refused: "Recusado",
				chargedback: "Cobrado de volta",
			},
			PaymentMethods: {
				creditCard: "creditCard",
				boleto: "boleto",
			},
		},
		adminUser: {
			success: "Editado com sucesso",
		},
		routeNames: {
			home: "Home",
			ciclik: "Ciclik",
			login: "Acessar minha conta",
			logout: "Sair",
		},
		components: {
			imagePicker: {
				selectAnImage: "Selecione uma imagem",
			},
			radioBoolean:{
				label: "Mostrar no App?",
			},
			userInfoCard: {
				supplier: "Tester",
				code: "Código",
				email: "E-mail",
			},
			autoCompleteModal: {
				back: "Voltar",
			},
			modalPayment: {
				title: "Pagamento sendo processado",
				description: "Aguarde um pouco, seu pagamento está sendo processado e pode levar alguns minutos para atualizar o status do pagamento",
			},
			establishmentCard: {
				segment: "Segmento:",
			},
			autoCompleteList: {
				empty: "Não há itens na lista",
			},
			graphic: {
				error: "Houve um erro, e não foi possível gerar o gráfico",
			},
			paymentCard: {
				button: "Pagar",
				signaureStatus: {
					paid: "Assinatura Ativa",
					noPaid: "Assinatura Irregular",
					trilling: "Testando Assinatura",
					canceled: "Assinatura Cancelada",
					pendingPayment: "Pagamento Pendente",
				},
			},
			paymentMethod: {
				title: "Pagamento",
				creditCard: "Cartão de credito",
				boleto: "Boleto",
			},
			checkinPeriodFilter: {
				filterDate: "Filtrar por data",
				from: "De:",
				until: "Até:",
			},
		},
		nav: {
			home: "Inicio",
			createCollect: "Criar Agendamento",
			collects: "Agendamentos de Coleta",
			materials: "Movimentação de Recicláveis",
			cooperativeAdminUsers: "Agentes Ciclik",
			customer: "Clientes",
			checkIns: "Check-ins",
			deleteRequests: "Solicitações de exclusão",
			legalUser: "Usuários PJ / PF",
			createEstablishment: "Cadastrar Estabelecimento",
			establishment: "Meu Cadastro",
			signature: "Área De Assinante",
			logout: "Sair",
			close: "Fechar",
		},
		error: {
			stillLoading: "Ainda Carregando, aguarde.",
			notLoggedIn: "Usuario não logado",
			default: "Houve um erro, tente novamente mais tarde.",
		},
		moment: {
			day: "DD",
			dateMonth: "DD/MM",
			date: "DD/MM/YYYY",
			dateHour: "DD/MM/YYYY HH[h]",
			dateTime: "DD/MM/YYYY HH:mm",
		},
		actions: {
			save: "Salvar",
			delete: "Apagar",
			create: "Criar",
			cancel: "Cancelar",
			select: "Selecionar",
			back: "Voltar",
			add: "Criar",
			exportCsv: "Exportar CSV",
			pay: "Pagar",
			purchase: "Comprar",
			confirm: "Confirmar",
		},
		common: {
			noResutls: "Sem Resultados",
			no: "Não",
			yes: "Sim",
			delete: "Clique para deletar",
			approve: "Clique para aprova",
			details: "Clique para ver mais informações",
			edit: "Clique para editar",
			cancel: "Cancelar",
			clear: "Limpar",
			confirm: (isEdit?: boolean) => isEdit ? "Salvar" : "Adicionar",
			modal: {
				title: "Deletar",
				description: (itemName: string) => `Tem certeza que deseja deletar ${itemName}?`,
				button: {
					delete: "Deletar",
					cancel: "Cancelar",
				},
			},
		},
		home: {
			welcome: "Bem vindo!",
		},
		login: {
			loginIn: "Login",
			recoveryPassword: "Recuperar Senha",
			register: "Ainda não tem login? Cadastre-se aqui",
			success: (userName: string) => `Bem vindo ${userName}.`,
			fields: {
				email: "E-mail",
				password: "Senha",
			},
		},
		users: {
			table: {
				title: "Lista de Clientes",
				header: ["Cliente", "E-mail", "Phone"],
				delete: (userName: string) => `Usuário ${userName} foi deletado!`,
			},
			edit: {
				title: "Editar Cliente",
				success: "Cliente editado com sucesso",
				fields: {
					admin: "Cliente",
					name: "Nome",
					email: "E-mail",
					phone: "Celular",
					password: "Senha",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Salvar" : "Adicionar",
				backButton: "Voltar",
			},
		},
		collects: {
			table: {
				title: "Lista de Coletas",
				header: ["Ordem de Coleta", "Estabelecimento", "Status", "Turno", "Data", "Agente Ciclik"],
				schedule: "Agendar",
			},
			error: {
				selectEstablishment: "É obrigatório selecionar um estabelecimento",
			},
		},
		deleteRequests: {
			table: {
				title: "Lista de Solicitações de exclusão",
				header: ["Usuário", "E-mail", "Data da solicitação"],
				approve: (userName: string) => `Solicitação do usuário ${userName} foi aprovada!`,
			},
			modal: {
				title: "Aprovar",
				description: "Deseja aprovar esta solicitação de exclusão de conta?",
				button: {
					aprove: "Aprovar",
					cancel: "Cancelar",
				},
			},
		},
		materials: {
			details: {
				title: "Material",
				fields: {
					code: "Código",
					category: "Categoria",
					date: "Data",
					weight: "Peso",
					collectOrder: "Ordem de coleta",
				},
				weight: (value: number) => `${value} kg`,
			},
			table: {
				title: "Entradas de Materiais",
				legalUserSearch: "Pesquisar usuários jurídicos",
				header: ["ID", "Material", "Data", "Peso", "Ordem de coleta"],
				graphic: {
					title: "Material x Peso (Kg)",
					kg: "Kg",
				},
				delete: (userName: string) => `Usuário PJ ${userName} foi deletado!`,
			},
		},
		legalUsers: {
			table: {
				title: "Lista de Usuários PJ / PF",
				header: ["Usuário PJ / PF", "E-mail", "Nome / Nome Fantasia", "CPF / CNPJ", "Status de Pagamento "],
				delete: (userName: string) => `Usuário PJ ${userName} foi deletado!`,
			},
			edit: {
				title:  {
					cpf: "Editar Usuário PF",
					cnpj: "Editar Usuário PJ",
				},
				titleDetails: {
					cpf: "Usuário PF",
					cnpj: "Usuário PJ",
				},
				establishmentDetails: "Estabelecimento",
				establishmentAddress: "Endereço do Estabelecimento",
				establishmentImages: "Logo e Banner do Estabelecimento",
				success: "Usuários PJ editado com sucesso",
				banner: "Banner",
				logo: "Logo",
				fields: {
					pf: "Usuário PF",
					pj: "Usuário PJ",
					admin: "Cliente",
					name: "Nome",
					userName: {
						cpf: "Nome",
						cnpj: "Nome Fantasia",
					},
					email: "E-mail",
					document:  {
						cpf: "CPF",
						cnpj: "CNPJ",
					},
					password: "Senha",
				},
				confirmButton: (isEdit?: boolean) => isEdit ? "Salvar" : "Adicionar",
				backButton: "Voltar",
				editorEstablishment: (hasId: boolean) => hasId ? "Editar Estabelecimento" : "Criar Estabelecimento",
				editorLegalUsers: {
					cpf: "Editar Usuário PF",
					cnpj: "Editar Usuário PJ",
				},
			},
		},
		cooperativeAdminUsers: {
			title: (isEdit?: boolean) => isEdit ? "Editar" : "Criar",
			fields: {
				fantasyName: "Nome Fantasia: ",
				documentNumber: "CPF/CNPJ: ",
				name: "Nome: ",
				email: "Email: ",
				password: "Senha: ",
				type: "Tipo: ",
			},
			details: {
				title: "Detalhes",
			},
			table: {
				title: "Lista de Agentes Ciclik",
				header: ["Nome", "E-mail", ""],
				delete: "Deletado com sucesso!",
				create: "Criar Agente Ciclik",
			},
			editOrEdit: {
				title: "Editar",
				success: (id: string) => id ? "Usuário editado com sucesso!" : "Usuário criado com sucesso!",
			},
		},
		establishment: {
			edit: {
				title: (hasId: boolean) => hasId ? "Editar Estabelecimento" : "Criar Estabelecimento",
				titleDetails: "Estabelecimentos Estabelecimento",
				success: (value: boolean) => value ? "Estabelecimento editado com sucesso" : "Estabelecimento criado com sucesso",
				fields: {
					name: "Nome",
					phone: "Celular",
					zipcode: "CEP",
					searchAddress: "Pesquise pelo nome do estabelecimento (Caso não encontre, colocar CEP)",
					street: "Rua",
					logo: "Logo (1280 × 720)",
					imagem: "Imagem (1280 × 720)",
					streetNumber: "Número",
					complementary: "Complemento",
					neighborhood: "Bairro",
					lat: "Latitude",
					lng: "Longitude",
					documentNumber: "CNPJ",
					city: "Cidade",
					state: "Estado",
					segment: "Segmento",
					imageTypes: "( .jpeg ou .png )",
				},
				confirmButton: (isEdit: boolean) => isEdit ? "Salvar" : "Adicionar",
				backButton: "Voltar",
			},
		},

		imagePicker: {
			addImagePlaceholder: "Clique em adicionar para escolher uma imagem",
			addImagesPlaceholder:
				"Clique em adicionar para escolher uma ou mais imagens",
			addFilePlaceholder: "Clique em adicionar para escolher um arquivo",
			addFilesPlaceholder:
				"Clique em adicionar para escolher um ou mais arquivos",
			addImageBtn: "Adicionar",
			missingImage: "A imagem é obrigatória",
		},
		recoveryPage: {
			success: "Bem vindo!",
			title: "Recuperação de Senha",
			newAccess: "Novo Acesso",
			sendSuccess: "E-mail enviado com sucesso!",
			recoverPasswordButton: "Enviar e-mail",
			newPasswordButton: "Alterar Senha",
			backButton: "Voltar",
			email: "Email",
			token: "Código",
			validToken: "Código válido!",
			confirmTokenButton: "Confirmar Código",
			tokenButton: "Já possui um código?",
			noTokenButton: "Não possuo código",
			newPassword: "Nova senha",
			confirmNewPassword: "Confirme sua nova senha",
			samePasswordError: "As senhas não correspondem",
			helperEmailText: "Enviaremos um código para seu email cadastrado",
		},
		collectPage: {
			success: (isSuperAdmin?: boolean, isEditForm?: boolean) => {
				const createSuccess = `Coleta foi ${isSuperAdmin ? "criada" : "agendada"}`;
				return isEditForm ? "Coleta modificada" : createSuccess;
			},
			title: (isSuperAdmin?: boolean, isEditForm?: boolean) => {
				const createTitle = isSuperAdmin ? "Criar Coleta" : "Agendamento de Coleta";
				return isEditForm ? "Modificar Coleta" : createTitle;
			},
			back: "Voltar",
			confirmButton: (isSuperAdmin?: boolean, isEditForm?: boolean) => {
				const createButton = isSuperAdmin ? "Criar" : "Agendar";
				return isEditForm ? "Editar" : createButton;
			},
			erroEstablishment: "Usuário não possui estabelecimento cadastrado ainda",
			fields: {
				description: "Observações (Opcional)",
				descriptionView: "Observações",
				owner: "Responsável",
				date: "Data",
				status: "Status",
				turn: "Turno",
				code: "Ordem de Coleta",
				establishment: "Estabelecimento",
				search: "Buscar ...",
				transactionStatus: "Status de pagamento",
				recoveryCo2: "CO2 Recuperado",
				cooperative: "Agente Ciclik:",
			},
			details: {
				title: "Ordem de Coleta",
				addMaterial: "Adicionar Material",
				seeBoleto: "Ver boleto",
				addCO2: "Adicionar CO2 recuperado",
				addCO2Success: "Valor adicionado com sucesso",
			},
			singlePurchase: {
				title: "Compra Avulsa",
			},
		},
		materialsPage: {
			title: "Adicionar Entrada de Materiais",
			success: "Material foi criado",
			onDelete: "Material foi deletado",
			confirmButton: "Adicionar",
			fields: {
				weight: "Peso (Kg)",
				date: "Data",
				id: "ID:",
				collectOrder: "Ordem de Categoria",
				category: "Categoria",
			},
		},
		checkInPage: {
			table: {
				title: "Lista de Check-ins",
				header: ["Data", "Cliente", "Cupom"],
			},
			details: {
				title: "Check-in",
				success: (changeSatus: boolean) => changeSatus ? "Cupom está no sorteio" : "Cupom agora está fora do sorteio",
				fields: {
					invoice: "Nota fiscal",
					ticket: "Cupom",
					user: "Usuário Cliente",
					establishment: "Estabelecimento",
					cpf: "CPF",
					cnpj: "CNPJ do estabelecimento",
					isOnDraw: "Participação do Sorteio",
					createdAt: "Data",
				},
			},
		},
		editMaterial: {
			title: "Editar Material",
			success: "Material editado!",
			confirmButton: "Editar",
		},
		signaturePage: {
			title: "Bem-vindo!",
			sucessBoleto: "Boleto emitido!",
			successCancel: "O pedido de cancelamento de assinatura foi feito!",
			firstCard: {
				title: "Selo Ciclik",
				infoTitle: "Como funciona",
				description: [
					"Para empresas que geram resíduos fáceis de armazenar.",
					"01 – Coleta seletiva por semana.",
					"01 - Disparo de e-mail com divulgação de impactos positivos das coletas seletivas e produtos/promoções do estabelecimento.",
					"01 - Relatório de dados de impacto sustentável para uso como material de marketing/divulgação.",
					"01 - Relatório com dados exclusivos acerca do comportamento da base de consumidores.",
				],
				greenSeal: "Selo Ciclik",
				hire: "Contratar",
			},
			secondCard: {
				title: "Selo Ecoponto",
				infoTitle: "Como funciona",
				description: [
					"Empresas agentes da transformação, coletam recicláveis dos clientes.",
					"04 – Coletas seletivas por semana.",
					"01 - Coletor de resíduos Ciclik.",
					"01 - Disparo de e-mail com divulgação de impactos positivos das coletas seletivas e produtos/promoções do estabelecimento.",
					"04 - Disparo de notificação para a base de consumidores (em nosso app).",
					"01 - Relatório de dados de impacto sustentável para uso como material de marketing/divulgação.",
					"01 - Relatório com dados exclusivos acerca do comportamento da base de consumidores.",
				],
				greenSeal: "Selo Ecoponto",
				hire: "Contratar",
			},
			thirdCard: {
				title: "Coleta Avulsa",
				infoTitle: "Como funciona",
				description: [
					"Para demandas extras de resíduos gerados por eventos ou afins.",
					"01 - Relatório de dados de impacto sustentável para uso como material de marketing/divulgação.",
				],
				hire: "Contratar",
			},
		},
		payment: {
			title: (isSingleCollect?: boolean) => isSingleCollect ? "Pagar coleta avulsa" : "Pagamento de Assinatura",
			plan: "Plano contratado:",
			price: (price: number) => `Valor: ${format.currencyForBR(price)}`,
			expiryDate: (date: Date, expired: boolean) => expired ? `O boleto expirou ${format.expiryDate(date)}` : `O boleto irá expirar ${format.expiryDate(date)}`,
			copyButton: "Copiar código de barras",
			codeWasCopied: "Copiado!",
			seeBoleto: "Ver boleto",
			error: "Houve um error ou usuário não possui boleto",
			goBack: "Voltar",
			paid: "Pago",
		},
		creditCardPaymentPage: {
			title: "Pagamento",
			addressTitle: "Endereço de cobrança",
			phoneTitle: "Dados de Contato",
			creditCardTitle: "Dados do Cartão",
			selectedPLan: "Plano selecionado:",
			fields: {
				name: "Nome do Titular do Cartão",
				cardNumber: "Número do Cartão",
				secureCode: "Código de Segurança",
				validateDate: "Data de Validade",
				cpf: "CPF",
				zipcode: "CEP",
				state: "Estado",
				city: "Cidade",
				neighborhood: "Bairro",
				street: "Rua",
				streetNumber: "Número",
				complement: "Complemento",
				ddd: "DDD",
				phone: "Número de Telefone",
			},
			success: "Assinatura feita",
			confirmButton: "Pagar",
		},
		formatters: {
			date: (date: Date | null) => date ? `${moment(date).format("L")}` : "Sem data",
			weight: (weight: number) => `${weight} kg`,
			...format,
		},
		hooks: {
			checkUserPj: {
				title: "Sem Estabelecimento",
				description: "É necessário cadastrar um estabelecimento para acessar essa funcionalidade",
			},
			checkSignature: {
				title: "Sem Assinatura",
				description: "É necessário uma assinatura para acessar essa funcionalidade",
			},
		},
	},
});

export default strings;
