import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Users from "./Users";
import DeleteRequests from "./DeleteRequests";
import LegalUsers from "./LegalUsers";
import Collects from "./Collects";
import CheckIns from "./CheckIns";
import Materials from "./Materials";
import Home from "./Home";
import { MainLayout } from "../../layout";
import { useAuthRoute } from "../../hooks/useAuthRoute";
import CooperativeAdminUser from "./CooperativeAdminUser";

const Dashboard: React.FC = () => {
	useAuthRoute();
	return (
		<MainLayout >
			<Switch>
				<Route
					exact
					path="/dashboard"
					component={Home}
				/>
				<Route path="/dashboard/users" component={Users} />
				<Route path="/dashboard/deleteRequests" component={DeleteRequests} />
				<Route path="/dashboard/legalUsers" component={LegalUsers} />
				<Route path="/dashboard/collects" component={Collects} />
				<Route path="/dashboard/materials" component={Materials} />
				<Route path="/dashboard/checkIns" component={CheckIns} />
				<Route path="/dashboard/cooperativeAdminUsers" component={CooperativeAdminUser} />
			</Switch>
		</MainLayout>
	);
};

export default observer(Dashboard);
