import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Stack } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";
import {
	Section,
	ButtonEditor,
	TextInput,
} from "../../../../components";
import strings from "../../../../resources/strings";
import Store from "./store";

interface IParams {
	id?: string;
}

const EditOrCreate: React.FC = () => {
	const pageStrings = strings.cooperativeAdminUsers;
	const { id } = useParams<IParams>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));

	const onBack = () => {
		history.goBack();
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section
				title={pageStrings.title(!!id)}
			>
				<Stack spacing={5}>
					<TextInput
						boxProps={{ w: "100%"}}
						labelText={pageStrings.fields.fantasyName}
						isDisabled={store.loader.isLoading}
						{...store.formShelf.field("fantasyName")}
					/>
					<TextInput
						boxProps={{ w: "100%"}}
						labelText={pageStrings.fields.documentNumber}
						isDisabled={store.loader.isLoading}
						{...store.formShelf.field("documentNumber")}
					/>
					<TextInput
						boxProps={{ w: "100%"}}
						labelText={pageStrings.fields.name}
						isDisabled={store.loader.isLoading}
						{...store.formShelf.field("name")}
					/>
					<TextInput
						boxProps={{ w:"100%"}}
						labelText={pageStrings.fields.email}
						isDisabled={store.loader.isLoading}
						{...store.formShelf.field("email")}
					/>
					{!id && (
						<TextInput
							boxProps={{ w:"100%"}}
							labelText={pageStrings.fields.password}
							isDisabled={store.loader.isLoading}
							type="password"
							{...store.formShelf.field("password")}
						/>
					)}
				</Stack>
			</Section>
			<ButtonEditor
				loading={store.loader.isLoading}
				hasEditor={!!id}
				isDisabled={store.loader.isLoading}
				onCancel={onBack}
				onGo={() => store.createOrEditCooperativeAdminUser(onBack)}
			/>
		</Flex>
	);
};

export default observer(EditOrCreate);
