import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";

import { useCheckIfLegalUserHasEstablishment } from "~/hooks/useCheckIfLegalUserHasEstablishment";

import Table from "./Table";
import Create from "./Create";
import Edit from "./Edit";
import Details from "./Details";

const Materials: React.FC = () => {
	useCheckIfLegalUserHasEstablishment();

	return (
		<Switch>
			<Route exact path="/dashboard/materials" component={Table} />
			<Route path="/dashboard/materials/create/:collectId" component={Create} />
			<Route path="/dashboard/materials/edit/:materialId" component={Edit} />
			<Route path="/dashboard/materials/details/:id" component={Details} />
		</Switch>
	);
};

export default observer(Materials);
