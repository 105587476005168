import { makeAutoObservable } from "mobx";
import { ModelShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class Store {

	public modelShelf: ModelShelf<api.AdminUser>;

	constructor(id: string) {

		this.modelShelf = new ModelShelf(id, api.getLegalUser);
		makeAutoObservable(this);
	}
}
