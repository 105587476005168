import React from "react";
import { observer } from "mobx-react-lite";
import { AiOutlineUser } from "react-icons/ai";
import { HStack, Image, Text, Icon } from "@chakra-ui/react";
import api from "~/resources/api";

interface IProps {
	legalUser: api.AdminUser;
	onClick: (legalUser: api.AdminUser) => void;
}

export const LegalUserCard: React.FC<IProps> = observer((props) => {
	const { onClick, legalUser } = props;
	return (
		<HStack
			p={5}
			bg="gray.200"
			minW="300px"
			cursor="pointer"
			maxH="300px"
			borderRadius={5}
			onClick={() => onClick(legalUser)}
		>
			{legalUser.image ? (
				<Image
					src={legalUser.image?.url}
					borderRadius={5}
					h={25}
					w={25}
				/>
			) : (
				<Icon
					as={AiOutlineUser}
					color="primary.500"
					w={25}
					h={25}
				/>
			)}
			<Text>{legalUser.name}</Text>
		</HStack>
	);
});
