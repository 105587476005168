import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";

import {
	Table,
	TableCellWithActionButtons,
} from "../../../../components";
import strings from "../../../../resources/strings";
import { useGlobalStore } from "../../../../contexts/useGlobalContext";
import Store from "./store";
import API from "../../../../resources/api";
import { useHistory } from "react-router-dom";
import { legalUsersAuthentication } from "~/hooks/legalUsersAuthentication";

const TableView: React.FC = () => {
	legalUsersAuthentication();
	const pageStrings = strings.legalUsers.table;
	const modal = strings.common.modal;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();

	const history = useHistory();

	const onDeleteLgealUser = (id: string) => {
		store.deleteLegalUser(id);
		dialog.closeDialog();
	};

	const onGoToEditLegalUser = (id: string) => history.push(`legalUsers/edit/${id}`);
	const onGoToDetailsAdminUser = (id: string) => history.push(`legalUsers/details/${id}`);

	const openDialog = (legalUser: API.AdminUser) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description(legalUser.name),
			buttons: [
				{
					title: modal.button.delete,
					onPress: () => onDeleteLgealUser(legalUser.id),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex p={{ base: "2", lg: "16" }}>
			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				hasNextPage={store._isHaveNextPage}
				renderRow={(item) => (
					<Tr key={item.id}>
						<Td>
							<Text>{item.name}</Text>
						</Td>
						<Td>
							<Text>{item.email}</Text>
						</Td>
						<Td>
							<Text>{item.legalUser?.fantasyName}</Text>
						</Td>
						<Td>
							<Text>{item.legalUser?.documentNumber}</Text>
						</Td>
						<Td>
							<Text>
								{
									item.legalUser?.subscriptionStatus
									&& API.translateSubscriptionStatus(item.legalUser.subscriptionStatus)
								}
							</Text>
						</Td>
						<TableCellWithActionButtons
							onEdit={() => onGoToEditLegalUser(item.id)}
							onDelete={() => openDialog(item)}
							onView={() => onGoToDetailsAdminUser(item.id)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
