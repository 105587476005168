import React from "react";
import { observer } from "mobx-react-lite";
import {
	Box,
	Flex,
	Heading,
	Text,
	Button,
	BoxProps,
	ButtonProps,
} from "@chakra-ui/react";

import format from "~/resources/format";
import strings from "~/resources/strings";
import { Card } from "~/components";
import { SubscriptionStatusBadge } from "./SubscriptionStatusBadge";
import api from "~/resources/api";

interface PaymentButton extends ButtonProps {
	buttonText?: string;
}

export interface IProps {
	title: string;
	price: number;
	infoTitle: string;
	paymentSectionTitle?: string;
	descriptionType?: "text" | "descriptive";
	description?: string[];
	headerProps?: BoxProps;
	subscriptionStatus?: api.SubscriptionStatus;
	buttons?: Array<PaymentButton | null>;
	removePlan?: boolean;
}

export const PaymentCard: React.FC<IProps> = observer((props) => {
	const componentStrings = strings.components.paymentCard;
	const {
		title,
		price,
		infoTitle,
		descriptionType = "text",
		description,
		paymentSectionTitle,
		headerProps,
		subscriptionStatus,
		removePlan,
		buttons = [],
	} = props;

	return (
		<Card
			mb={{base: 5, lg: 0}}
			p={0}
			borderRadius={12}
			boxShadow="lg"
			d={removePlan ? "none" : "block"}
		>
			<Flex
				bg="primary.500"
				borderTopRadius="lg"
				alignItems="center"
				py={2}
				px={5}
				{...headerProps}
			>
				<Heading color="white" size="lg">
					{title}
				</Heading>
				{subscriptionStatus && (
					<SubscriptionStatusBadge subscriptionStatus={subscriptionStatus} />
				)}
			</Flex>
			<Flex
				direction="column"
			>
				<Box
					p={5}
					w="100%"
				>
					<Heading
						color="gray.500"
						size="xs"
						mb={2}
					>
						{infoTitle}
					</Heading>

					<Box
						h={150}
						overflow="auto"
						css={{
							"&::-webkit-scrollbar": {
								width: "3px",
							},
							"&::-webkit-scrollbar-track": {
								width: "6px",
							},
							"&::-webkit-scrollbar-thumb" : {
								background: "#FEB200",
								borderRadius: "16px",
							},
						}}
					>
						{descriptionType === "text"
							? (
								<Text
									color="gray.500"
									textAlign="justify"
								>
									{description}
								</Text>
							)
							: description?.map((item, index) => (
								<Text
									key={index}
									color="gray.500"
									textAlign="justify"
									maxW="98%"
								>
									{item}
								</Text>
							))}
					</Box>
				</Box>
				<Flex
					w="100%"
					h={200}
					bg="gray.100"
					justifyContent="space-between"
					flexDirection="column"
					pt={5}
					borderRadius={12}
				>

					<Heading size="lg" textAlign="center">
						{paymentSectionTitle}
					</Heading>
					<Heading
						size="xl"
						textAlign="center"
						color="gray.500"
					>
						{format.currencyForBR(price)}
					</Heading>
					<Box mt={4}>
						{buttons.map((button, index) => !!button && (
							<Button
								key={index}
								w="100%"
								{...button}
							>
								{button.buttonText || componentStrings.button}
							</Button>
						))}
					</Box>
				</Flex>
			</Flex>
		</Card>
	);
});
