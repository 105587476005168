import React from "react";
import { Center, VStack, Switch } from "@chakra-ui/react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { DetailsRow, Section, DetaislContent, FormError } from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.checkInPage.details;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));

	return (
		<>
			<Center
				w="100%"
				p={{ base: 0, sm: 16 }}
			>
				<Section title={pageStrings.title}>
					<DetaislContent
						loading={store.modelShelf.loader.isLoading}
						item={store.modelShelf.model}
						renderItem={(item) => (
							<VStack spacing={spacing.s2} w="100%" display="inline">
								<DetailsRow
									label={pageStrings.fields.invoice}
									value={item.invoice}
								/>
								<DetailsRow
									label={pageStrings.fields.ticket}
									value={item.ticket.toString()}
								/>
								<DetailsRow
									label={pageStrings.fields.user}
									value={item.user.name}
								/>
								{item.user.documentNumber && (
									<DetailsRow
										label={pageStrings.fields.cpf}
										value={item.user.documentNumber}
									/>
								)}

								<DetailsRow
									label={pageStrings.fields.establishment}
									value={item.establishment.name}
								/>
								<DetailsRow
									label={pageStrings.fields.cnpj}
									value={item.establishment.documentNumber}
								/>
								<FormError label={pageStrings.fields.isOnDraw}>
									<Switch
										size="lg"
										isChecked={store.isOnDraw.value}
										isDisabled={store.loader.isLoading}
										onChange={() => store.toggleIsOnDraw(item.id, !store.isOnDraw.value)}
										disabled={store.loader.isLoading}
									/>
								</FormError>
							</VStack>
						)}
					/>
				</Section>
			</Center>
		</>
	);
};

export default observer(Details);
