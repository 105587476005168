import { useEffect } from "react";
import { useHistory } from "react-router";
import { useGlobalStore } from "../contexts/useGlobalContext";

export const legalUsersAuthentication = () => {
	const { authStore } = useGlobalStore();
	const history = useHistory();
	useEffect(() => {
		if (!authStore.loader.isLoading && authStore.currentAdminUser) {
			if (authStore.currentAdminUser.legalUser) {
				history.replace("/");
			}
		}
	}, [authStore.loader.isLoading, authStore.currentAdminUser]);

	return authStore.loader.isLoading;
};
