import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Center, VStack } from "@chakra-ui/layout";
import { useParams } from "react-router-dom";
import {
	DetailsRow,
	Section,
	DetaislContent,
} from "~/components";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.cooperativeAdminUsers;
	const { id } = useParams<IParams>();
	const store = useLocalObservable(() => new Store(id));

	return (
		<Center
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section title={pageStrings.details.title}>
				<DetaislContent
					loading={store.modelShelf.loader.isLoading}
					item={store.modelShelf._model}
					renderItem={(item) => (
						<VStack spacing={spacing.s2} w="100%" display="inline">
							<DetailsRow
								label={pageStrings.fields.fantasyName}
								value={item.legalUser?.fantasyName || ""}
							/>
							<DetailsRow
								label={pageStrings.fields.documentNumber}
								value={item.legalUser?.documentNumber || ""}
							/>
							<DetailsRow
								label={pageStrings.fields.name}
								value={item.name}
							/>
							<DetailsRow
								label={pageStrings.fields.email}
								value={item.email}
							/>
						</VStack>
					)}
				/>
			</Section>
		</Center>
	);
};

export default observer(Details);
