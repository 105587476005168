import React from "react";
import { Box, Stack, Text } from "@chakra-ui/react";

export interface IUserInfoCardProps {
	label: string;
	value: string;
}

export const DetailsRow: React.FC<IUserInfoCardProps> = (props) => {
	const { value, label } = props;
	return (
		<Box bg="gray.100" p={4} borderRadius="lg">
			<Stack spacing={1}>
				<Text fontSize="xl" fontWeight="bold">{label}</Text>
				<Text fontSize="md" >{value}</Text>
			</Stack>
		</Box>
	);
};
