import React from "react";
import { observer } from "mobx-react-lite";
import { Center, Text, Box } from "@chakra-ui/react";

import strings from "~/resources/strings";
import { Menu } from "~/components";

const Home: React.FC = () => (
	<Box>
		<Center>
			<Text
				mt="4"
				color="secondary.500"
				fontSize={{ base: "4xl", sm: "5xl", lg: "6xl" }}
			>
				{strings.home.welcome}
			</Text>
		</Center>
		<Menu />
	</Box>
);

export default observer(Home);
