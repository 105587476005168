import { ComponentStyleConfig } from "@chakra-ui/react";

export const Switch: ComponentStyleConfig = {
	baseStyle: {
		track: {
			bg:"gray.300",
			_checked: {
				bg: "primary.500",
			},
		},
	},
};
