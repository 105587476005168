import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import {
	Table,
	TableCellWithActionButtons,
	PeriodFilter,
} from "../../../../components";
import strings from "../../../../resources/strings";
import Store from "./store";
import api from "~/resources/api";
import { useGlobalStore } from "~/contexts/useGlobalContext";

const TableView: React.FC = () => {
	const pageStrings = strings.collects.table;
	const { authStore } = useGlobalStore();
	const isLegalUser = !!authStore.currentAdminUser?.legalUser;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();
	const onGoToCreate = () => {
		history.push("/dashboard/collects/create");
	};

	const onGoToEdit = (id: string) => {
		history.push(`/dashboard/collects/edit/${id}`);
	};

	const onGoToDetails = (id: string) => {
		history.push(`/dashboard/collects/details/${id}`);
	};

	React.useEffect(() => {
		if (authStore.currentAdminUser) {
			store.setLegalUser(isLegalUser);

		}
	}, [authStore.currentAdminUser]);

	return (
		<Flex p={{ base: "2", lg: "16"}}>
			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<PeriodFilter
						selectedStartDate={store.startDate.value}
						onChangeStartDate={store.startDate.setValue}
						selectedFinalDate={store.finalDate.value}
						onChangeFinalDate={store.finalDate.setValue}
					/>
				}
				renderRow={(item) => (
					<Tr key={item.id} >
						<Td>
							<Text>{item.code}</Text>
						</Td>
						<Td>
							<Text>
								{item.adminUser.establishment?.name}
							</Text>
						</Td>
						<Td>
							<Text>{api.translateCollectStatus(item.status)}</Text>
						</Td>
						<Td>
							<Text>{api.translateTurn(item.turn)}</Text>
						</Td>
						<Td>
							<Text>
								{strings.formatters.date(item.collectDate)}
							</Text>
						</Td>
						<Td>
							<Text>
								{item.cooperative?.name}
							</Text>
						</Td>
						<TableCellWithActionButtons
							onEdit={
								!isLegalUser &&
								authStore.currentAdminUser?.type !== api.AdminUserType.cooperative ?
									() => onGoToEdit(item.id) : undefined
							}
							onView={() => onGoToDetails(item.id)}
							isCollect
						/>
					</Tr>
				)}
				addtext={pageStrings.schedule}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
				onAdd={authStore.currentAdminUser?.type !== api.AdminUserType.cooperative ? onGoToCreate : undefined}
				onExportCsv={!isLegalUser ? store.exportCsv : undefined}
			/>
		</Flex>
	);
};

export default observer(TableView);
