import { makeObservable, observable } from "mobx";
import {AbstractPaginatedListStore, AttributeShelf, LoaderShelf, ModelShelf} from "@startapp/mobx-utils";
import api from "~/resources/api";

export default class Store extends AbstractPaginatedListStore<api.Material> {

	public loader = new LoaderShelf();
	public collectId = "";
	public modelShelf: ModelShelf<api.Collect>;
	public recoveredCO2 = new AttributeShelf(0);

	constructor(id: string) {
		super();
		this.modelShelf = new ModelShelf(id, api.getCollect);
		makeObservable(this, {
			modelShelf: observable,
			collectId: observable,
			loader: observable,
		});
		this.collectId = id;
		this.fetchPage(0);
	}

	protected getDataItemsPerPage(page: number): Promise<api.Material[]> {
		return api.getAllMaterialsFromCollect(this.collectId, page);
	}
}
