import React from "react";
import { observer } from "mobx-react-lite";
import { Flex, HStack, Button, Box } from "@chakra-ui/react";
import { TextInput } from "..";
import strings from "../../resources/strings";

interface IProps {
	onOpen: () => void;
	value?: string;
	labelText: string;
	onClean: () => void;
}

export const AutoCompleteLegalUserField: React.FC<IProps> = observer((props) => {
	const { labelText, onClean, value, onOpen } = props;
	return (
		<Flex
			p={2}
			w="100%"
			maxW="3xl"
			alignItems="end"
			borderBottomRadius={20}
			borderTopLeftRadius={20}
			justifyContent="center"
			cursor="pointer"
			bg="#FFFFFF"
		>
			<HStack w="100%" alignItems="end">
				<Box
					w="75%"
				>
					<TextInput
						labelText={labelText}
						onClick={onOpen}
						value={value}
						cursor="pointer"
						isReadOnly
					/>
				</Box>
				<Button onClick={onClean} w="25%">{strings.common.clear}</Button>
			</HStack>
		</Flex>
	);
});
