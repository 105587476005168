import React from "react";
import { observer } from "mobx-react-lite";
import { Box, BoxProps, Flex, Heading } from "@chakra-ui/react";
import spacing from "../../themes/spacing";
import { Card } from "~/components";

interface IProps extends BoxProps {
	title?: string;
	footer?: JSX.Element | null;
}

export const Section: React.FC<IProps> = observer((props) => {
	const { children, title, footer, ...rest } = props;

	return (
		<Box
			{...rest}
			position="relative"
			maxW={{ base:"100%", md:"75%", lg: "60%" }}
			w="100%"
			bg="white"
			rounded={{ base: 0, sm: spacing.s10 }}
		>

			{title && (
				<Box
					textAlign="center"
					bg="gray.100"
					p={4}
				>
					<Heading fontSize="xl" color="primary.700">
						{title}
					</Heading>
				</Box>
			)}
			<Flex alignItems="center" w="100%">
				<Card size={footer ? "footerMd" : "md"}>
					{children}
				</Card>
			</Flex>
			{footer && (
				<Box
					pos="absolute"
					bottom={0}
					w="full"
				>
					{footer}
				</Box>
			)}
		</Box>
	);
});
