import React from "react";
import {
	Table as CTable,
	Thead,
	Tbody,
	Th,
	Tr,
	Flex,
	Heading,
	Button,
	BoxProps,
	Box,
} from "@chakra-ui/react";
import {
	AiOutlineCloudDownload,
} from "react-icons/ai";

import { AddIcon } from "@chakra-ui/icons";
import { Label, Card, Pagination, Loading } from "../";
import spacing from "../../themes/spacing";
import strings from "~/resources/strings";

interface IProps<DataType> extends BoxProps {
	data: DataType[];
	headers: string[];
	renderRow: (item: DataType, index: number) => React.ReactElement | null;
	loading: boolean;
	emptyMessage: string;
	currentPage: number;
	prevPage: () => void;
	nextPage: () => void;
	hasNextPage: boolean;
	onAdd?: () => void;
	onExportCsv?: () => void;
	addtext?: string;
	tableTitle?: string;
	headerFilter?: JSX.Element;
	disableCsv?: boolean;
}

export const Table = <DataType,>(props: IProps<DataType>) => {
	const {
		data,
		headers,
		renderRow,
		loading,
		emptyMessage,
		currentPage,
		prevPage,
		nextPage,
		hasNextPage,
		tableTitle,
		onAdd,
		onExportCsv,
		addtext,
		headerFilter,
		disableCsv,
		...rest
	} = props;

	return (
		<Flex
			w="100%"
			flexDir="column"
		>
			{tableTitle && (
				<Heading
					variant="secondary"
					size="lg"
					color="secondary.500"
					mb={spacing.s3}
				>
					{tableTitle}
				</Heading>
			)}
			<Box mb={3}>
				{headerFilter && (headerFilter)}
			</Box>
			<Card
				d="initial"
				w="100%"
				px={0}
				{...rest}
			>
				<Box
					maxHeight={{ base: 450, md: 600 }}
					overflowX="auto"
				>
					{loading || !data ? (
						<Flex my={spacing.s5} w="100%" justifyContent="center">
							<Loading />
						</Flex>
					) : data.length < 1 ? (
						<Flex
							my={spacing.s5}
							w="100%"
							justifyContent="center"
							minH={300}
							alignItems="center"
						>
							<Heading size="md" fontWeight="bold">
								{emptyMessage}
							</Heading>
						</Flex>
					) : (
						<CTable
							variant="simple"
							ml="auto"
							height="100%"
						>
							<Thead
								borderBottomColor="primary.500"
								borderBottomWidth={spacing.s2}
							>

								<Tr>
									{headers.map((header, index) => (
										<Th key={index}>
											<Label
												variant="secondary"
												fontWeight="bold"
											>
												{header}
											</Label>
										</Th>
									))}
								</Tr>
							</Thead>
							<Tbody>
								{data.map((dataItem, index) => renderRow(dataItem, index))}
							</Tbody>
						</CTable>
					)}
				</Box>
				<Flex
					pt={spacing.s4}
					px={spacing.s6}
					alignItems="center"
					justifyContent={onAdd ? "space-between" : "flex-end"}
					borderTopColor="primary.500"
					borderTopWidth={spacing.s2}
				>
					{onAdd && (
						<Button rightIcon={<AddIcon />} onClick={onAdd}>
							{addtext || strings.actions.add}
						</Button>
					)}
					<Pagination
						currentPage={currentPage}
						prevPage={prevPage}
						nextPage={nextPage}
						hasNextPage={hasNextPage}
					/>
					{onExportCsv && (
						<Button
							disabled={disableCsv}
							rightIcon={<AiOutlineCloudDownload />}
							onClick={onExportCsv}
							position="absolute"
							right={5}
							top={2}
							variant="outline"
							colorScheme="secondary"
							size="xs"
						>
							{strings.actions.exportCsv}
						</Button>
					)}
				</Flex>
			</Card>
		</Flex>
	);
};
