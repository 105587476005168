/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ILink {
	href: string;
	as?: string;
	passHref?: boolean;
}

interface IRouteType {
	[key: string]: ((...x: any) => ILink) | ILink | IRouteType;
}
const getRoutes = <T extends IRouteType>(value: T): T => value;

export const getDashboardRoute = () => {
	switch (process.env.NODE_ENV) {
		case "production":
			return "https://dashboard.ciclik.com.br/login";
		default:
			return "https://staging.dashboard.ciclik.com.br/login";
	}
};

const Routes = getRoutes({
	ciclikSite: () => ({
		href: "https://ciclik.com.br",
		passHref: true,
	}),
});

export default Routes;
