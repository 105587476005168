import { makeAutoObservable } from "mobx";

export default class SimpleStateShelf<StateType> {
	public state: StateType;

	constructor(initialValue: StateType) {
		this.state = initialValue;
		makeAutoObservable(this);
	}

	public setState = (state: StateType) => this.state = state;
}
