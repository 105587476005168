import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";

import {
	Table,
	TableCellWithActionButtons,
} from "../../../../components";
import strings from "../../../../resources/strings";
import { useGlobalStore } from "../../../../contexts/useGlobalContext";
import Store from "./store";
import API from "../../../../resources/api";
import format from "~/resources/format";

const TableView: React.FC = () => {
	const pageStrings = strings.deleteRequests.table;
	const modal = strings.deleteRequests.modal;
	const store = useLocalObservable(() => new Store());
	const { dialog } = useGlobalStore();

	const onAproveDeleteRequest = (id: string) => {
		store.approveDeleteRequest(id);
		dialog.closeDialog();
	};

	const openDialog = (deleteRequest: API.DeleteRequest) => {
		dialog.showDialog({
			title: modal.title,
			closeOnOverlayClick: true,
			description: modal.description,
			buttons: [
				{
					title: modal.button.cancel,
					onPress: () => dialog.closeDialog(),
					buttonProps: { bg: "red.500" },
				},
				{
					title: modal.button.aprove,
					onPress: () => onAproveDeleteRequest(deleteRequest.id),
					outlined: true,
				},
			],
		});
	};

	return (
		<Flex p={{ base: "2", lg: "16"}}>
			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				renderRow={(item) => (
					<Tr key={item.id} >
						<Td>
							<Text>{item.user.name}</Text>
						</Td>
						<Td>
							<Text>{item.user.email}</Text>
						</Td>
						<Td>
							<Text>{format.expiryDate(item.createdAt)}</Text>
						</Td>
						<TableCellWithActionButtons
							onApprove={() => openDialog(item)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				hasNextPage={store._isHaveNextPage}
			/>
		</Flex>
	);
};

export default observer(TableView);
