import React from "react";
import {
	Box,
	Image,
	Text,
	Flex,
	FlexProps,
} from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import api from "~/resources/api";
import spacing from "~/themes/spacing";

interface IProps {
	cooperative: api.AdminUser;
	onClick?: (establishment: api.AdminUser) => void;
	isSelected?: boolean;
	flexProps?: FlexProps;
}

export const CooperativeCard: React.FC<IProps> = observer((props) => {
	const {
		cooperative,
		onClick,
		isSelected,
		flexProps,
	} = props;

	return (
		<Flex
			h="80px"
			bg="gray.100"
			borderRadius="md"
			borderTopRightRadius={0}
			borderWidth={isSelected ? 1 : 0}
			borderColor="gray.400"
			cursor="pointer"
			w="100%"
			onClick={onClick ? () => onClick(cooperative) : undefined}
			{...flexProps}
		>
			<Box
				bg="gray.300"
				minW="25%"
				maxW="30%"
				borderRadius="md"
				borderTopRightRadius={0}
			>
				{cooperative.image && (
					<Image
						src={cooperative.image.url}
						w="100%"
						h="100%"
						borderRadius="md"
						borderTopRightRadius={0}
					/>
				)}
			</Box>
			<Flex
				p={spacing.s2}
				w="100%"
				direction="column"
			>
				<Text fontWeight="bold" size="md">
					{cooperative.name}
				</Text>
				<Text fontWeight="bold" size="md">
					{cooperative.email}
				</Text>
			</Flex>
		</Flex>
	);
});
