import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import {
	Flex,
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

import {
	Table,
	TableCellWithActionButtons,
	PeriodFilter,
} from "~/components";
import strings from "~/resources/strings";
import Store from "./store";

const TableView: React.FC = () => {
	const pageStrings = strings.checkInPage.table;
	const store = useLocalObservable(() => new Store());
	const history = useHistory();

	const onGoToDetails = (id: string) => {
		history.push(`/dashboard/checkIns/details/${id}`);
	};

	return (
		<Flex p={{ base: "2", lg: "16" }}>

			<Table
				data={store._items}
				tableTitle={pageStrings.title}
				headers={pageStrings.header}
				headerFilter={
					<PeriodFilter
						selectedStartDate={store.startDate.value}
						onChangeStartDate={store.startDate.setValue}
						selectedFinalDate={store.finalDate.value}
						onChangeFinalDate={store.finalDate.setValue}
					/>
				}
				hasNextPage={store._isHaveNextPage}
				renderRow={(item) => (
					<Tr key={item.id}>
						<Td>
							<Text>{strings.formatters.date(item.createdAt)}</Text>
						</Td>
						<Td>
							<Text>{item.user.name}</Text>
						</Td>
						<Td>
							<Text>{item.ticket}</Text>
						</Td>
						<TableCellWithActionButtons
							onView={() => onGoToDetails(item.id)}
						/>
					</Tr>
				)}
				loading={store._loading}
				emptyMessage={strings.common.noResutls}
				currentPage={store.page}
				prevPage={store.previousPage}
				nextPage={store.nextPage}
				onExportCsv={store.exportCsv}
				disableCsv={!store._items.length}
			/>
		</Flex>
	);
};

export default observer(TableView);
