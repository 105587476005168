import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import { useCheckIfLegalUserHasEstablishment } from "~/hooks/useCheckIfLegalUserHasEstablishment";
import CreateOrEdit from "./CreateOrEdit";
import Table from "./Table";
import Details from "./Details";

const Collects: React.FC = () => {
	useCheckIfLegalUserHasEstablishment();

	return (
		<Switch>
			<Route exact path="/dashboard/collects" component={Table} />
			<Route path="/dashboard/collects/create" component={CreateOrEdit} />
			<Route path="/dashboard/collects/edit/:id" component={CreateOrEdit} />
			<Route path="/dashboard/collects/details/:id" component={Details} />
		</Switch>
	);
};

export default observer(Collects);
