import React from "react";
import { Center, VStack } from "@chakra-ui/layout";
import {
	Flex,
	Td,
	Text,
	Tr,
} from "@chakra-ui/react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { useParams, useHistory } from "react-router-dom";

import { DetailsRow, Section, DetaislContent, Table, TableCellWithActionButtons, TextInput } from "~/components";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import api from "~/resources/api";
import strings from "~/resources/strings";
import spacing from "~/themes/spacing";
import Store from "./store";

interface IParams {
	id: string;
}

const Details: React.FC = () => {
	const pageStrings = strings.collectPage;
	const { id } = useParams<IParams>();
	const { authStore } = useGlobalStore();
	const store = useLocalObservable(() => new Store(id));
	const history = useHistory();

	const onGoToDetails = (materialId: string) => {
		history.push(`/dashboard/materials/details/${materialId}`);
	};

	const isSuperAdmin = !authStore.currentAdminUser?.legalUser;

	const onGoToCreateMaterial = () => {
		history.push(`/dashboard/materials/create/${id}`);
	};

	const onGoToEditMaterial = (materialId: string) => history.push(`/dashboard/materials/edit/${materialId}`);

	return (
		<>
			<Center
				w="100%"
				p={{ base: 0, sm: 16 }}
			>
				<Section title={pageStrings.details.title}>
					<DetaislContent
						loading={store.modelShelf.loader.isLoading}
						item={store.modelShelf._model}
						renderItem={(item) => (
							<VStack spacing={spacing.s2} w="100%" display="inline">
								<DetailsRow
									label={pageStrings.fields.owner}
									value={item.adminUser.name}
								/>
								<DetailsRow
									label={pageStrings.fields.status}
									value={api.translateCollectStatus(item.status)}
								/>
								<DetailsRow
									label={pageStrings.fields.code}
									value={item.code.toString()}
								/>
								{item.collectDate && (
									<DetailsRow
										label={pageStrings.fields.date}
										value={strings.formatters.date(item.collectDate)}
									/>
								)}
								{item.description && (
									<DetailsRow
										label={pageStrings.fields.descriptionView}
										value={item.description}
									/>
								)}
								<DetailsRow
									label={pageStrings.fields.turn}
									value={api.translateTurn(item.turn)}
								/>
								<TextInput
									labelText={pageStrings.fields.recoveryCo2}
									type="number"
									value={item.recoveredCO2.toFixed(3)}
									isDisabled
								/>
							</VStack>
						)}
					/>
				</Section>
			</Center>
			<Flex p={{ base: "4", lg: "8" }}>
				<Table
					data={store._items}
					tableTitle={strings.materials.table.title}
					headers={strings.materials.table.header}
					hasNextPage={store._isHaveNextPage}
					renderRow={(item) => (
						<Tr key={item.id} >
							<Td>
								<Text>{item.materialCode}</Text>
							</Td>
							<Td>
								<Text>{api.translateMaterialCategory(item.category)}</Text>
							</Td>
							<Td>
								<Text>{strings.formatters.date(item.date)}</Text>
							</Td>
							<Td>
								<Text>{strings.formatters.weight(item.weight)}</Text>
							</Td>
							<Td>
								<Text>{store.modelShelf._model.code}</Text>
							</Td>
							<TableCellWithActionButtons
								onEdit={isSuperAdmin ? () => onGoToEditMaterial(item.id) : undefined}
								onView={() => onGoToDetails(item.id)}
							/>
						</Tr>
					)}
					loading={store._loading}
					emptyMessage={strings.common.noResutls}
					currentPage={store.page}
					prevPage={store.previousPage}
					nextPage={store.nextPage}
					onAdd={isSuperAdmin ? onGoToCreateMaterial : undefined}
					addtext={pageStrings.details.addMaterial}
				/>
			</Flex>
		</>
	);
};

export default observer(Details);
