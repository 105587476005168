import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import Table from "./Table";
import EditUser from "./Edit";
import Details from "./Details";
import EditOrCreateEstablishment from "./EditOrCreateEstablishment";


const LegalsUsers: React.FC = () =>
	(
		<Switch>
			<Route exact path="/dashboard/legalUsers" component={Table} />
			<Route path="/dashboard/legalUsers/edit/:id" component={EditUser} />
			<Route path="/dashboard/legalUsers/details/:id" component={Details} />
			<Route path="/dashboard/legalUsers/establishments/edit/:id" component={EditOrCreateEstablishment} />
			<Route path="/dashboard/legalUsers/establishments/create" component={EditOrCreateEstablishment} />
		</Switch>
	)
;

export default observer(LegalsUsers);
