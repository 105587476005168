export const colors = {
	primary: {
		50: "#E7FEF0",
		100: "#BBFCD6",
		200: "#8FFABB",
		300: "#63F8A1",
		400: "#37F686",
		500: "#09BE54",
		600: "#09C356",
		700: "#079241",
		800: "#05612B",
		900: "#023116",
	},
	secondary: {
		50: "#FFF7E5",
		100: "#FFEAB8",
		200: "#FFDC8A",
		300: "#FFCE5C",
		400: "#FFC02E",
		500: "#FEB200",
		600: "#CC8F00",
		700: "#996B00",
		800: "#664700",
		900: "#332400",
	},
};
