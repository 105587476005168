import React from "react";
import { observer } from "mobx-react-lite";
import { Route, Switch } from "react-router-dom";
import TableView from "./Table";
import EditOrCreate from "./EditOrCreate";
import Details from "./Details";

const CooperativeAdminUsers: React.FC = () => (
	<Switch>
		<Route exact path="/dashboard/cooperativeAdminUsers" component={TableView} />
		<Route path="/dashboard/cooperativeAdminUsers/edit/:id" component={EditOrCreate} />
		<Route path="/dashboard/cooperativeAdminUsers/create" component={EditOrCreate} />
		<Route path="/dashboard/cooperativeAdminUsers/details/:id" component={Details} />
	</Switch>
);

export default observer(CooperativeAdminUsers);
