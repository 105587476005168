import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Flex } from "@chakra-ui/react";
import { ImagePicker } from "../ImagePicker";

import spacing from "../../themes/spacing";

const { s1, s2 } = spacing;

interface Image {
	src: string | null;
	pick: () => void;
	loading: boolean;
}

interface IProps {
	images: Image[];
}

export const MultipleImagePickers: React.FC<IProps> = observer((props) => {
	const { images } = props;
	return (
		<Flex
			flexDirection="row"
			flexWrap="wrap"
			justifyContent="center"
			alignItems="center"
		>
			{images.map((image, index) => (
				<Box
					key={index}
					marginX={[s1, s1, s2, s2]}
					marginY={[s1, s1, s1, 0]}
				>
					<ImagePicker
						src={image.src}
						pickImage={image.pick}
						loading={image.loading}
					/>
				</Box>
			))}
		</Flex>
	);
});
