import {
	AiFillHome,
	AiOutlineUser,
	AiFillFile,
	AiFillSchedule,
	AiFillProfile,
	AiOutlineCarryOut,
	AiFillIdcard,
	AiFillRead,
	AiFillBook,
	AiFillThunderbolt,
} from "react-icons/ai";
import api from "~/resources/api";
import strings from "../resources/strings";

const useMainRoutes = (adminUser: api.AdminUser | null) => {
	const isAdminMaster = adminUser && !adminUser.legalUser;
	const isCooperativeUser =  adminUser?.type === api.AdminUserType.cooperative;

	const adminMasterRoutes = [
		{
			Icon: AiFillHome,
			text: strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: AiFillSchedule,
			text: strings.nav.createCollect,
			path: "/dashboard/collects/create",
		},
		{
			Icon: AiFillRead,
			text: strings.nav.collects,
			path: "/dashboard/collects",
		},
		{
			Icon: AiOutlineCarryOut,
			text: strings.nav.checkIns,
			path: "/dashboard/checkIns",
		},
		{
			Icon: AiFillBook,
			text: strings.nav.deleteRequests,
			path: "/dashboard/deleteRequests",
		},
		{
			Icon: AiFillProfile,
			text: strings.nav.materials,
			path: "/dashboard/materials",
		},
		{
			Icon: AiFillThunderbolt,
			text: strings.nav.cooperativeAdminUsers,
			path: "/dashboard/cooperativeAdminUsers",
		},
		{
			Icon: AiOutlineUser,
			text: strings.nav.customer,
			path: "/dashboard/users",
		},
		{
			Icon: AiFillFile,
			text: strings.nav.legalUser,
			path: "/dashboard/legalUsers",
		},
	];

	const legalUserRoutes = [
		{
			Icon: AiFillHome,
			text: strings.nav.home,
			path: "/dashboard",
		},
		adminUser &&
		{
			Icon: AiFillIdcard,
			text: strings.nav.establishment,
			path: `/dashboard/legalUsers/details/${adminUser.id}`,
		},
		{
			Icon: AiFillSchedule,
			text: strings.nav.createCollect,
			path: "/dashboard/collects/create",
		},
		{
			Icon: AiFillRead,
			text: strings.nav.collects,
			path: "/dashboard/collects",
		},
		{
			Icon: AiFillProfile,
			text: strings.nav.materials,
			path: "/dashboard/materials",
		},
	];

	const cooperativeRoutes = [
		{
			Icon: AiFillHome,
			text: strings.nav.home,
			path: "/dashboard",
		},
		{
			Icon: AiFillRead,
			text: strings.nav.collects,
			path: "/dashboard/collects",
		},
		{
			Icon: AiFillProfile,
			text: strings.nav.materials,
			path: "/dashboard/materials",
		},
	];

	if (isCooperativeUser) {
		return cooperativeRoutes;
	}

	if (isAdminMaster) {
		return adminMasterRoutes;
	}

	return legalUserRoutes;
};

export default useMainRoutes;
