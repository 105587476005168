import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Box, Flex, Stack, Text } from "@chakra-ui/react";
import {
	Section,
	FormError,
	ImagePicker,
	ButtonEditor,
	EnumSelect,
	TextInput,
	AddressAutoCompleteInput,
} from "../../../../components";
import strings from "../../../../resources/strings";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import { useHistory, useParams } from "react-router-dom";
import spacing from "../../../../themes/spacing";
import Store from "./store";
import api from "~/resources/api";
import { showSuccessToast } from "~/resources/toast";

interface IParams {
	id?: string;
}

const EditOrCreateEstablishment: React.FC = () => {
	const pageStrings = strings.establishment.edit;
	const { authStore } = useGlobalStore();
	const { id } = useParams<IParams>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id, authStore));

	const onSuccess = () => {
		showSuccessToast(pageStrings.success(!!id));
		history.push(`/dashboard/legalUsers/details/${authStore.currentAdminUser?.id}`);
	};

	const handleCreateOrEditEstablishment = () => {
		if (store.legalUser.value?.establishment) {
			store.editEstablishment(store.legalUser.value.establishment.id, onSuccess);
			return;
		}
		store.createEstablishment(onSuccess);
	};

	const onBack = () => {
		history.goBack();
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			flexDirection="column"
			w="100%"
			p={{ base: 0, sm: 16 }}
		>
			<Section
				title={pageStrings.title(!!store.legalUser.value?.establishment)}
			>
				<Stack spacing={spacing.s8}>
					<Flex mt={spacing.s8} flexDir={{ base: "column", xl: "row" }} justifyContent="space-around">
						<Stack
							spacing={spacing.s8}
							w={{ base: "100%" }}
							flexDirection="row"
							flexWrap="wrap"
							justifyContent="space-between"
							alignItems="center"
						>
							<Flex justifyContent="space-around" alignItems="center" w="100%">
								<Stack textAlign="center" alignSelf="center">
									<Text>{pageStrings.fields.imagem}</Text>
									<ImagePicker
										pickImage={store.image.getPickerFields().pick}
										src={store.image.src}
									/>
									<Text fontSize="xs">{pageStrings.fields.imageTypes}</Text>
								</Stack>
								<Stack textAlign="center" alignSelf="center">
									<Text>{pageStrings.fields.logo}</Text>
									<ImagePicker
										pickImage={store.logo.getPickerFields().pick}
										src={store.logo.src}
									/>
									<Text fontSize="xs">{pageStrings.fields.imageTypes}</Text>
								</Stack>
							</Flex>
							<TextInput
								boxProps={{ w: { base: "100%", md: "45%" } }}
								labelText={pageStrings.fields.phone}
								type="phone"
								isDisabled={store.loader.isLoading || store.isDisabled.value}
								mask="99 99999-9999"
								{...store.form.field("phone")}
							/>
							{
								store.legalUser.value
								&& store.legalUser.value.legalUser
								&& store.legalUser.value.legalUser.documentNumber.length === 18
								&& (
									<FormError
										label={pageStrings.fields.segment}
										w={{ base: "100%", md: "45%" }}
									>
										<EnumSelect
											items={api.allValuesSegment()}
											currentValue={store.segment.value}
											onChangeEnum={store.segment.setValue}
											tranlateEnum={api.translateSegment}
											disabled={store.loader.isLoading || store.isDisabled.value}
										/>
									</FormError>
								)
							}
							<Box w="100%">
								<AddressAutoCompleteInput
									onSelected={store.onSelectAddress}
									labelText={pageStrings.fields.searchAddress}
								/>
							</Box>
							<TextInput
								boxProps={{ w: { base: "100%", md: "45%" } }}
								labelText={pageStrings.fields.zipcode}
								isDisabled={store.loader.isLoading || store.isDisabled.value}
								mask="99999-999"
								{...store.form.field("zipcode")}
							/>
							<TextInput
								boxProps={{ w: { base: "100%", md: "45%" } }}
								labelText={pageStrings.fields.neighborhood}
								isDisabled={store.loader.isLoading || store.isDisabled.value}
								{...store.form.field("neighborhood")}
							/>
							<TextInput
								boxProps={{ w: { base: "100%", md: "45%" } }}
								labelText={pageStrings.fields.city}
								isDisabled={store.loader.isLoading || store.isDisabled.value}
								{...store.form.field("city")}
							/>
							<TextInput
								boxProps={{ w: { base: "100%", md: "45%" } }}
								labelText={pageStrings.fields.street}
								isDisabled={store.loader.isLoading || store.isDisabled.value}
								{...store.form.field("street")}
							/>
							<TextInput
								boxProps={{ w: { base: "100%", md: "45%" } }}
								labelText={pageStrings.fields.streetNumber}
								isDisabled={store.loader.isLoading || store.isDisabled.value}
								{...store.form.field("streetNumber")}
							/>
							<TextInput
								boxProps={{ w: { base: "100%", md: "45%" } }}
								labelText={pageStrings.fields.complementary}
								isDisabled={store.loader.isLoading || store.isDisabled.value}
								{...store.form.field("complementary")}
							/>
							<FormError
								label={pageStrings.fields.state}
								w={{ base: "100%", md: "45%" }}
							>
								<EnumSelect
									items={api.allValuesStateUF()}
									currentValue={store.state.value}
									onChangeEnum={store.state.setValue}
									tranlateEnum={api.translateStateUF}
									disabled={store.loader.isLoading || store.isDisabled.value}
								/>
							</FormError>
						</Stack>
					</Flex>
					<ButtonEditor
						loading={store.loader.isLoading}
						hasEditor={!!id}
						isDisabled={!store.lat.value}
						onCancel={onBack}
						onGo={handleCreateOrEditEstablishment}
					/>
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(EditOrCreateEstablishment);
