import { makeAutoObservable } from "mobx";
import { LoaderShelf, FormShelf } from "@startapp/mobx-utils";

import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";
import SimpleStateShelf from "~/shelves/SimpleStateShelf";
import api from "~/resources/api";

export default class Store {
	public formShelf = new FormShelf({
		code: "",
		weight: "",
	});

	public collectId = "";

	public loader = new LoaderShelf();

	public category = new SimpleStateShelf(api.MaterialCategory.Plastic);

	public date = new SimpleStateShelf(new Date());

	constructor(collectId: string) {
		this.collectId = collectId;
		makeAutoObservable(this);
	}

	public postPromise = async (promiseMethod: () => Promise<api.Material>, onSuccess: () => void) => {
		this.loader.tryStart();
		try {

			await promiseMethod();
			onSuccess();
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

	public createMaterial = (onSuccess: () => void) => {

		const data = this.formShelf.getValues();

		const newMaterial: api.NewMaterial = {
			category: this.category.state,
			collectId: this.collectId,
			date: this.date.state,
			weight: Number(data.weight),
		};

		const create = () => api.createMaterial(newMaterial);

		this.postPromise(create, onSuccess);
	};
}
