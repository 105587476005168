import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { useGlobalStore } from "~/contexts/useGlobalContext";
import strings from "~/resources/strings";

export const useCheckIfLegalUserHasEstablishment = () => {
	const { authStore, dialog } = useGlobalStore();
	const history = useHistory();
	const hookStrings = strings.hooks.checkUserPj;

	const onGotoToCreateEstablishment = () => {
		history.push("/dashboard/legalUsers/establishments/create");
		dialog.closeDialog();
	};

	const onGoBack = () => {
		history.goBack();
		dialog.closeDialog();
	};

	const showDialog = () => {
		dialog.showDialog({
			title: hookStrings.title,
			closeOnOverlayClick: false,
			isOpen: true,
			description: hookStrings.description,
			buttons: [
				{
					title: strings.actions.create,
					onPress: onGotoToCreateEstablishment,
				},
				{
					title: strings.actions.cancel,
					onPress: onGoBack,
					outlined: true,
				},
			],
		});
	};

	useEffect(() => {
		const { currentAdminUser } = authStore;

		const hasEstablishment = !!currentAdminUser?.establishment;

		if (authStore.hasLegalUser() && authStore.hasSignature() && !hasEstablishment) {
			showDialog();
		}
	}, [authStore.currentAdminUser]);
};
