import React from "react";
import { observer } from "mobx-react-lite";

import {
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalFooter,
	ModalBody,
	ModalBodyProps,
	ModalContentProps,
	Flex,
} from "@chakra-ui/react";

import spacing from "~/themes/spacing";
import strings from "~/resources/strings";

interface IProps{
	isOpen: boolean;
	closeOnOverlayClick?: boolean;
	modalBodyProps?: ModalBodyProps;
	modalContentProps?: ModalContentProps;
	footer?: JSX.Element;
	onClose: () => void;
}

export const CustomModal: React.FC<IProps> = observer((props) => {
	const {
		onClose,
		isOpen,
		footer,
		modalBodyProps,
		modalContentProps,
		closeOnOverlayClick = true,
		children,
	} = props;

	const componentStrings = strings.components.autoCompleteModal;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={closeOnOverlayClick}
			isCentered
		>
			<ModalOverlay />
			<ModalContent
				maxH="95%"
				maxW="100vw"
				{...modalContentProps}
			>
				<ModalBody
					color="primary.500"
					px={spacing.s2}
					{...modalBodyProps}
				>
					{children}
				</ModalBody>

				<ModalFooter>
					<Flex flexDir="column" justifyContent="center">
						{footer}
						<Button
							mt={spacing.s8}
							variant="link"
							onClick={onClose}
						>
							{componentStrings.back}
						</Button>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);

});

