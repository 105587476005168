import { computed, makeObservable } from "mobx";
import { AbstractPaginatedListStore, AttributeShelf, LoaderShelf } from "@startapp/mobx-utils";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";

export default class Store extends AbstractPaginatedListStore<api.CheckIn> {

	public loader = new LoaderShelf();
	public startDate: AttributeShelf<Date | null>;
	public finalDate: AttributeShelf<Date | null>;

	constructor() {
		super();
		this.startDate = new AttributeShelf(null, () => this.fetchPage(0));
		this.finalDate = new AttributeShelf(null, () => this.fetchPage(0));
		this.fetchPage(0);
		makeObservable(this, {
			filterElements: computed,
		});
	}

	protected getDataItemsPerPage(page: number): Promise<api.CheckIn[]> {
		return api.getAllCheckIns(page, this.filterElements);
	}

	public get filterElements(): api.FilterByPeriod {
		return {
			startDate: this.startDate.value,
			endDate: this.finalDate.value,
		};
	}

	public exportCsv = async () => {
		this.loader.tryStart();
		try {

			const url = await api.getExportCSVCheckin(this.filterElements);

			window.open(url);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};

}
