import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Checkbox, Flex, HStack, Stack } from "@chakra-ui/react";
import {
	ButtonEditor,
	RadioBoolean,
	Section,
	TextInput,
} from "../../../../components";
import strings from "../../../../resources/strings";
import { useGlobalStore } from "../../../../contexts/useGlobalContext";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import spacing from "~/themes/spacing";

interface IParams {
	id: string;
}

const Edit: React.FC = () => {
	const pageStrings = strings.legalUsers.edit;
	const { id } = useParams<IParams>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));
	const { authStore } = useGlobalStore();

	const onBack = () => {
		history.goBack();
	};

	const editLegalUser = () => {
		store.editLegalUser(
			!!authStore.currentAdminUser?.legalUser,
			id,
			onBack,
		);
	};

	const [docType, setDoctype] = React.useState<"cpf" | "cnpj">("cnpj");

	React.useEffect(
		() => {
			if (store.form.field("documentNumber").value.length === 14) {
				setDoctype("cpf");
			}

			if (store.form.field("documentNumber").value.length === 18) {
				setDoctype("cnpj");
			}
		},
		[store.initalLoader.isLoaded],
	);

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{base: 0, sm: 16 }}
		>
			<Section title={pageStrings.title[docType]}>
				<Stack w="100%" spacing={spacing.s8}>
					<TextInput
						labelText={pageStrings.fields.userName[docType]}
						isDisabled={store.loader.isLoading}
						{...store.form.field("fantasyName")}
					/>
					<TextInput
						labelText={pageStrings.fields.document[docType]}
						isDisabled={store.loader.isLoading}
						mask={
							docType === "cpf"
								? "999.999.999-99"
								: "99.999.999/9999-99"
						}
						{...store.form.field("documentNumber")}
						value={store.form.getValue("documentNumber")}
					/>
					<HStack w="100%">
						<Checkbox
							size="md"
							colorScheme="primary"
							isChecked={docType === "cpf"}
							onChange={() => setDoctype("cpf")}
						>
							{pageStrings.fields.pf}
						</Checkbox>
						<Checkbox
							size="md"
							colorScheme="primary"
							isChecked={docType === "cnpj"}
							onChange={() => setDoctype("cnpj")}
						>
							{pageStrings.fields.pj}
						</Checkbox>
					</HStack>
					{
						!authStore.currentAdminUser?.legalUser &&
							<RadioBoolean
								label={strings.components.radioBoolean.label}
								attributeShelf={store.showInApp}
							/>
					}
					<ButtonEditor
						loading={store.loader.isLoading}
						hasEditor={!!id}
						onCancel={onBack}
						onGo={editLegalUser}
					/>
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(Edit);
