import React from "react";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Stack } from "@chakra-ui/react";
import {
	ButtonEditor,
	Section,
	ImagePicker,
	TextInput,
} from "../../../../components";
import strings from "../../../../resources/strings";
import { useHistory, useParams } from "react-router-dom";
import Store from "./store";
import spacing from "~/themes/spacing";

interface IParams {
	id: string;
}

const EditUser: React.FC = () => {
	const pageStrings = strings.users.edit;
	const { id } = useParams<IParams>();
	const history = useHistory();
	const store = useLocalObservable(() => new Store(id));

	const onBack = () => {
		history.goBack();
	};

	const editUser = () => {
		store.editUser(id, onBack);
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{base: 0, sm: 16 }}
		>
			<Section title={pageStrings.title}>
				<Stack w="100%" spacing={spacing.s8}>
					<Flex flexDir="column" alignItems="center">
						<ImagePicker
							pickImage={store.profileImage.getPickerFields().pick}
							src={store.profileImage.src}
						/>
					</Flex>
					<TextInput
						labelText={pageStrings.fields.name}
						type="text"
						isDisabled={store.loader.isLoading}
						{...store.form.field("name")}
					/>
					<TextInput
						labelText={pageStrings.fields.email}
						type="email"
						isDisabled={store.loader.isLoading}
						{...store.form.field("email")}
					/>
					<TextInput
						labelText={pageStrings.fields.phone}
						type="tel"
						isDisabled={store.loader.isLoading}
						mask="99 99999-9999"
						{...store.form.field("phone")}
					/>
					<ButtonEditor hasEditor={!!id} onCancel={onBack} onGo={editUser} />
				</Stack>
			</Section>
		</Flex>
	);
};

export default observer(EditUser);
