import { makeAutoObservable } from "mobx";
import { LoaderShelf, ModelShelf, AttributeShelf } from "@startapp/mobx-utils";

import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";
import api from "~/resources/api";
import { Errors } from "~/resources/errors";

export default class Store {

	public loader = new LoaderShelf();
	public modelShelf: ModelShelf<api.CheckIn>;
	public isOnDraw = new AttributeShelf(true);

	constructor(id: string) {
		this.modelShelf = new ModelShelf(id, api.getCheckIn, {onModelFetch: this.afterModelShelf });
		makeAutoObservable(this);
	}

	public afterModelShelf = (model: api.CheckIn) => {
		this.isOnDraw.setValue(model.isOnDraw);
	};

	public toggleIsOnDraw = async (id: string, isOnDraw: boolean) => {
		this.loader.tryStart();
		try {
			const checkinIOnDraw = await api.switchParticipationDraw(id, isOnDraw);

			this.isOnDraw.setValue(checkinIOnDraw);
			showSuccessToast(strings.checkInPage.details.success(checkinIOnDraw));
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
