import * as React from "react";
import { observer } from "mobx-react-lite";
import { Input, InputProps, Box, BoxProps } from "@chakra-ui/react";
import Autocomplete from "react-google-autocomplete";
import * as mapsTypes from "~/declarations/maps";
import { Label } from "../Label";
import spacing from "~/themes/spacing";

export interface IProps extends InputProps {
	onSelected: ((place: mapsTypes.IPlaceDetailsResult) => void);
	labelText?: string;
	helper?: string;
	boxProps?: BoxProps;
}

export const AddressAutoCompleteInput: React.FC<IProps> = observer((props) => {
	const { onSelected, labelText, helper, boxProps, ...rest } = props;

	return (
		<Box {...boxProps}>
			<Label marginBottom={spacing.s1} helper={helper}>{labelText}</Label>
			<Input
				as={Autocomplete}
				onPlaceSelected={onSelected}
				options={{
					types: [],
					componentRestrictions: { country: "br" },
				}}
				language="pt-BR"
				{...rest}
			/>
		</Box>
	);
});
