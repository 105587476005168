import { makeAutoObservable } from "mobx";
import { InitialLoaderShelf, LoaderShelf, FormShelf, AttributeShelf } from "@startapp/mobx-utils";
import { Errors } from "~/resources/errors";
import api from "~/resources/api";
import { showErrorToast, showSuccessToast } from "~/resources/toast";
import strings from "~/resources/strings";

const pageStrings = strings.legalUsers.edit;
export default class Store {
	public form = new FormShelf({
		documentNumber: "",
		fantasyName: "",
	});

	public initalLoader = new InitialLoaderShelf();
	public loader = new LoaderShelf();
	public id: string | null = null;
	public showInApp = new AttributeShelf<boolean>(false);


	constructor (id: string) {
		this.getLegalUser(id);
		makeAutoObservable(this);
	}

	public setValuesFormShelf = (legalUser: api.AdminUser) => {
		if (legalUser.legalUser) {
			this.form = new FormShelf({
				documentNumber: legalUser.legalUser.documentNumber,
				fantasyName: legalUser.legalUser.fantasyName,
			});
			this.showInApp.setValue(legalUser.establishment?.showOnApp || false);
		}
	};

	public getLegalUser = async (id: string) => {
		this.loader.tryStart();
		try {
			const user = await api.getLegalUser(id);
			this.setValuesFormShelf(user);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			Errors.handleError(errorMessage);
		} finally {
			this.loader.end();
			this.initalLoader.done();
		}
	};

	public editLegalUser = async (hasLegalUser: boolean, userId: string, onSuccess: () => void) => {
		this.loader.tryStart();
		try {
			const data = this.form.getValues();

			const newLegalUser: api.EditLegalUserWithApp = {
				documentNumber: data.documentNumber,
				fantasyName: data.fantasyName,
				showOnApp: this.showInApp.value,
			};

			if (hasLegalUser) {
				await api.editCurrentLegalUser(newLegalUser);
			} else {
				await api.editLegalUser(userId, newLegalUser);
			}
			showSuccessToast(pageStrings.success);
			onSuccess();
		} catch (e) {
			if (Errors.treatError(e).type === api.ErrorType.Validation) {
				this.form.fieldError.cleanAndAddErrors(JSON.parse(e.message));
			} else {
				showErrorToast(e.message);
			}
		} finally {
			this.loader.end();
		}
	};
}
