import { computed, makeObservable, observable } from "mobx";
import { LoaderShelf, AbstractPaginatedListStore, AttributeShelf } from "@startapp/mobx-utils";
import api from "../../../../resources/api";
import { Errors } from "~/resources/errors";
import { showErrorToast } from "~/resources/toast";

export default class Store extends AbstractPaginatedListStore<api.Collect> {

	public loader = new LoaderShelf();
	public isLegalUser = false;
	public startDate: AttributeShelf<Date | null>;
	public finalDate: AttributeShelf<Date | null>;
	public cooperativeUser: AttributeShelf<boolean | null>

	constructor() {
		super();
		this.startDate = new AttributeShelf(null, () => this.fetchPage(0));
		this.finalDate = new AttributeShelf(null, () => this.fetchPage(0));
		makeObservable(this, {
			isLegalUser: observable,
			filterElements: computed,
		});
	}

	public setLegalUser = (isLegalUser: boolean) => {
		this.isLegalUser = isLegalUser;
		this.fetchPage(0);
	};

	protected getDataItemsPerPage(page: number): Promise<api.Collect[]> {
		return api.getAllCollects(page, this.filterElements);
	}

	public get filterElements(): api.FilterByPeriod {
		return {
			startDate: this.startDate.value,
			endDate: this.finalDate.value,
		};
	}

	public exportCsv = async () => {
		this.loader.tryStart();
		try {
			const url = await api.exportCSVCollect({endDate: this.filterElements.endDate, startDate: this.filterElements.startDate});

			window.open(url);
		} catch (e) {
			const errorMessage = Errors.handleError(e);
			showErrorToast(errorMessage);
		} finally {
			this.loader.end();
		}
	};
}
