import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { observer, useLocalObservable } from "mobx-react-lite";
import { Flex, Stack, useDisclosure } from "@chakra-ui/react";

import {
	TextInput,
	EnumSelect,
	DatePicker,
	Section,
	ButtonEditor,
	AutoCompleteModal,
	EstablishmentCard,
	CooperativeCard,
} from "~/components";
import strings from "~/resources/strings";
import { useDebounce } from "~/hooks/useDebounce";
import { useGlobalStore } from "~/contexts/useGlobalContext";
import spacing from "~/themes/spacing";

import api from "~/resources/api";
import Store from "./store";
import { showSuccessToast } from "~/resources/toast";

interface IParams {
	id?: string;
}

const CreateOrEdit: React.FC = () => {
	const pageStrings = strings.collectPage;
	const { authStore } = useGlobalStore();
	const { id } = useParams<IParams>();
	const history = useHistory();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const debounce = useDebounce();

	const isSuperAdmin = !authStore.currentAdminUser?.legalUser;

	React.useEffect(() => {
		if (isSuperAdmin && !id) {
			store.autoCompleteEstablishment.fetchPage(0);
		}
		return () => store.dispose();
	}, []);

	const onSuccess = () => {
		showSuccessToast(pageStrings.success(isSuperAdmin, !!store.id));
		history.goBack();
	};

	const store = useLocalObservable(() => new Store(id));

	const onSelectEstablishment = (establishment: api.Establishment) => {
		store.establishment.setValue(establishment);
		onClose();
	};

	const submitNewEdit = async () => {
		await store.addCooperativeToCollect();
		store.editCollect(onSuccess);
	};

	const onSubmit = () => {
		if (store.id) {
			submitNewEdit();
			return;
		}

		if (isSuperAdmin) {
			store.createCollectForAdminUser(onSuccess);
			return;
		}
		store.createCollect(
			authStore.currentAdminUser,
			onSuccess,
		);
	};

	const onBack = () => {
		history.goBack();
	};

	return (
		<Flex
			justifyContent="center"
			alignItems="center"
			w="100%"
			p={{base: 0, sm: 16 }}
		>
			<Section title={pageStrings.title(isSuperAdmin, !!id)}>
				<Stack w="100%" spacing={spacing.s8}>
					<DatePicker
						label={pageStrings.fields.date}
						selectedDate={store.collectDate.value}
						onChangeDate={store.collectDate.setValue}
						isDisabled={store.loader.isLoading}
					/>

					<EnumSelect
						label={pageStrings.fields.turn}
						items={api.allValuesTurn()}
						tranlateEnum={api.translateTurn}
						onChangeEnum={store.turn.setValue}
						currentValue={store.turn.value}
					/>
					{id && (
						<>
							<TextInput
								labelText={pageStrings.fields.cooperative}
								onClick={() => store.modalCooperative.setValue(true)}
								value={store.searchCooperative.value}
								isReadOnly
								cursor="pointer"
							/>
							<AutoCompleteModal
								isOpen={store.modalCooperative.value}
								onClose={() => store.modalCooperative.setValue(false)}
								header={
									<TextInput
										placeholder={pageStrings.fields.search}
										onKeyUp={(e) => {
											const input = e.target as HTMLInputElement;
											debounce.clearTimer();
											debounce.setTimer(setTimeout(() => store.searchCooperative.setValue(input.value), 500));
										}}
									/>
								}
								listProps={{
									data: store.autoCompleteCooperative.items,
									loading: store.autoCompleteCooperative.loader.isLoading,
									renderItem: (item) => (
										<CooperativeCard
											key={item.id}
											cooperative={item}
											onClick={() => {
												store.cooperativeId.setValue(item.id);
												store.searchCooperative.setValue(item.name);
												store.modalCooperative.setValue(false);
											}}
										/>
									),
									paginantionProps: {
										currentPage: store.autoCompleteCooperative.page,
										nextPage: store.autoCompleteCooperative.nextPage,
										prevPage: store.autoCompleteCooperative.previousPage,
										hasNextPage: store.autoCompleteCooperative.hasNextPage,
									},
								}}
							/>
						</>
					)}
					{isSuperAdmin && (
						<>
							<EnumSelect
								label={pageStrings.fields.status}
								items={api.allValuesCollectStatus()}
								currentValue={store.status.value}
								onChangeEnum={store.status.setValue}
								tranlateEnum={api.translateCollectStatus}
							/>
						</>
					)}

					{isSuperAdmin && (
						<TextInput
							labelText={pageStrings.fields.establishment}
							onClick={!id ? onOpen : undefined}
							value={store.establishment.value?.name}
							isReadOnly
						/>

					)}
					<TextInput
						labelText={pageStrings.fields.description}
						{...store.formShelf.field("description")}
					/>
					<ButtonEditor
						goButtonText={
							pageStrings.confirmButton(isSuperAdmin, !!id)
						}
						loading={store.loader.isLoading}
						onCancel={onBack}
						onGo={onSubmit}
					/>
				</Stack>
				<AutoCompleteModal
					isOpen={isOpen}
					onClose={onClose}
					header={
						<TextInput
							placeholder={pageStrings.fields.search}
							onKeyUp={(e) => {
								const input = e.target as HTMLInputElement;
								debounce.clearTimer();
								debounce.setTimer(setTimeout(() => store.searchEstablishment.setValue(input.value), 500));
							}}
						/>
					}
					listProps={{
						data: store.autoCompleteEstablishment.items,
						loading: store.autoCompleteEstablishment.loader.isLoading,
						renderItem: (item) => (
							<EstablishmentCard
								key={item.id}
								establishment={item}
								onClick={onSelectEstablishment}
								isSelected={item.id === store.establishment.value?.id}
							/>
						),
						paginantionProps: {
							currentPage: store.autoCompleteEstablishment.page,
							nextPage: store.autoCompleteEstablishment.nextPage,
							prevPage: store.autoCompleteEstablishment.previousPage,
							hasNextPage: store.autoCompleteEstablishment.hasNextPage,
						},
					}}
				/>
			</Section>
		</Flex>
	);
};

export default observer(CreateOrEdit);
