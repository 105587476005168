import React from "react";
import { Link, LinkProps } from "@chakra-ui/react";

interface IProps extends LinkProps {}

export const LinkButton: React.FC<IProps> = (props) => {
	const { children, ...rest } = props;
	return (
		<Link {...rest}>
			{children}
		</Link>
	);

};

